import { useState, useEffect } from "react";
import { URL } from "../../link.js"
import style from "./Recuperacion.module.css"
import { useNavigate } from 'react-router-dom'
import { validarContrasena, compararContraseña } from '../Register/functions.js'
import Swal from 'sweetalert2'
import logo from "../../assets/Logo.svg"
import axios from "axios";
import ojo from "../../assets/ojo.png"

const Actiualiza = () => {

  const [contrasena, setContrasena] = useState("");
  const [errors, setErrors] = useState({ correo: "No puede quedar vacio", contrasena: "No puede quedar vacio", nombre: "No puede quedar vacio", repetirContrasena: "No puede quedar vacio" })
  const [hasErrors, setHasErrors] = useState(false);
  const navigate = useNavigate();
  const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))
  const [ocultar, setOcultar] = useState(true)
  const [ocultarB, setOcultarB] = useState(true)


  useEffect(() => {
    const tieneErrores = Object.values(errors).some((error) => error !== "");
    setHasErrors(tieneErrores);
  }, [errors]);

  const onChanceInputs = (e) => {
    if (e.target.name === "contrasena") {
      const nuevaContrasena = e.target.value;
      setContrasena(nuevaContrasena);
      const contrasenaError = validarContrasena(nuevaContrasena) ? "" : "La contraseña debe contener entre 8 y 15 caracteres, mayúscula, minúscula y número";
      setErrors((prevErrors) => ({ ...prevErrors, contrasena: contrasenaError }));
    }
    if (e.target.name === "repetirContrasena") {
      const nuevaRepetirContrasena = e.target.value;
      const repetirContrasenaError = compararContraseña(nuevaRepetirContrasena, contrasena) ? "" : "La contraseña no coincide";
      setErrors((prevErrors) => ({ ...prevErrors, repetirContrasena: repetirContrasenaError }));
    }

    const tieneErrores = Object.values(errors).some((error) => error !== "");
    setHasErrors(tieneErrores);
  };

  const onClickActualizar = async () => {
    let usuario = {
      correo: dataUserLocal.correo,
      nuevaContrasena: contrasena
    }
    try {
      const response = await axios.put(`${URL}/usuario/contrasena`, usuario)
      console.log(response);
      if (response.data) {
        navigate("/succes")
      }
    } catch (error) {
      Swal.fire({
        title: "Oops...",
        text: `${error.response.data.message}`,
        icon: "warning"
      });
    }
  }

  const onClickHome = () => {
    navigate("/")
  }

  return (
    <div className={style.container}>
      <div className={style.cont}>
        <div className={style.contLogo} onClick={onClickHome}>
          <img className={style.logo} src={logo} alt="Big Sport Data" />
        </div>
        <div className={style.sesion}>
          <div className={style.inter}>
            <h1 className={style.regName}>CAMBIO DE CONTRASEÑA</h1>
            <div className={style.conText}>
              <span className={style.linkText}>
                Proporciona una nueva contraseña para acceder a tu cuenta de Big Sport Data.
              </span>
            </div>
            <div className={style.inputs}>
              <p className={style.etiqueta}>Nueva contraseña</p>
              <div className={style.contInp}>
                <input type={ocultar ? "password" : "text"} className={style.inp} name="contrasena" onChange={onChanceInputs} />
                <div className={style.iconoContainer} onClick={() => setOcultar(!ocultar)}>
                  <img src={ojo} alt="" className={style.icono} />
                </div>
              </div>
              <p className={style.aviso}>{errors.contrasena}</p>
            </div>
            <div className={style.inputs}>
              <p className={style.etiqueta}>Confirma la nueva contraseña</p>
              <div className={style.contInp}>
                <input type={ocultarB ? "password" : "text"} className={style.inp} name="repetirContrasena" onChange={onChanceInputs} />
                <div className={style.iconoContainer} onClick={() => setOcultarB(!ocultarB)}>
                  <img src={ojo} alt="" className={style.icono} />
                </div>
              </div>
              <p className={style.aviso}>{errors.repetirContrasena}</p>
            </div>
            <button className={style.btn} onClick={() => onClickActualizar()}>
              Cambiar la contraseña
            </button>
          </div>
        </div>
        <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
      </div>
    </div>
  );
}

export default Actiualiza