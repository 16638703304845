import { useEffect, useState } from 'react'
import style from "./ModalMetrica.module.css"
import iconFuerza from "../../assets/iconFuerza.png"
import { Line, Bar } from 'react-chartjs-2'
import medallaOne from "../../assets/medallaOne.svg"
import medallaTwo from "../../assets/medallaTwo.svg"
import medallaThree from "../../assets/MedallaThree.svg"
import axios from 'axios'
import { URL } from '../../link'
import { format } from 'date-fns';
import subirImg from "../../assets/subirVideo.png"
import { getUser } from '../../actions'
import { useDispatch } from 'react-redux'
import VideoPlayer from '../Publiaciones/VideoPlayer'

const ModalMetricaVisitor = ({ setMetricaBool, metricaV, user }) => {


    // const user = JSON.parse(localStorage.getItem("dataUser"))

    const dispach = useDispatch()

    const [comparativoBool, setComparativoBool] = useState(true)
    const [historicoBool, setHistoricoBool] = useState(false)
    const [editBool, setEditBool] = useState(true)
    const [metrica, setMetrica] = useState({})
    const [firstSixPosicion, setFirstSixPosicion] = useState(user.Metricas)
    const [arrayComparativo, setArrayComparativo] = useState([])
    const [valor, setValor] = useState("")
    const [bool, setBool] = useState(false)
    const [califico, setCalifico] = useState(0)


    const onClickComparativo = () => {
        setComparativoBool(true)
        setHistoricoBool(false)
        setBool(false)
    }

    const onClickHistorico = () => {
        setComparativoBool(false)
        setHistoricoBool(true)
        setBool(true)
    }

    const onClickEdit = () => {
        setEditBool(!editBool)
    }

    const fechaFormateada = (date) => {
        return format(new Date(date), `dd/MM/yyyy | HH:mm`)
    }

    const fechaFormat = (date) => {
        return format(new Date(date), `dd/MM/yyyy`)
    }

    const filterMetricas = () => {
        return user.Metricas.filter(metric => metric.nombre === metricaV)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }

    const getComparativo = async () => {
        try {
            const response = await axios.get(`${URL}/metrica/comparativo/${metricaV}`)
            setArrayComparativo(response.data)
        } catch (error) {
            console.error('Error al obtener las métricas:', error.message);
        }
    }

    const calcularEdad = (dia, mes, anio) => {
        const fechaNacimiento = new Date(anio, mes - 1, dia);
        const fechaActual = new Date();

        let edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();

        const mesActual = fechaActual.getMonth() + 1;
        const diaActual = fechaActual.getDate();

        if (mesActual < mes || (mesActual === mes && diaActual < dia)) {
            edad--;
        }

        return edad;
    };

    const opciones = {
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                grid: {
                    color: "rgba(21, 26, 32, 1)",
                    display: false
                },
                ticks: {
                    color: "rgba(255, 255, 255, 1)"
                }
            },
            y: {
                grid: {
                    color: "rgba(21, 26, 32, 1)"
                },
                ticks: {
                    color: "rgba(255, 255, 255, 1)",
                    callback: function (value, index, values) {
                        return value + " " + getUnidad()
                    }
                }
            }
        }
    };

    const getUnidad = () => {
        if (metricaV === "fuerza") {
            return "kg"
        }
        if (metricaV === "velocidad") {
            return "seg"
        }
        if (metricaV === "resistencia") {
            return "min"
        }
        if (metricaV === "salto") {
            return "cm"
        }
    }

    useEffect(() => {
        if (arrayComparativo.length === 0) {
            getComparativo();
            getFilterMetricas();
        }
    });

    const handlerValor = (e) => {
        setValor(e.target.value)
    }


    const getFilterMetricas = async () => {
        try {
            const response = await axios.get(`${URL}/metrica/user/${user.id}`);
            const metricasFiltradas = response.data.Metricas.filter(metrica => metrica.nombre === metricaV);
            const metricasOrdenadas = metricasFiltradas.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, 6);
            setFirstSixPosicion(metricasOrdenadas);
            //se podria incluir el dispach getUser para actualizar las metricas
        } catch (error) {
            console.error('Error al obtener las métricas:', error.message);
        }
    };

    const arrayFechas = (array) => {
        // Ordenar el array de objetos por fecha de manera descendente
        const sortedArray = array.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        // Extraer las fechas ordenadas del array ordenado
        const fechas = sortedArray.map(item => fechaFormat(item.createdAt));

        return fechas;
    }

    const arrayValores = (array) => {
        // Ordenar el array de objetos por fecha de manera descendente
        const sortedArray = array.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        // Extraer los valores ordenados del array ordenado
        const valores = sortedArray.map(item => item.valor);

        return valores;
    }

    const enviar = async(id) => {
        const data = {
            id: id,
            valor: valor
        }
        if(califico === 0) {
            try {
                await axios.put(`${URL}/metrica/verificar`, data)
                setEditBool(true)
                setCalifico(1)
            } catch (error) {
                console.log(error);
            }
        }
    } 



    return (
        <div className={style.conteiner}>
            <p className={style.titleMAnd} onClick={() => setMetricaBool(false)}>cerrar</p>
            <div className={!bool ? style.modalContentAnd : style.modalContent}>
                <div className={style.contGrafica}>
                    <div className={style.contTitle}>
                        <img className={style.iconFuerza} src={iconFuerza} alt="" />
                        <h2 className={style.title}>{metricaV.toUpperCase()}</h2>
                    </div>
                    <div className={style.contInfo}>
                        <div className={style.info}>
                            <h4 className={style.sub}>ACTUAL</h4>
                            <div className={style.contData}>
                                <h1 className={style.data}>{filterMetricas()[0].valor}</h1>
                                <h3 className={style.unidad}>{getUnidad()}</h3>
                            </div>
                        </div>
                        <div className={style.contFecha}>
                            <div className={style.contSubfecha}>
                                <p className={style.subFecha}>Última edición</p>
                                <p className={style.fecha}>{fechaFormateada(filterMetricas()[0].createdAt)}</p>
                                <p className={style.fecha}>Veces validada: {filterMetricas()[0].verificaPositivo + filterMetricas()[0].verificaNegativo + califico}</p>
                            </div>
                            {editBool && <button className={style.btnG} onClick={onClickEdit}>Verificar</button>}
                        </div>
                    </div>
                    <div className={style.contEdit}>
                        {editBool || <div className={style.contBtnG}>
                            <h2 className={style.titEdit}>VERIFICAR METRICA</h2>
                            <p className={style.avisoG}>¿Consideras que el video valida la metrica?</p>
                            <div className={style.contEditBtn}>
                                <div className={style.contOption}>
                                    <input
                                        type="radio"
                                        className={style.checkboxP}
                                        id="Si"
                                        value="Si"
                                        checked={valor === "Si"}
                                        onChange={handlerValor}
                                    />
                                    <p className={style.txtSi}>Si</p>
                                </div>
                                
                                <div className={style.contOption}>
                                    <input
                                        type="radio"
                                        className={style.checkboxP}
                                        id="No"
                                        value="No"
                                        checked={valor === "No"}
                                        onChange={handlerValor}
                                    />
                                    <p className={style.txtNo}>No</p>
                                </div>
                                {valor !== "" && <p className={style.txtEnviar} onClick={() => enviar(filterMetricas()[0].id)}>Enviar</p>}
                                <p className={style.txtCerrar} onClick={() => setEditBool(true)}>Cerrar</p>
                            </div>
                            <div className={style.contVideo}>
                                <VideoPlayer
                                    width={350}
                                    height={250}
                                    video={filterMetricas()[0].video}
                                    controls
                                />
                            </div>
                        </div>}
                    </div>
                    {editBool && <div className={bool ? style.conSubGrafico : style.contGraficaDes}>
                        <h4 className={style.sub}>HISTÓRICO</h4>
                        {comparativoBool ? <div className={style.contGrafica}>
                            {metricaV === "fuerza" || metricaV === "resistencia" ? <Line
                                data={{
                                    labels: arrayFechas(firstSixPosicion) || ["sin valores"],
                                    datasets: [
                                        {
                                            label: "",
                                            data: arrayValores(firstSixPosicion) || [0],
                                            fill: false,
                                            borderColor: "rgba(228, 253, 63, 1)",
                                            tension: 0.5,
                                        }
                                    ]
                                }}
                                options={opciones}
                            /> : <Bar
                                data={{
                                    labels: arrayFechas(firstSixPosicion) || ["sin valores"],
                                    datasets: [
                                        {
                                            label: "",
                                            data: arrayValores(firstSixPosicion) || [0],
                                            fill: false,
                                            backgroundColor: "rgba(228, 253, 63, 1)", // Color de relleno amarillo
                                            borderColor: "rgba(255, 206, 86, 1)", // Color de borde amarillo
                                            borderWidth: 1,
                                            tension: 0.5,
                                        }
                                    ]
                                }}
                                options={opciones}
                            />}
                        </div> : <div>
                            {metricaV === "fuerza" || metricaV === "resistencia" ? <Line
                                data={{
                                    labels: arrayFechas(filterMetricas()) || ["sin valores"],
                                    datasets: [
                                        {
                                            label: "",
                                            data: arrayValores(filterMetricas()) || [0],
                                            fill: false,
                                            borderColor: "rgba(228, 253, 63, 1)",
                                            tension: 0.5,
                                        }
                                    ]
                                }}
                                options={opciones}
                            /> : <Bar
                                data={{
                                    labels: arrayFechas(filterMetricas()) || ["sin valores"],
                                    datasets: [
                                        {
                                            label: "",
                                            data: arrayValores(filterMetricas()) || [0],
                                            fill: false,
                                            backgroundColor: "rgba(228, 253, 63, 1)", // Color de relleno amarillo
                                            borderColor: "rgba(255, 206, 86, 1)", // Color de borde amarillo
                                            borderWidth: 1,
                                            tension: 0.5,
                                        }
                                    ]
                                }}
                                options={opciones}
                            />}</div>}
                    </div>}
                </div>
                <div className={style.contHistorico}>
                    <p className={style.titleM} onClick={() => setMetricaBool(false)}>X</p>
                    <div className={style.contSubHistorico}>
                        <div className={style.contMenu}>
                            <div className={comparativoBool ? style.btnSelected : style.btn} onClick={onClickComparativo}>
                                <p className={comparativoBool ? style.btnTxtSelected : style.btnTxt}>Comparativa</p>
                            </div>
                            <div className={historicoBool ? style.btnSelected : style.btn} onClick={onClickHistorico}>
                                <p className={historicoBool ? style.btnTxtSelected : style.btnTxt}>Histórico</p>
                            </div>
                        </div>
                        {comparativoBool ?
                            <div className={!bool ? style.contComparativo : style.contComparativoDes}>
                                {arrayComparativo
                                    .sort((a, b) => b.valor - a.valor) // Ordenar el array por el valor descendente
                                    .map((posicion, index) => (
                                        <div className={index % 2 === 1 ? style.posicionP : style.posicion} key={index}>
                                            {index === 0 && <img className={style.iconM} src={medallaOne} alt="" />}
                                            {index === 1 && <img className={style.iconM} src={medallaTwo} alt="" />}
                                            {index === 2 && <img className={style.iconM} src={medallaThree} alt="" />}
                                            <div className={style.contInfoUser}>
                                                {index > 2 && <p className={style.numList}>{index === 0 ? "Actual" : index + 1}</p>}
                                                <img className={style.userFoto} src={posicion.Usuarios[0].foto} alt="" />
                                                <div className={style.infoUser}>
                                                    <h3 className={style.nameUser}>{posicion.Usuarios[0].nombre}</h3>
                                                    <p className={style.info}>{posicion.Usuarios[0].posicion} - {calcularEdad(posicion.Usuarios[0].dia, posicion.Usuarios[0].mes, posicion.Usuarios[0].anio)} años</p>
                                                </div>
                                            </div>
                                            <div className={style.contDataP}>
                                                <h1 className={style.data}>{posicion.valor}</h1>
                                                <p className={style.unidad}>{getUnidad()}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div> : <div className={bool ? style.contHistorialDes : style.contHistorial}>
                                {filterMetricas().map((metric, index) => (
                                    <div className={style.posicion} key={index}>
                                        <p className={style.numList}>{index === 0 ? "Actual" : index + 1}</p>
                                        <div className={style.contInfoUser}>
                                            <img className={style.userFoto} src={user.foto} alt="" />
                                            <div className={style.infoUser}>
                                                <h3 className={style.nameUser}>{user.nombre}</h3>
                                                <p className={style.info}>{fechaFormateada(metric.createdAt)}</p>
                                            </div>
                                        </div>
                                        <div className={style.contDataP}>
                                            <h1 className={style.data}>{metric.valor}</h1>
                                            <p className={style.unidad}>{getUnidad()}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalMetricaVisitor