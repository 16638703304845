import React from 'react'
import styles from "./Stadistics.module.css"
import userDefault from "../../assets/iconUser.png"
import fuerza from "../../assets/graficaFuerza.png"
import iconFuerza from "../../assets/iconFuerza.png"
import velocidad from "../../assets/graficaVelocidad.png"
import iconVelocidad from "../../assets/iconVelocidad.png"
import resistencia from "../../assets/graficaResistencia.png"
import iconResistencia from "../../assets/iconResistencia.png"
import salto from "../../assets/graficaSalto.png"
import iconSalto from "../../assets/iconSalto.png"
import ok from "../../assets/ok.png"
import not from "../../assets/not.png"
import { calcularEdad, filterMetricas } from '../funciones'
import tu from "../../assets/tu.png"
import menor from "../../assets/menor.svg"
import mayor from "../../assets/mayor.svg"

export default function StadisticsVisitor({ toggleStatics, metricaClick, detailUser }) {

    const userLocal = JSON.parse(localStorage.getItem("dataUser"))

    return (
        <div className={styles.conteiner}>
            <div className={styles.fondoEscudo}>
                <div className={styles.contFoto}>
                    <img className={styles.foto} src={detailUser.foto || userDefault} alt="" />
                    <h3 className={styles.name}>{detailUser.nombre}</h3>
                    <h4 className={styles.direccion}>{detailUser.direccion}, {detailUser.origen}</h4>
                    <div className={styles.contUserInfo}>
                        <div className={styles.userInfo}>
                            <div className={styles.info}>
                                <h3 className={styles.dataName}>EDAD</h3>
                                <h4 className={styles.dataInfo}>{calcularEdad(detailUser.dia, detailUser.mes, detailUser.anio)} años</h4>
                            </div>
                        </div>
                        <div className={styles.userInfo}>
                            <div className={styles.infoB}>
                                <h3 className={styles.dataName}>ALTURA</h3>
                                <h4 className={styles.dataInfo}>{detailUser.altura / 100}m</h4>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contUserInfo}>
                        <div className={styles.userInfo}>
                            <div className={styles.info}>
                                <h3 className={styles.dataName}>PESO</h3>
                                <h4 className={styles.dataInfo}>{detailUser.peso}kg</h4>
                            </div>
                        </div>
                        <div className={styles.userInfo}>
                            <div className={styles.infoB}>
                                <h3 className={styles.dataName}>PIE</h3>
                                <h4 className={styles.dataInfo}>{detailUser?.pie?.slice(0, 3).toUpperCase()}</h4>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contBSD}>
                        <h3 className={styles.bsdT}>BSD SCORE</h3>
                        <h1 className={styles.score}>74</h1>
                    </div>
                </div>
            </div>
            <div className={styles.equipo}>
                <div className={styles.contMetriBtn}>
                    <h2 className={styles.seccionName}>MÉTRICAS DE EQUIPO</h2>
                </div>
                <div className={styles.contMetricaE}>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>PJ</h4>
                        <h3 className={styles.numE}>28</h3>
                    </div>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>G</h4>
                        <h3 className={styles.numE}>20</h3>
                    </div>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>E</h4>
                        <h3 className={styles.numE}>4</h3>
                    </div>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>P</h4>
                        <h3 className={styles.numE}>4</h3>
                    </div>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>GF</h4>
                        <h3 className={styles.numE}>70</h3>
                    </div>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>GC</h4>
                        <h3 className={styles.numE}>24</h3>
                    </div>
                    <div className={styles.metricaE}>
                        <h4 className={styles.metricaNameE}>DG</h4>
                        <h3 className={styles.numE}>46</h3>
                    </div>
                </div>
                <div className={styles.contPartidos}>
                    <h4 className={styles.txtP}>ÚLTIMOS PARTIDOS</h4>
                    <div className={styles.partidos}>
                        <img className={styles.icon} src={ok} alt="" />
                        <img className={styles.icon} src={ok} alt="" />
                        <img className={styles.icon} src={not} alt="" />
                        <img className={styles.icon} src={ok} alt="" />
                        <img className={styles.icon} src={ok} alt="" />
                    </div>
                </div>
               <div className={styles.verificadas}>
                    <h2 className={styles.seccionName}>MÉTRICAS VERIFICADAS</h2>
                    <div className={styles.contMetricas} onClick={() => metricaClick("fuerza")}>
                        <div className={styles.metrica}>
                            <div className={styles.contNum}>
                                <h3 className={styles.metricaName}>{detailUser?.Metricas[0]?.nombre.toUpperCase()}</h3>
                                <div className={styles.contUnidad}>
                                    <p className={styles.num}>{filterMetricas(detailUser, "fuerza")[0].valor}</p>
                                    <span className={styles.unidad}>kg</span>
                                </div>
                            </div>
                            {userLocal.rol === "Jugador" && <div className={styles.contNumB}>
                                <div className={styles.metricaName}>
                                    <img className={styles.tu} src={tu} alt="" /> TÚ
                                </div>
                                <div className={styles.contUnidadB}>
                                    <img className={styles.condicion} src={filterMetricas(detailUser, "fuerza")[0].valor > filterMetricas(userLocal, "fuerza")[0].valor ? menor : mayor } alt="" />
                                    <p className={styles.num}>{filterMetricas(userLocal, "fuerza")[0].valor}</p>
                                    <span className={styles.unidad}>kg</span>
                                </div>
                            </div>}
                            {userLocal.rol !== "Jugador" && <img className={styles.grafica} src={fuerza} alt='Grafico de fuerza en FUTBOL'/>}
                            <img className={styles.grafica} src={iconFuerza} alt="" />
                        </div>
                    </div>
                    <div className={styles.contMetricas} onClick={() => metricaClick("velocidad")}>
                        <div className={styles.metrica}>
                            <div className={styles.contNum}>
                                <h3 className={styles.metricaName}>{detailUser.Metricas[1].nombre.toUpperCase()}</h3>
                                <div className={styles.contUnidad}>
                                    <p className={styles.num}>{filterMetricas(detailUser, "velocidad")[0].valor}</p>
                                    <span className={styles.unidad}>seg</span>
                                </div>
                            </div>
                            {userLocal.rol === "Jugador" && <div className={styles.contNumB}>
                                <div className={styles.metricaName}>
                                    <img className={styles.tu} src={tu} alt="" /> TÚ
                                </div>
                                <div className={styles.contUnidadB}>
                                    <img className={styles.condicion} src={filterMetricas(detailUser, "velocidad")[0].valor > filterMetricas(userLocal, "velocidad")[0].valor ? menor : mayor} alt="" />
                                    <p className={styles.num}>{filterMetricas(userLocal, "velocidad")[0].valor}</p>
                                    <span className={styles.unidad}>seg</span>
                                </div>
                            </div>}
                            {userLocal.rol !== "Jugador" && <img className={styles.grafica} src={velocidad} alt='Grafico de velocidad en FUTBOL'/>}
                            <img className={styles.grafica} src={iconVelocidad} alt="" />
                        </div>
                    </div>
                    <div className={styles.contMetricas} onClick={() => metricaClick("resistencia")}>
                        <div className={styles.metrica}>
                            <div className={styles.contNum}>
                                <h3 className={styles.metricaName}>{detailUser.Metricas[2].nombre.toUpperCase()}</h3>
                                <div className={styles.contUnidad}>
                                    <p className={styles.num}>{filterMetricas(detailUser, "resistencia")[0].valor}</p>
                                    <span className={styles.unidad}>min</span>
                                </div>
                            </div>
                            {userLocal.rol === "Jugador" && <div className={styles.contNumB}>
                                <div className={styles.metricaName}>
                                    <img className={styles.tu} src={tu} alt="" /> TÚ
                                </div>
                                <div className={styles.contUnidadB}>
                                    <img className={styles.condicion} src={filterMetricas(detailUser, "resistencia")[0].valor > filterMetricas(userLocal, "resistencia")[0].valor ? menor : mayor} alt="" />
                                    <p className={styles.num}>{filterMetricas(userLocal, "resistencia")[0].valor}</p>
                                    <span className={styles.unidad}>min</span>
                                </div>
                            </div>}
                            {userLocal.rol !== "Jugador" && <img className={styles.grafica} src={resistencia} alt='Grafico de resistencia en FUTBOL'/>}
                            <img className={styles.grafica} src={iconResistencia} alt="" />
                        </div>
                    </div>
                    <div className={styles.contMetricas} onClick={() => metricaClick("salto")}>
                        <div className={styles.metrica}>
                            <div className={styles.contNum}>
                                <h3 className={styles.metricaName}>{detailUser.Metricas[3].nombre.toUpperCase()}</h3>
                                <div className={styles.contUnidad}>
                                    <p className={styles.num}>{filterMetricas(detailUser, "salto")[0].valor}</p>
                                    <span className={styles.unidad}>cm</span>
                                </div>
                            </div>
                            {userLocal.rol === "Jugador" && <div className={styles.contNumB}>
                                <div className={styles.metricaName}>
                                    <img className={styles.tu} src={tu} alt="" /> TÚ
                                </div>
                                <div className={styles.contUnidadB}>
                                    <img className={styles.condicion} src={filterMetricas(detailUser, "salto")[0].valor > filterMetricas(userLocal, "salto")[0].valor ? menor :  mayor} alt="" />
                                    <p className={styles.num}>{filterMetricas(userLocal, "salto")[0].valor}</p>
                                    <span className={styles.unidad}>cm</span>
                                </div>
                            </div>}
                            {userLocal.rol !== "Jugador" && <img className={styles.grafica} src={salto} alt='Grafico de salto en FUTBOL'/>}
                            <img className={styles.grafica} src={iconSalto} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
