import styles from "./User.module.css"
import posicion from "../../assets/Campo.png"
import { Radar } from 'react-chartjs-2';
import { useState } from 'react'
import { useDispatch } from "react-redux";
import { editarPerfil } from "../../actions";
import { posicionOptions, habilidades, formatoLegible } from "../funciones.js";



const User = () => {

    const user = JSON.parse(localStorage.getItem("dataUser"))

    const [principal, setPrincipal] = useState("")
    const [secundaria, setSecundaria] = useState("")
    const [terciaria, setTerciaria] = useState("")
    const [editPosicion, setEditPosicion] = useState(false)
    const [editHabilidades, setEditHabilidades] = useState(false)
    const [editMetricas, setEditMetricas] = useState(false)
    const [habilidadesSeleccionadas, setHabilidadesSeleccionadas] = useState(user.habilidadesSeleccionadas);
    const [valores, setValores] = useState({
        paseCorto: user.paseCorto,
        paseLargo: user.paseLargo,
        cabeceo: user.cabeceo,
        regate: user.regate,
        defensa: user.defensa,
        tiros: user.tiros
    });

    const dispatch = useDispatch();

    const editP = () => {
        setEditPosicion(!editPosicion)
    }

    const editH = () => {
        setEditHabilidades(!editHabilidades)
    }

    const editM = () => {
        setEditMetricas(!editMetricas)
    }

    const Guardar = async () => {

        user.posicion = principal || user.posicion
        user.posicionesSeleccionadas = [secundaria || user.posicionesSeleccionadas[0], terciaria || user.posicionesSeleccionadas[1]]
        user.habilidadesSeleccionadas = habilidadesSeleccionadas || user.habilidadesSeleccionadas
        user.paseCorto = valores.paseCorto || user.paseCorto;
        user.paseLargo = valores.paseLargo || user.paseLargo;
        user.cabeceo = valores.cabeceo || user.cabeceo;
        user.regate = valores.regate || user.regate;
        user.defensa = valores.defensa || user.defensa;
        user.tiros = valores.tiros || user.tiros;

        const response = await dispatch(editarPerfil(user.id, user))
        localStorage.setItem("dataUser", JSON.stringify(response))
        setEditPosicion(false)
        setEditHabilidades(false)
        setEditMetricas(false)
    }

    const onChanceInputs = (habilidad) => {
        const index = habilidadesSeleccionadas.indexOf(habilidad);
        if (index === -1) {
            setHabilidadesSeleccionadas([...habilidadesSeleccionadas, habilidad]);
        } else {
            const nuevasHabilidades = [...habilidadesSeleccionadas];
            nuevasHabilidades.splice(index, 1);
            setHabilidadesSeleccionadas(nuevasHabilidades);
        }
    }

    const handleChange = (campo, valor) => {
        setValores(prevState => ({ ...prevState, [campo]: valor }));
    };

    const renderCalificaciones = (campo) => {
        const calificaciones = [];
        for (let i = 1; i <= 5; i++) {
            calificaciones.push(
                <div
                    key={i}
                    className={valores[campo] === i ? styles.caliSeleccionado : styles.cali}
                    onClick={() => handleChange(campo, i)}
                >
                    <p className={styles.caliText}>{i}</p>
                </div>
            );
        }
        return calificaciones;
    };

    const posicionesLegibles = user.posicionesSeleccionadas.map(formatoLegible);

    return (
        <div className={styles.conteiner}>
            {!editPosicion ? <div className={styles.posicion}>
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
                    <p className={styles.btnTxt} onClick={editP}>EDITAR</p>
                </div>
                <img className={styles.campo} src={posicion} alt="" />
                <h3 className={styles.title}>POSICIÓN FAVORITA</h3>
                <div className={styles.contPosicion}>
                    <div className={styles.posi}>
                        <p className={styles.txt}>{formatoLegible(user.posicion)}</p>
                    </div>
                </div>
                <h3 className={styles.title}>OTRAS POSICIONES</h3>
                <div className={styles.contPosicion}>
                    <div className={styles.posi}>
                        <p className={styles.txt}>{posicionesLegibles[0]}</p>
                    </div>
                    <div className={styles.posi}>
                        <p className={styles.txt}>{posicionesLegibles[1]}</p>
                    </div>
                </div>
            </div> : <div className={styles.posicion}>
                <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
                <div className={styles.contInp}>
                    <h3 className={styles.subT}>Posición principal</h3>
                    <select className={styles.inputCampo} onChange={(e) => setPrincipal(e.target.value)}>
                        {posicionOptions}
                    </select>
                </div>
                <div className={styles.contInp}>
                    <h3 className={styles.subT}>Segunda posición</h3>
                    <select className={styles.inputCampo} onChange={(e) => setSecundaria(e.target.value)}>
                        {posicionOptions}
                    </select>
                </div>
                <div className={styles.contInp}>
                    <h3 className={styles.subT}>Tercera posición</h3>
                    <select className={styles.inputCampo} onChange={(e) => setTerciaria(e.target.value)}>
                        {posicionOptions}
                    </select>
                </div>
                <div className={styles.cont}>
                    <button className={styles.btnRegresar} onClick={editP}>Cancelar</button>
                    <button className={styles.btnContinuar} onClick={Guardar}>Guardar</button>
                </div>
            </div>}
            {!editHabilidades ? <div className={styles.habilidades}>
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>HABILIDADES DESTACADAS</h3>
                    <p className={styles.btnTxt} onClick={editH}>EDITAR</p>
                </div>
                <div className={styles.contHabilidades}>
                    {user.habilidadesSeleccionadas.map((habilidad, index) => (
                        <div key={index} className={styles.habi}>
                            <p className={styles.txt}>{habilidad}</p>
                        </div>
                    ))}
                </div>
            </div> : <div className={styles.habilidades}>
                <h3 className={styles.seccion}>HABILIDADES DESTACADAS</h3>
                <div className={styles.contOpciones}>
                    <div className={styles.opciones}>
                        {habilidades.map((habilidad, index) => (
                            <div key={index} className={styles.opcionh}>
                                <div className={styles.contCheck}>
                                    <input
                                        type="checkbox"
                                        className={styles.checkbox}
                                        onChange={() => onChanceInputs(habilidad.nombre)}
                                        checked={habilidadesSeleccionadas.includes(habilidad.nombre)}
                                    />
                                    <label className={styles.labelCheckbox}>
                                        <span className={styles.textCheck}>
                                            {habilidad.nombre}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.cont}>
                    <button className={styles.btnRegresar} onClick={editH}>Cancelar</button>
                    <button className={styles.btnContinuar} onClick={Guardar}>Guardar</button>
                </div>
            </div>}
            {!editMetricas ? <div className={styles.metricas}>
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
                    <p className={styles.btnTxt} onClick={editM}>EDITAR</p>
                </div>
                <Radar
                    className={styles.radar}
                    data={{
                        labels: ["PASES CORTOS", "PASES LARGOS", "CABECEO", "REGATE", "DEFENSA", "TIROS"],
                        datasets: [
                            {
                                label: 'HABILIDADES',
                                data: [user.paseCorto, user.paseLargo, user.cabeceo, user.regate, user.defensa, user.tiros],
                                fill: true,
                                backgroundColor: 'rgba(228, 253, 63, 0.5)',
                                borderColor: 'rgb(228, 253, 63)',

                            }
                        ]

                    }}
                    options={{
                        maintainAspectRatio: true,
                        responsive: true,
                        width: 300,
                        height: 300,
                        scales: {
                            r: {
                                min: 0,
                                max: 5,
                                ticks: {
                                    display: false
                                },
                                pointLabels: {
                                    color: 'rgb(255, 255, 255)',
                                }
                            },
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white' // Cambia el color del label de la leyenda aquí
                                }
                            }
                        }
                    }}
                />
            </div> : <div className={styles.metricas}>
                <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
                {Object.entries(valores).map(([campo, valor]) => (
                     <div key={campo} className={styles.contCalificacion}>
                     <h2 className={styles.selectOpcM}>{campo.toUpperCase()}</h2>
                     {campo === "paseCorto" && <p className={styles.textInfoM}>
                     Elige qué tan efectivo eres en el pase corto, considerando que Lionel Andrés Messi es el mejor.
                     </p>}
                     {campo === "paseLargo" && <p className={styles.textInfoM}>
                     Elige qué tan efectivo eres en el pase largo, considerando que Kevin de Bruyne es el más efectivo.
                     </p>}
                     {campo === "cabeceo" && <p className={styles.textInfoM}>
                     Elige qué tan efectivo eres en el cabeceo, considerando que Cristiano Ronaldo el mejor.
                     </p>}
                     {campo === "regate" && <p className={styles.textInfoM}>
                     Elige qué tan efectivo eres en el regate, considerando que Lionel Andrés Messi el mejor.
                     </p>}
                     {campo === "defensa" && <p className={styles.textInfoM}>
                     Elige qué tan efectivo eres defendiendo, considerando que Virgil van Dijk es el más efectivo.
                     </p>}
                     {campo === "tiros" && <p className={styles.textInfoM}>
                     Elige qué tan efectivo eres en el tiro a portería, considerando que Cristiano Ronaldo Messi es el mejor.
                     </p>}
                     <div className={styles.contCali}>
                         {renderCalificaciones(campo)}
                     </div>
                 </div>
                ))}
                <div className={styles.cont}>
                    <button className={styles.btnRegresar} onClick={editM}>Cancelar</button>
                    <button className={styles.btnContinuar} onClick={Guardar}>Guardar</button>
                </div>
            </div>}
        </div>
    )
}

export default User