import { useEffect, useState } from 'react'
import styles from "./ModalMetricaComparativo.module.css"
import tu from "../../assets/tu.png"
import campo from "../../assets/Campodejuegotwo.png"
import { formatoLegible, calcularEdad } from '../funciones'

const ModalPosicionComparativo = ({ user, handlePosicion }) => {

    const userLocal = JSON.parse(localStorage.getItem("dataUser"))

    const [bool, setBool] = useState(true)

    return (
        <div className={styles.conteiner}>
            <div className={styles.modalContent}>
                <h1 className={styles.btnCloset} onClick={handlePosicion}>X</h1> {/* Modificado */}
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
                </div>
                <div className={styles.botonera}>
                    <div className={bool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setBool(true)}>
                        <p className={styles.txtMenu}>{user.nombre.toUpperCase()}</p>
                    </div>
                    <div className={!bool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setBool(false)}>
                        <p className={styles.txtMenu}>TÚ</p>
                    </div>
                </div>
                <div className={styles.metricas}>
                    <div className={bool ? styles.contRadarDes : styles.contRadar}>
                        <div className={styles.etiqueta}>
                            <img className={styles.icon} src={tu} alt="" />
                            <h4 className={styles.etiquetaTxt}>TÚ</h4>
                        </div>
                        <div className={styles.userCard}>
                            <img className={styles.foto} src={userLocal.foto} alt="" />
                            <div className={styles.info}>
                                <p className={styles.name}>{userLocal.nombre.toUpperCase()}</p>
                                <p className={styles.position}>{userLocal.posicion} - {calcularEdad(userLocal.dia, userLocal.mes, userLocal.anio)} años</p>
                            </div>
                        </div>
                        <img className={styles.campo} src={campo} alt="" />
                        <h3 className={styles.sub}>POSICIÓN FAVORITA</h3>
                        <div className={styles.contPosicion}>
                            <p className={styles.posicion}>{formatoLegible(userLocal.posicion)}</p>
                        </div>
                        <h3 className={styles.sub}>OTRAS POSICIONES</h3>
                        <div className={styles.posiciones}>
                        {userLocal.posicionesSeleccionadas.map(posi => (
                            <div className={styles.contPosicion}>
                                <p className={styles.posicion}>{formatoLegible(posi)}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className={!bool ? styles.contRadarDes : styles.contRadar}>
                        <div className={styles.etiqueta}>
                            <h4 className={styles.etiquetaTxt}>{user.nombre}</h4>
                        </div>
                        <div className={styles.userCard}>
                            <img className={styles.foto} src={user.foto} alt="" />
                            <div className={styles.info}>
                                <p className={styles.name}>{user.nombre.toUpperCase()}</p>
                                <p className={styles.position}>{user.posicion} - {calcularEdad(user.dia, user.mes, user.anio)} años</p>
                            </div>
                        </div>
                        <img className={styles.campo} src={campo} alt="" />
                        <h3 className={styles.sub}>POSICIÓN FAVORITA</h3>
                        <div className={styles.contPosicion}>
                            <p className={styles.posicion}>{formatoLegible(user.posicion)}</p>
                        </div>
                        <h3 className={styles.sub}>OTRAS POSICIONES</h3>
                        <div className={styles.posiciones}>
                        {user.posicionesSeleccionadas.map(posi => (
                            <div className={styles.contPosicion}>
                                <p className={styles.posicion}>{formatoLegible(posi)}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <button className={styles.btnAgregar} onClick={handlePosicion}>Cerrar</button>
            </div>
        </div>
    )
}

export default ModalPosicionComparativo;
