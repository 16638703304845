// SearchBar.jsx
import React, { useState } from 'react';
import styles from './SearchBar.module.css';

const SearchBar = ({ handleSearch, handleReset, handleOrder }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleResetClick = () => {
    setSearchTerm('');
    handleReset();
  };

  return (
    <div className={styles.searchBar}>
      <input
      className={styles.inputText}
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <button className={styles.btn} onClick={() => handleSearch(searchTerm)}>Buscar</button>
      <button className={styles.btn} onClick={handleResetClick}>Todos</button>
      <button className={styles.btn} onClick={handleOrder}>Ordenar</button>
    </div>
  );
};

export default SearchBar;
