import style from "./Seleccion.module.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.svg"
import RadioButton from "../../assets/RadioButton.png"
import RadioButtonOk from "../../assets/RadioButtonOk.png"

export default function Intereses() {
    const [tipo, setTipo] = useState("")
    const usuario = JSON.parse(localStorage.getItem("dataUser"))
    const [aviso, setAviso] = useState("")

    const navigate = useNavigate();

    const onChanceInputs = (e) => {
        if (e === "Entrenador") {
            setTipo(e)
        }
        if (e === "Reclutador") {
            setTipo(e)
        }
        if (e === "Periodista") {
            setTipo(e)
        }
        if (e === "Aficionado") {
            setTipo(e)
        }
    }

    const continuar = () => {
        if (tipo === "") {
            setAviso("Debes seleccionar un interes para continuar")
            return
        }
        usuario.posicion = tipo
        localStorage.setItem("dataUser", JSON.stringify(usuario))
        navigate("/basicao")
    }

    const regresar = () => {
        navigate("/seleccion")
    }

    const onClickHome = () => {
        navigate("/")
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.intereses}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>INTERÉS PRINCIPAL</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Indícanos tu interés principal en la plataforma para que podamos personalizar tu experiencia de usuario.
                            </span>
                        </div>
                        <div className={style.inputsI} onClick={() => onChanceInputs("Reclutador")}>
                            <div className={style.infoI}>
                                <p className={style.etiquetaI}>Reclutador</p>
                                <span className={style.textInfoI}>
                                    Si estas en busca de los mejores talentos juveniles selecciona esta opción.
                                </span>
                            </div>
                            <img src={tipo === "Reclutador" ? RadioButtonOk : RadioButton} alt="seleccion" className={style.icon} />
                        </div>
                        <div className={style.inputsI} onClick={() => onChanceInputs("Entrenador")}>
                            <div className={style.infoI}>
                                <p className={style.etiquetaI}>Entrenador</p>
                                <span className={style.textInfoI}>
                                    Si eres entrenador de algún equipo selecciona esta opción.
                                </span>
                            </div>
                            <img src={tipo === "Entrenador" ? RadioButtonOk : RadioButton} alt="seleccion" className={style.icon} />
                        </div>
                        <div className={style.inputsI} onClick={() => onChanceInputs("Periodista")}>
                            <div className={style.infoI}>
                                <p className={style.etiquetaI}>Periodista deportivo</p>
                                <span className={style.textInfoI}>
                                    Si quieres estar al tanto de las novedades futbolísticas selecciona esta opción.
                                </span>
                            </div>
                            <img src={tipo === "Periodista" ? RadioButtonOk : RadioButton} alt="seleccion" className={style.icon} />
                        </div>
                        <div className={style.inputsI} onClick={() => onChanceInputs("Aficionado")}>
                            <div className={style.infoI}>
                                <p className={style.etiquetaI}>Aficionado</p>
                                <span className={style.textInfoI}>
                                    Si eres un gran aficionado al fútbol selecciona esta opción.
                                </span>
                            </div>
                            <img src={tipo === "Aficionado" ? RadioButtonOk : RadioButton} alt="seleccion" className={style.icon} />
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={() => regresar()}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}