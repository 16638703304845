import {useEffect} from 'react'
import styles from './ModalPublicacion.module.css'

const ModalPublicacion = ({setOpcionesBool, opcionesBool, userId, publicacionId, handleDelete, setEditBool}) => {

  const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))

    useEffect(() => {
        const handleDocumentClick = (event) => {
          if (opcionesBool && event.target.closest('.modal-publicaciones') === null) {
            setOpcionesBool(false);
          }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };
      }, [opcionesBool, setOpcionesBool]);

    return (
        <div className={styles.container}>
            {userId === dataUserLocal.id ? <p className={styles.subT} onClick={() => setEditBool(true)}>Editar</p> : <p className={styles.subT}>Dejar de seguir</p>}
            {userId === dataUserLocal.id ? <p className={styles.subT} onClick={() => handleDelete(publicacionId)}>Borrar</p> : <p className={styles.subT}>Denunciar</p>}
        </div>
    )
}

export default ModalPublicacion