import style from "./NavBar.module.css";
import userIcon from "../../assets/iconUser.png"
import logo from "../../assets/Logo.svg"
import notificacionIcon from "../../assets/notification.png"
import notificacionIconTrue from "../../assets/NotificacionesTrue.png"
import { calcularEdad, formatoLegible } from "../funciones";
import { useEffect, useState } from "react";
import menuAnd from "../../assets/menu.svg"
import ModalMenu from "../ModalMenu/ModalMenu";


export default function NavBar({ handleClick, clickMessage, inAmigos, clickNotificacion, notificacionesNum, handlerAdminClick, user, clickUserBool, menuBool, setMenuBool, inEdit, logOut }) {

  const [isAndroid, setIsAndroid] = useState(false)

  useEffect(() => {
    if (window.AndroidInterface) {
      setIsAndroid(true)
    }
  }, [])

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (menuBool && event.target.closest('.modal-menu') === null) {
        setMenuBool(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [menuBool, setMenuBool]);

  return (
    <div className={style.conteiner}>
      <div className={style.nav}>
        <div className={style.navLinksL}>
          <div className={style.part}>
            {user?.rol !== "admin" ? <div>
              <img className={style.logo} src={logo} alt="" onClick={handleClick} />
            </div> : <div>
              <img className={style.icon} src={logo} alt="" onClick={handlerAdminClick} />
            </div>}
          </div>
        </div>
        <div className={style.navLinks}>
          <div className={style.btn} onClick={handleClick}>
            <p className={style.a}>Inicio</p>
          </div>
          <div className={style.btn} onClick={inAmigos}>
            <p className={style.a}>Comunidad</p>
          </div>
          <div className={style.btn} onClick={clickMessage}>
            <p className={style.a}>Mensajes</p>
          </div>
        </div>
        <div className={style.navLinksU}>
          <img className={style.iconNoti} src={notificacionesNum > 0 ? notificacionIconTrue : notificacionIcon} alt="" onClick={clickNotificacion} />
          <div className={style.user} onClick={clickUserBool}>
            <div className={style.contInfo}>
              <p className={style.name}>{user?.nombre}</p>
              <p className={style.info}>{formatoLegible(user?.posicion || "")} - {calcularEdad(user?.dia, user?.mes, user?.anio)} años</p>
            </div>
            <img className={style.iconUser} src={user?.foto || userIcon} alt="" />
          </div>
        </div>
      </div>
      {isAndroid ? <div className={style.navAnd}>
        <img className={style.logoAnd} src={logo} alt="" onClick={handleClick}/>
        <div className={style.btnAnd}>
          <img className={style.iconNotiAnd} src={notificacionesNum > 0 ? notificacionIconTrue : notificacionIcon} alt="" onClick={clickNotificacion} />
          <img className={style.menuAnd} src={menuAnd} alt=""onClick={() => setMenuBool(!menuBool)} />
        </div>
      </div> : <div className={style.navAnd}>
        <img className={style.logoAnd} src={logo} alt="" onClick={handleClick}/>
        <div className={style.btnAnd}>
          <img className={style.iconNotiAnd} src={notificacionesNum > 0 ? notificacionIconTrue : notificacionIcon} alt="" onClick={clickNotificacion} />
          <img className={style.menuAnd} src={menuAnd} alt=""onClick={() => setMenuBool(!menuBool)} />
        </div>
      </div>}
      {menuBool && <ModalMenu inAmigos={inAmigos} clickMessage={clickMessage} handleClick={handleClick} inEdit={inEdit} logOut={logOut} setMenuBool={setMenuBool}/>}
    </div>
  );
}