import React from 'react'
import style from "./Register.module.css";
import logo from "../../assets/Logo.svg"
import filled from "../../assets/filled.png"
import { useNavigate } from 'react-router-dom'

const ConfirmarCorreo = () => {

    const navigate = useNavigate();

    const onClickHome = () => {
        navigate("/")
    }

    const onClickSesion = () => {
        navigate("/sesion")
    }

    return (
        <div className={style.container}>
            <div className={style.register}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.confirm}>
                    <div className={style.inter}>
                        <img className={style.filled} src={filled} alt="success" />
                        <h1 className={style.regName}>CONFIRMA TU CORREO ELECTRONICO</h1>
                        <div className={style.conText}>
                        <span className={style.linkTextConfirm}>
                            Haz clic en el enlace de confirmación del mensaje para completar tu registro.
                        </span>
                    </div>
                    <button onClick={() => onClickSesion()} className={style.btnConfirm}>
                        Ir a iniciar sesión
                    </button>
                    <div className={style.contCheck}>
                        <span className={style.textConfirm}>
                            Si no recibiste el correo de confirmación revisa tu carpeta de spam
                        </span>
                    </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}

export default ConfirmarCorreo