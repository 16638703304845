import { useEffect, useState } from 'react'
import styles from "./Perfil.module.css"
import { getUserPublicaciones } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import userDefault from "../../assets/iconUser.png"
import Stadistics from '../Stadistics/Stadistics'
import News from '../News/News'

import Feed from '../Feed/Feed.jsx'
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx";
import Loading from '../Loading/Loading'

const Perfil = ({ inEdit, inVisitor, user, notificacionesBool, noticias}) => {
  const dispatch = useDispatch()
  const publicaciones = useSelector(state => state.userPubliaciones)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(getUserPublicaciones(user.id))
    console.log("Hay error?");
  }, [dispatch, user.id])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
        setLoading(false)
    }, 500)
},[])

  return (
    loading ? <Loading /> :
    <div className={styles.container}>
      <div>
        <Stadistics detailUser={user} />
      </div>
      <div>
        <div className={styles.hero}>
          <div className={styles.contHero}>
           
            <div className={styles.perfil}>
              <img className={styles.foto} src={user.foto || userDefault} alt="" />
              <div>
                <h2 className={styles.nombre}>{user.nombre} {user.segundoNombre} {user.apellido} {user.segundoApellido}</h2>
                <h3 className={styles.perfil}>{`${user.posicion} ${user.experiencia || ""}`}</h3>
              </div>
             
            </div>
          </div>
        </div>
        <Feed user={user} publicaciones={publicaciones} inVisitor={inVisitor} noticias={noticias}/>
      </div>
      <div>
        <News />
      </div>
      {notificacionesBool === true && <ModalNotificaciones/>}
    </div>
  )
}

export default Perfil