import React, { useEffect, useState } from 'react'
import NavBarAdmin from '../NavBarAdmin/NavBarAdmin'
import styles from "./Admin.module.css"
import Panel from './Panel/Panel'
import Usuarios from './Usuarios/Usuarios'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getAllJugadores, getAllReclutadores, getJugadoresLine, getReclutadoresLine, getAllPublicaciones, getUserDesactivos, getUserActivos, getNoticias } from '../../actions'
import Feed from '../Feed/Feed'
import Noticias from './Noticias/Noticias'
import axios from 'axios'
import { URL } from '../../link'

const Admin = ({ user, inVisitor, deletePublicacion, logOut, noticias }) => {
    const [panelBool, setPanelBool] = useState(true)
    const [usuariosBool, setUsuariosBool] = useState(false)
    const [publicacionesBool, setPublicacionesBool] = useState(false)
    const [noticiasBool, setNoticiasBool] = useState(false)

    const [allJugadores, setAllJugadores] = useState([])
    const [allReclutadores, setAllReclutadores] = useState([])
    const jugadoresLine = useSelector(state => state.jugadoresLine)
    const reclutadoresLine = useSelector(state => state.reclutadoresLine)
    const allUsuarios = useSelector(state => state.allUsuarios)
    const userPublicaciones = useSelector(state => state.allPublicaciones);
    const usuariosActivos = useSelector(state => state.usuariosActivos)
    const usuariosDesactivos = useSelector(state => state.usuariosDesactivos)
    const [publicaciones, setPublicaciones] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))

    function handleClick() {
        navigate("/home")
    }

    const changePanel = () => {
        setPanelBool(true)
        setUsuariosBool(false)
        setPublicacionesBool(false)
        setNoticiasBool(false)
        actualizar()
    }

    const changeUsuarios = () => {
        setPanelBool(false)
        setUsuariosBool(true)
        setPublicacionesBool(false)
        setNoticiasBool(false)
        actualizar()
    }

    const changePublicaciones = () => {
        setPanelBool(false)
        setUsuariosBool(false)
        setPublicacionesBool(true)
        setNoticiasBool(false)
        actualizar()
    }

    const changeNoticias = () => {
        setPanelBool(false)
        setUsuariosBool(false)
        setPublicacionesBool(false)
        setNoticiasBool(true)
        actualizar()
    }

    const getAllJugadores = async () => {
        try {
            const response = await axios.get(`${URL}/admin/jugadores`)
            setAllJugadores(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllReclutadores = async () => {
        try {
            const response = await axios.get(`${URL}/admin/reclutadores`)
            setAllReclutadores(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const activarUsuario = async (id) => {
        const data = {
            id: id,
            activo: true
        }
        try {
            await axios.put(`${URL}/admin/activar`, data)
            getAllJugadores()
            getAllReclutadores()
        } catch (error) {
            console.log(error);
        }
    }

    const desactivarUsuario = async (id) => {
        const data = {
            id: id,
            activo: false
        }
        try {
            await axios.put(`${URL}/admin/activar`, data)
            getAllJugadores()
            getAllReclutadores()
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // dispatch(getAllJugadores(dataUserLocal.id))
        // dispatch(getAllReclutadores())
        // dispatch(getJugadoresLine())
        dispatch(getReclutadoresLine())
        dispatch(getAllPublicaciones(user.id))
        dispatch(getUserDesactivos())
        dispatch(getUserActivos())
    }, [])

    function actualizar() {
        // dispatch(getAllJugadores(dataUserLocal.id))
        // dispatch(getAllReclutadores())
        dispatch(getJugadoresLine())
        dispatch(getReclutadoresLine())
        dispatch(getAllPublicaciones(user.id))
        dispatch(getUserDesactivos())
        dispatch(getUserActivos())
    }

    useEffect(() => {
        getAllJugadores()
        getAllReclutadores()
    }, [])

    return (
        <div className={styles.container}>
            <NavBarAdmin
                changePanel={changePanel}
                changeUsuarios={changeUsuarios}
                changePublicaciones={changePublicaciones}
                handleClick={handleClick}
                changeNoticias={changeNoticias}
                logOut={logOut}
            />
            <div className={styles.subContainer}>
                {panelBool && <Panel
                    allJugadores={allJugadores}
                    allReclutadores={allReclutadores}
                    allUsuarios={allUsuarios}
                    jugadoresLine={jugadoresLine}
                    reclutadoresLine={reclutadoresLine}
                    usuariosDesactivos={usuariosDesactivos}
                    usuariosActivos={usuariosActivos}
                />}
                {usuariosBool && <Usuarios
                    activarUsuario={activarUsuario}
                    desactivarUsuario={desactivarUsuario}
                    allJugadores={allJugadores}
                    allReclutadores={allReclutadores}
                    inVisitor={inVisitor}
                />}
                {publicacionesBool && <div className={styles.feed}>
                    <Feed
                        user={user}
                        userPublicaciones={userPublicaciones}
                        publicaciones={publicaciones}
                        inVisitor={inVisitor}
                        deletePublicacion={deletePublicacion}
                        noticias={noticias}
                    /></div>}
                {noticiasBool && <Noticias noticias={noticias}/>}
            </div>
        </div>
    )
}

export default Admin
