import React from 'react'
import style from "./Recuperacion.module.css";
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Logo.svg";
import filled from "../../assets/filled.png"

const Succes = () => {
    const navigate = useNavigate();
    const onClickHome = () => {
        navigate("/");
    }

    const onClickSesion = () => {
        navigate("/sesion")
    }

    return (
        <div className={style.container}>
            <div className={style.cont}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.sesion}>
                    <div className={style.inter}>
                        <img className={style.filled} src={filled} alt="success" />
                        <h1 className={style.regName}>CAMBIO DE CONTRASEÑA EXITOSO</h1>
                    </div>
                    <div className={style.conText}>
                        <span className={style.linkText}>
                            Ahora podrás iniciar sesión con tu mismo correo y tu nueva contraseña.
                        </span>
                    </div>
                    <button onClick={() => onClickSesion()} className={style.btnConfirm}>
                        Ir a iniciar sesión
                    </button>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}

export default Succes