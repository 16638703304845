import React, { useState, useEffect, useRef } from 'react';
import styles from "./Feed.module.css";
import iconUser from "../../assets/iconUser.png";
import Publicaciones from '../Publiaciones/Publicaciones';
import axios from 'axios';
import { URL } from '../../link.js';
import { useDispatch, useSelector } from "react-redux";
import { getAllPublicaciones, getUserPublicaciones, deletePublicacion } from "../../actions/index.js";
import iconImg from "../../assets/iconImg.png"
import iconVideo from "../../assets/iconVideo.png"
import VideoPlayer from '../Publiaciones/VideoPlayer.js';

const Feed = ({ user, publicaciones, inVisitor, userPublicaciones, noticias }) => {
  const dispatch = useDispatch()
  const miUser = useSelector(state => state.dataUser)
  const [mensaje, setMensaje] = useState("");
  const [imageUpLoad, setImageUpLoad] = useState("")
  const [upLoadVideo, setUpLoadVideo] = useState("")
  const [miPerfilBool, setMiPerfilBool] = useState(false)
  const [idUpdate, setIdUpdate] = useState("")
  const fileInputRef = useRef(null);
  const [seguidosBool, setSeguidosBool] = useState(true);
  const [mifeed, setMifeed] = useState(false);
  const [noticiasBool, setNoticiasBool] = useState(false);
  const [videoPreview, setVideoPreview] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const fileInputImgRef = useRef(null);
  const fileInputVideoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [videoLoadingInProgress, setVideoLoadingInProgress] = useState(false);

  const preset_key = "bqgn8ppn";
    const cloud_name = "dvwtgluwc";
  const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;
  const cloudinaryUrlVideo = `https://api.cloudinary.com/v1_1/${cloud_name}/video/upload`;

  const handleImageUpload = () => {
    fileInputImgRef.current.click();
  };

  const handleVideoUpload = () => {
    fileInputVideoRef.current.click();
  };

  const clickBtnMenu = (apartado) => {
    switch (apartado) {
      case "miPerfil":
        setMifeed(true);
        setSeguidosBool(false);
        setNoticiasBool(false);
        break;
      case "seguidos":
        setMifeed(false);
        setSeguidosBool(true);
        setNoticiasBool(false);
        break;
      case "noticias":
        setMifeed(false);
        setSeguidosBool(false);
        setNoticiasBool(true);
        break;
      default:
        break;
    }
  }

  const uploadImage = async (file) => {
    setLoadingImage(true);
    setUpLoadVideo("")
    setVideoPreview("")
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', preset_key);

    try {
      const response = await axios.post(cloudinaryUrl, data);
      setImageUpLoad(response.data.secure_url);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingImage(false);
    }
  };

  const uploadVideo = async (file) => {
    if (file.type.startsWith('video/')) {
      setImageUpLoad("")
      setVideoLoadingInProgress(true);
      setLoadingVideo(true);
      
      try {
        const video = document.createElement('video');
        video.preload = 'metadata';
    
        video.onloadedmetadata = async function () {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
    
          if (duration <= 1200) {
            const data = new FormData();
            data.append('file', file);
            data.append('upload_preset', preset_key);
    
            try {
              const response = await axios.post(cloudinaryUrlVideo, data);
              setUpLoadVideo(response.data.public_id);
              setVideoPreview(window.URL.createObjectURL(file));
              setVideoLoadingInProgress(false);
            } catch (error) {
              console.log(error);
              setVideoLoadingInProgress(false);
              setLoadingVideo(false);
            }
          } else {
            console.log("El video supera los 20 minutos");
            setVideoLoadingInProgress(false);
            setLoadingVideo(false);
          }
        }
    
        video.src = window.URL.createObjectURL(file);
      } catch (error) {
        console.log(error);
        setVideoLoadingInProgress(false);
        setLoadingVideo(false);
      } finally {
        setLoadingVideo(false);
      }
    } else {
      // No es un archivo de video, no se ejecuta la lógica de carga de video
      // Aquí puedes mostrar un mensaje de error o tomar cualquier otra acción necesaria
      console.log("El archivo no es un video");
    }
  };
  
  const publiacar = async () => {
    try {
      if (!loading && mensaje.trim() !== "") {
        setLoading(true); // Activar el estado de carga
        const postData = {
          userID: user.id,
          mensaje: mensaje,
          img: imageUpLoad,
          video: upLoadVideo,
        };
        
        let response = {}
        if (user.rol === 'admin') {
          response = await axios.post(`${URL}/admin/publicacion`, postData);
        } else {
          response = await axios.post(`${URL}/publicacion/nueva`, postData);
        }
        if (Object.keys(response.data).length !== 0) {
          dispatch(getAllPublicaciones(user.id));
          dispatch(getUserPublicaciones(idUpdate));
        }
        setMensaje("");
        setImageUpLoad("");
        setUpLoadVideo("")
        setVideoPreview("");
      } else {
        console.log("El campo de texto no puede quedar vacío");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.id === miUser.id) {
      setIdUpdate(miUser.id);
      setMiPerfilBool(true);
    } else {
      setIdUpdate(user.id);
    }
  }, [user.id, miUser.id]);

  const publicacionesOrdenadas = [...publicaciones].sort((b, a) => new Date(a.updatedAt) - new Date(b.updatedAt));
  const userPublicacionesOrdenadas = [...userPublicaciones].sort((b, a) => new Date(a.updatedAt) - new Date(b.updatedAt));
  const disablePublishButton = loading || mensaje.trim() === "";

  return (
    <div className={styles.conteiner}>
      <div className={styles.miPubli}>
        <div className={styles.msg}>
          <img className={styles.user} src={user.foto || iconUser} alt="" />
          <input
            className={styles.inp}
            type="text"
            placeholder=" Crear Publiación"
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
          />
          <button className={styles.btn} onClick={publiacar} disabled={disablePublishButton}>
            Publicar
          </button>
        </div>
        {imageUpLoad !== "" && <img className={styles.img} src={imageUpLoad} alt="" />}
        {videoPreview !== "" && <video className={styles.video} src={videoPreview} controls></video>}
        <div className={styles.contImg}>
          <input
            type="file"
            name="foto"
            accept="image/*"
            onChange={(e) => uploadImage(e.target.files[0])}
            ref={fileInputImgRef}
            className={styles.inputext}
            disabled={loadingImage}
          />
          <div className={styles.btnImg} onClick={handleImageUpload}>
            <img className={styles.icon} src={iconImg} alt="" />
            <p className={styles.btnText}>{loadingImage ? "Cargando..." : "Agregar imagen"}</p>
          </div>
          <input
            type="file"
            name="video"
            accept="video/*"
            onChange={(e) => uploadVideo(e.target.files[0])}
            ref={fileInputVideoRef}
            className={styles.inputext}
            disabled={loadingVideo || videoLoadingInProgress} 
          />
          <div className={styles.btnImg} onClick={handleVideoUpload}>
            <img className={styles.icon} src={iconVideo} alt="" />
            <p className={styles.btnText}>{videoLoadingInProgress ? "Cargando..." : "Agregar video"}</p>
          </div>
        </div>
      </div>
      <div className={styles.contBotonera}>
        <div className={seguidosBool ? styles.botonSelected : styles.boton} onClick={() => clickBtnMenu("seguidos")}>
          <p className={styles.txtBoton}>Seguidos</p>
        </div>
        <div className={mifeed ? styles.botonSelected : styles.boton} onClick={() => clickBtnMenu("miPerfil")}>
          <p className={styles.txtBoton}>Mi feed</p>
        </div>
        <div className={noticiasBool ? styles.botonSelected : styles.boton} onClick={() => clickBtnMenu("noticias")}>
          <p className={styles.txtBoton}>Noticias</p>
        </div>
      </div>
      {seguidosBool && <div className={styles.publicacionesDiv}>
        {publicacionesOrdenadas.map((publicacion) => (
          <Publicaciones
            key={publicacion.id}
            id={publicacion.id}
            tipo={publicacion.tipo}
            userId={publicacion.Usuario?.id}
            foto={publicacion.Usuario?.foto}
            nombre={publicacion.Usuario?.nombre}
            mensaje={publicacion.mensaje}
            img={publicacion.img}
            video={publicacion.video}
            likes={publicacion.Likes}
            comentarios={publicacion.Comentarios}
            compartidos={publicacion.compartidos}
            fecha={publicacion.updatedAt}
            origenID={publicacion.origenID}
            origenName={publicacion.origenName}
            origenUserID={publicacion.origenUserID}
            origenMensaje={publicacion.origenMensaje}
            origenUserFoto={publicacion.origenUserFoto}
            oldcreatedAt={publicacion.oldcreatedAt}
            idUpdate={idUpdate}
            inVisitor={inVisitor}
            userRol={publicacion.Usuario.rol}
            posicion={publicacion.Usuario.posicion}
            dia={publicacion.Usuario.dia}
            mes={publicacion.Usuario.mes}
            anio={publicacion.Usuario.anio}
            // deletePublicacion={deletePublicacion}
            aplausos={publicacion.Aplausos}
          />
        ))}</div>}
      {mifeed && <div>
        {userPublicacionesOrdenadas.map((publicacion) => (
          <Publicaciones
            key={publicacion.id}
            id={publicacion.id}
            tipo={publicacion.tipo}
            userId={user.id}
            foto={user.foto}
            nombre={user.nombre}
            mensaje={publicacion.mensaje}
            img={publicacion.img}
            video={publicacion.video}
            likes={publicacion.Likes}
            comentarios={publicacion.Comentarios}
            compartidos={publicacion.compartidos}
            fecha={publicacion.updatedAt}
            origenID={publicacion.origenID}
            origenName={publicacion.origenName}
            origenUserID={publicacion.origenUserID}
            origenMensaje={publicacion.origenMensaje}
            origenUserFoto={publicacion.origenUserFoto}
            oldcreatedAt={publicacion.oldcreatedAt}
            idUpdate={idUpdate}
            inVisitor={inVisitor}
            userRol={user.rol}
            posicion={user.posicion}
            dia={user.dia}
            mes={user.mes}
            anio={user.anio}
            deletePublicacion={deletePublicacion}
            aplausos={publicacion.Aplausos}
          />
        ))}
      </div>}
      {noticiasBool && <div>
        {noticias.map((publicacion) => (
          <Publicaciones
            key={publicacion.id}
            id={publicacion.id}
            tipo={publicacion.tipo}
            empresa={publicacion.empresa}
            titulo={publicacion.titulo}
            logo={publicacion.logo}
            userId={user.id}
            foto={publicacion.logo}
            nombre={publicacion.empresa}
            mensaje={publicacion.mensaje}
            img={publicacion.img}
            video={publicacion.video}
            likes={publicacion.Likes}
            comentarios={publicacion.Comentarios}
            compartidos={publicacion.compartidos}
            fecha={publicacion.updatedAt}
            origenID={publicacion.origenID}
            origenName={publicacion.origenName}
            origenUserID={publicacion.origenUserID}
            origenMensaje={publicacion.origenMensaje}
            origenUserFoto={publicacion.origenUserFoto}
            oldcreatedAt={publicacion.oldcreatedAt}
            idUpdate={idUpdate}
            inVisitor={inVisitor}
            userRol={user.rol}
            deletePublicacion={deletePublicacion}
            aplausos={publicacion.Aplausos}
          />
        ))}
      </div>}
    </div>
  );
};

export default Feed;