import React from 'react'
import styles from './ModalUser.module.css'

const ModalUser = ({logOut, inEdit}) => {

    return (
        <div className={styles.container}>
            <p className={styles.subT} onClick={inEdit}>Perfil de usuario</p>
            <p className={styles.subT} onClick={logOut}>Cerrar sesión</p>
        </div>
    )
}

export default ModalUser