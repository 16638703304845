import { useState } from 'react';
import style from "./Recuperacion.module.css";
import logo from "../../assets/Logo.svg";
import { useNavigate } from 'react-router-dom';
import { dispatchEnviarCorreo } from '../../actions/index.js';
import { useDispatch } from 'react-redux';


const Recuperacion = () => {
    const [correo, setCorreo] = useState("");
    const [aviso, setAviso] = useState("")
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const onClickHome = () => {
        navigate("/");
    }

    const onClickSesion = () => {
        navigate("/sesion");
    }

    const enviarCorreo = async () => {
        try {
            const response = await dispatch(dispatchEnviarCorreo(correo))
            console.log(response);
            if (response === true) navigate("/verifica");
            else{
                setAviso(response.response.data.message)
            }
        } catch (error) {
            setAviso("Network Error")
        }
    }

    return (
        <div className={style.container}>
            <div className={style.cont}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.sesion}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>RECUPERACIÓN DE CONTRASEÑA</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Proporciona la dirección de correo con la que te registraste y te enviaremos un correo con instrucciones para recuperar tu contraseña.
                            </span>
                        </div>
                        <div className={style.inputs}>
                            <p className={style.etiqueta}>Correo electronico</p>
                            <input type="text" className={style.inp} name="correo" value={correo} onChange={(e) => setCorreo(e.target.value)}></input>
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <button className={style.btn} onClick={enviarCorreo}>
                            Recuperar contraseña
                        </button>
                        <div className={style.contRecu}>
                            <span className={style.recuperacion} onClick={onClickSesion}>Regresar a iniciar sesión</span>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}

export default Recuperacion;
