import React from 'react'
import style from "./ModalMenu.module.css"

const ModalMenu = ({inAmigos, clickMessage, handleClick, inEdit, logOut, setMenuBool}) => {

    const inicio = () => {
        setMenuBool(false)
        handleClick()
    }

    const comunidad = () => {
        setMenuBool(false)
        inAmigos()
    }

    const mensajes = () => {
        setMenuBool(false)
        clickMessage()
    }

    const perfil = () => {
        setMenuBool(false)
        inEdit()
    }

    const salir = () => {
        setMenuBool(false)
        logOut()
    }
    
    return (
        <div  className={[style.container, "modal-menu"].join(" ")}>
            <div className={style.contMenu}>
                <div className={style.menu} onClick={inicio}>
                    <p className={style.a}>Inicio</p>
                </div>
                <div className={style.menu} onClick={comunidad}>
                    <p className={style.a}>Comunidad</p>
                </div>
                <div className={style.menu} onClick={mensajes}>
                    <p className={style.a}>Mensajes</p>
                </div>
                <div className={style.menu} onClick={perfil}>
                    <p className={style.a}>Perfil de usuario</p>
                </div>
                <div className={style.menu} onClick={salir}>
                    <p className={style.a}>Cerrar sesión</p>
                </div>
            </div>
        </div>
    )
}

export default ModalMenu