import style from "./NavBarAdmin.module.css";
import logout from "../../assets/exit.png"
import user from "../../assets/user.png"
import homeIcon from "../../assets/home.png"
import chatIcon from "../../assets/conversation_953810.png"
import logo from "../../assets/Logo.svg"
import notificacionIcon from "../../assets/notification.png"
import friendsIcon from "../../assets/friends.png"

export default function NavBarAdmin({changePanel, changeUsuarios, changePublicaciones, handleClick, logOut, changeNoticias}) {

  return (
    <nav className={style.nav}>
      <div className={style.navLinksA}>
        <div className={style.part}>
          <div className={style.btn} onClick={handleClick}>
            <img className={style.icon} src={logo} alt="" />
          </div>
        </div>
      </div>
      <div className={style.navLinks}>
        <div className={style.part}>
          <div className={style.btn} onClick={changePanel}>
            <img className={style.icon} src={homeIcon} alt="" />
            <span className={style.a}>Panel</span>
          </div>
          <div className={style.btn} onClick={changeUsuarios}>
            <img className={style.icon} src={friendsIcon} alt="" />
            <span className={style.a}>Usuarios</span>
          </div>
          <div className={style.btn} onClick={changePublicaciones}>
            <img className={style.icon} src={chatIcon} alt=""/>
            <div className={style.contMnesaje}>
            <span className={style.a}>Publicaciones</span>
            </div>
          </div>
          <div className={style.btn} onClick={changeNoticias}>
            <img className={style.icon} src={user} alt="" />
            <span className={style.a}>Noticias</span>
          </div>
          <div className={style.btn} >
            <img className={style.icon} src={notificacionIcon} alt="" />
            <span className={style.a}>Campañas</span>
          </div>
        </div>
      </div>
      <div className={style.navLinksB}>
        <div className={style.part}>
          <div className={style.btnExit} onClick={logOut}>
          <span className={style.aExit}>Salir</span>
            <img className={style.iconExit} src={logout} alt="" />
          </div>
        </div>
      </div>
    </nav>
  );
}