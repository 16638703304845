import {useEffect, useRef} from 'react'

const VideoPlayer = (props) => {
    const cloudinaryRef = useRef()
    const videoRef = useRef()
    useEffect(() => {
        if(cloudinaryRef.current) return;
        cloudinaryRef.current = window.cloudinary;
        cloudinaryRef.current.videoPlayer(videoRef.current, {
            cloudName: "dvwtgluwc",
            publicId: props.video,
        })
    },[])
  return (
    <video 
      ref={videoRef}
      data-cld-public-id=""
      width={props.width}
      height={props.height}
      {...props}
    />
  )
}

export default VideoPlayer
