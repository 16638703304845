import React, { useEffect } from 'react'
import styles from "./News.module.css"
import shareIcon from "../../assets/share.png"
import commentIcon from "../../assets/message.png"
import likeIcon from "../../assets/premium.png"
import { formFecha } from '../funciones'
import VideoPlayer from '../Publiaciones/VideoPlayer';
import medallas from "../../assets/medallaTrue.png"

export default function News({ noticias }) {
  console.log(noticias)

  return (
    <div className={styles.conteiner}>
      <div className={styles.noticias}>
        {noticias?.map((noticia) => (
          <div className={styles.contNoticia} key={noticia.id}>
            <div className={styles.contInfo}>
              <div className={styles.contMarca}>
                <img className={styles.marcaLogo} src={noticia.logo} alt="" />
                <div className={styles.marcaInfo}>
                  <h4 className={styles.marcaNombre}>{noticia.empresa}</h4>
                  <p className={styles.marcaLink}>{noticia.titulo}</p>
                </div>
              </div>
              <p className={styles.time}>{formFecha(noticia.createdAt)}</p>
            </div>
            <div className={styles.noticia}>
              <p className={styles.mensaje}>{noticia.contenido}</p>
              {noticia.imagen !== "" && <img className={styles.imgNoticia} src={noticia.imagen} alt="" />}
              {noticia.video !== "" && <div className={styles.contVideo}>
                <VideoPlayer
                  white={720}
                  heigth={420}
                  video={noticia.video}
                  controls
                />
              </div>}
            </div>
            <div className={styles.contReaction}>
              <div className={styles.contR}>
                <div className={styles.recomendarCont}>
                  <div className={styles.recomendar}>
                    <img className={styles.icon} src={likeIcon} alt="" />
                    <p className={styles.reactText}>{noticia.Aplausos.length === 0 ? "" : noticia.Aplausos.length}</p>
                  </div>
                </div>
                <div className={styles.recomendarCont}>
                  <div className={styles.recomendar} >
                    <img className={styles.icon} src={commentIcon} alt="" />
                    <p className={styles.reactText}>{noticia.Comentarios.length === 0 ? "" : noticia.Comentarios.length}</p>
                  </div>
                </div>
                <div className={styles.recomendarCont}>
                  <div className={styles.recomendar}>
                    <img className={styles.icon} src={shareIcon} alt="" />
                    <p className={styles.reactText}>{noticia.compartidos === 0 ? "" : noticia.compartidos}</p>
                  </div>
                </div>
              </div>
              <div className={styles.contM}>
                <div className={styles.recomendar}>
                  <img className={styles.iconB} src={medallas} alt="" />
                  <p className={styles.reactText}>{noticia.Likes.length === 0 ? "" : noticia.Likes.length}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}