import { useState, useEffect } from 'react';
import style from "./Seleccion.module.css";
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Logo.svg";
import subir from "../../assets/SubirImagen.png";
import axios from 'axios';
import { mesesOptions, estadosOptions } from '../funciones';
import municipiosByEstado from "../municipios.json"

export default function BasicaO() {
    const [dia, setDia] = useState("");
    const [mes, setMes] = useState("");
    const [anio, setAnio] = useState("");
    const [origen, setOrigen] = useState("");
    const [direccion, setDireccion] = useState("")
    const [loading, setLoading] = useState(false);
    const usuario = JSON.parse(localStorage.getItem("dataUser"));
    const [foto, setFoto] = useState("");
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const [aviso, setAviso] = useState("")
    const [municipios, setMunicipios] = useState([])

    const preset_key = "qlzrvn25";
    const cloud_name = "drgco4gsh";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;

    const upLoadImage = async (e) => {
        const file = e.target.files[0];
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", preset_key);
        setLoading(true);
        try {
            const response = await axios.post(cloudinaryUrl, data);
            setFoto(response.data.secure_url);
            const { name } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: response.data.secure_url,
            }));
            setLoading(false);
        } catch (error) {
            setAviso("No fue posible  cargar la imagen")
        }
    };

    const continuar = () => {
        if (dia === "" || mes === "" || anio === "" || origen === "" || direccion === "") {
            setAviso("Aun falta campos por llenar")
        } else {
            usuario.dia = dia
            usuario.mes = mes
            usuario.anio = anio
            usuario.origen = origen
            usuario.direccion = direccion
            usuario.foto = foto
            localStorage.setItem("dataUser", JSON.stringify(usuario))
            navigate("/listo");
        }
    };

    const regresar = () => {
        navigate("/intereses");
    }

    const onClickHome = () => {
        navigate("/");
    };

    const diasOptions = [];
    for (let i = 1; i <= 31; i++) {
        diasOptions.push(<option key={i} value={i}>{i}</option>);
    }

    const currentYear = new Date().getFullYear();
    const yearsOptions = [];
    for (let i = currentYear; i >= 1970; i--) {
        yearsOptions.push(<option key={i} value={i}>{i}</option>);
    }

    const getMunicipios = (estado) => {
        if (estado) {
            setDireccion("selecciona una opción")
            const muni = municipiosByEstado[estado]
            const neuvosMuni = []
            for (let i = 0; i < muni.length; i++) {
                neuvosMuni.push(<option key={muni[i]} value={muni[i]}> {muni[i]} </option>)
            }
            setMunicipios(neuvosMuni)
        }
        setDireccion("selecciona una opción")
    }

    useEffect(() => {
        getMunicipios(origen)
        setDireccion("selecciona una opción")
    }, [origen])

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.basicao}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>INFORMACIÓN BÁSICA</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Estas muy cerca de poder acceder a la plataforma, únicamente requerimos un poco de información adicional para completar tu perfil de la plataforma.
                            </span>
                        </div>
                        <div className={style.contFoto}>
                            <p className={style.descripcion}> Agrega tu fotografía o un avatar que te represente</p>
                            <div className={style.contSubida}>
                                <button
                                    className={style.uploadButton}
                                    onClick={() => document.getElementById("fileInput").click()}
                                >
                                    <img className={style.foto} src={subir} alt="success" />
                                </button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    className={style.fileInput}
                                    onChange={upLoadImage}
                                    accept="image/*"
                                    style={{ display: "none" }}
                                />
                                {foto ? <img className={style.fotoPerfil} src={foto} alt="success" /> : <></>}
                            </div>
                        </div>
                        <div className={style.nacimiento}>
                            <p className={style.descripcion}> Fecha de nacimiento</p>
                            <div className={style.contSelect}>
                                <select className={style.selector} onChange={(e) => setDia(e.target.value)}>
                                    {diasOptions}
                                </select>
                                <select className={style.selectorM} onChange={(e) => setMes(e.target.value)}>
                                    {mesesOptions}
                                </select>
                                <select className={style.selector} onChange={(e) => setAnio(e.target.value)}>
                                    {yearsOptions}
                                </select>
                            </div>
                        </div>
                        <div className={style.conto}>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Estado</p>
                                <div className={style.contInp}>
                                    <select className={style.inputCamp} onChange={(e) => setOrigen(e.target.value)}>
                                        {estadosOptions}
                                    </select>
                                </div>
                            </div>
                            <div className={style.camp}>
                                <p className={style.descripcionCamp}>Ciudad</p>
                                <div className={style.contInp}>
                                <select className={style.inputCamp} onChange={(e) => setDireccion(e.target.value)}>
                                        {municipios}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={() => regresar()}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}
