import React, { useState } from 'react'
import styles from "./Notis.module.css"
import logoDDefauld from "../../assets/Logo.svg"
import { formFecha } from '../funciones'
import { dispachSeguir, getSeguidores, getSeguidos } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { meSigue } from '../funciones'
import { useNavigate } from 'react-router'

const Notis = ({ id, mensaje, icono, tipo, createdAt, nombre, leido, link, clickMessage }) => {

  const seguidores = useSelector(state => state.seguidores)
  const seguidos = useSelector(state => state.seguidos)

  const [nuevoSeguidor, setNuevoSeguidor] = useState("Seguir tambien.")

  const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))

  const dispatch = useDispatch()


  const seguirTambien = () => {
    setNuevoSeguidor("Siguiendo.")
    const data = {
      solicitanteID: dataUserLocal.id, 
      receptorID: link
    }
    dispatch(dispachSeguir(data))
    dispatch(getSeguidores(dataUserLocal.id))
    dispatch(getSeguidos(dataUserLocal.id))
  }

  return (
    <div className={styles.container}>
      <img className={styles.icon} src={icono || logoDDefauld} alt="" />
      <div className={styles.info}>
        <div className={styles.entrada}>
          <h4 className={leido ? styles.nombreLeido : styles.nombre}>{nombre}</h4>
          <h4 className={leido ? styles.mensajeLeido : styles.mensaje}>{mensaje}</h4>
        </div>
        <p className={styles.fecha}>{formFecha(createdAt)}</p>
        {tipo === "NuevoSeguidor" && <div>
          {meSigue(seguidos, link) ? <p className={styles.textBtn}>Tambien lo sigues.</p> : <p className={styles.textBtn} onClick={seguirTambien}> {nuevoSeguidor}</p>}
          </div>}
        {tipo === "NuevoSeguido" && <p className={styles.textBtn}> Ir a comunidad</p>}
        {tipo === "NuevaMensaje" && <p className={styles.textBtn} onClick={clickMessage}> Ir a mensajes</p>}
      </div>
    </div>
  )
}

{/* <div className={styles.container} onClick={() => click(tipo, link, id)}></div> */ }

export default Notis
