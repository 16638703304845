import React from 'react'
import { useNavigate } from 'react-router'
import style from "./Politica.module.css"

const Politica = () => {

    const navigate = useNavigate()

    const handleInicio = () => {
        navigate("/")
    }

  return (
    <div className={style.politica}>
        <p className={style.btn} onClick={handleInicio}>{"< Regresar"}</p>
      <h1 className={style.title}>Big Sport Data</h1>
        <h2 className={style.subTitle}>Politica de privacidad</h2>
        <p className={style.into}>Tiene la misión de fortalecer la disciplina deportiva y empoderar a la juventud y niñez a través del alto rendimiento y mejora continua, para que cada uno de ellos muestren lo mejor de sí y cambien su calidad de vida, obteniendo becas deportivas, pruebas etc. Dándoles herramientas que les permitan medir y monitorear su rendimiento de manera continua y sean vistos por todos los clubes deportivos, visores, entrenadores, medios deportivos etc.</p>
        <p className={style.into}>La piedra angular de esta misión es nuestro compromiso de honestidad y transparencia acerca de los datos que recabamos sobre ti, la manera en que se utilizan y con quién se comparten.</p>
        <p className={style.into}>Nuestra Política de privacidad se aplica cuando utilizas todos y cada uno de nuestros Productos y Servicios (descritos a continuación). Nosotros ofertamos a nuestros usuarios distintas opciones acerca de los datos que recopilamos, utilizamos y compartimos, según se describe en esta Política de privacidad, en la Política de cookies, en la Configuración, así como en nuestro Centro de ayuda.</p>
        <h4 className={style.point}>1. Los datos que recopilamos</h4>
        <h5 className={style.subPonit}>1.1 Los datos que nos brindas</h5>
        <p className={style.into}>Nos brindas datos para crear una cuenta en BigSportData.</p>
        <h4 className={style.point}>Registro</h4>
        <p className={style.into}>Para crear una cuenta, debes proporcionarnos datos como tu nombre, tu dirección de email o número de móvil, ubicación general (por ejemplo, ciudad, municipio), así como una contraseña. Si te registras en un servicio de paga, deberás proporcionar la información de pago (por ejemplo, la tarjeta de crédito) y de facturación.</p>
        <p className={style.into}>Creas un perfil en BigSportData (un perfil completo te ayuda a sacar el máximo provecho de nuestros Productos y Servicios).</p>
        <h4 className={style.point}>Perfil</h4>
        <p className={style.into}>En tu perfil tienes diferentes opciones que puedes incluir, como tu edad,peso, talla, posición favorita de jugo, experiencia laboral, aptitudes deportivas, una fotografía, tu ciudad o ubicación, municipio y en su caso un curriculim vitae. No es necesario que proporciones información adicional sobre tu perfil; sin embargo, la información del perfil te ayuda a sacar más provecho de nuestros Productos y Servicios, como facilitar que los reclutadores y/o directores técnicos entre otros,  seleccionar a los jugadores y las oportunidades deportivas que tengan para ti. Tú decides si deseas incluir información delicada en tu perfil y si quieres que esa información sea pública. No publiques o añadas datos personales que no quieras que se hagan públicos.</p>
        <h4 className={style.point}>Publicación y carga de contenido</h4>
        <p className={style.into}>Recopilamos tus datos personales cuando los brindas, los publicas o los cargas en nuestros Productos y Servicios, como cuando rellenas un formulario (por ejemplo, cuando vas con el médico, peso talla, edad etc.) respondes a una autoevaluación o envías un currículum, o cuando rellenas tu perfil o ficha técnica en nuestros Productos y Servicios. </p>
        <p className={style.into}>No es necesario u obligatorio que publiques o cargues datos personales, aunque si no lo haces, es posible que se dificulte o limite tu capacidad para crecer e interactuar con tu red a través de nuestros Productos y Servicios.</p>
        <h5 className={style.subPonit}>1.2 Datos de terceros</h5>
        <p className={style.into}>Es posible que otras personas publiquen contenido o escriban sobre ti.</p>
        <h4 className={style.point}>Contenido y noticias</h4>
        <p className={style.into}>Es probable que tú y otras personas publiquen contenido que incluya información sobre ti (como parte de artículos, publicaciones, comentarios y/o vídeos) en nuestros Productos y Servicios. Es posible que recopilemos información pública sobre ti, como noticias y logros profesionales, métricas deportivas y que la publiquemos como parte de nuestros Productos y Servicios, incluyendo, si tu configuración así lo permite, en notificaciones a terceros de menciones en las noticias.</p>
        <h4 className={style.point}>Información de contacto y del calendario</h4>
        <p className={style.into}>Recibimos tus datos personales (entre los que se incluye la información de contacto) sobre ti, cuandoasocian sus contactos con perfiles de Miembros, escanean y cargan tarjetas de visita, o envían mensajes a través de nuestros Productos y Servicios (incluidas invitaciones o solicitudes de contacto). Si optas u otros optan por sincronizar cuentas de email con nuestros Productos y Servicios, también recopilaremos información del «encabezado del email» que podemos asociar con perfiles de Miembros.</p>
        <p className={style.into}>Nuestros clientes y socios pueden proporcionarnos datos.</p>
        <h4 className={style.point}>Socios</h4>
        <p className={style.into}>Recibimos datos personales tuyos (por ejemplo, tu nombre, experiencia, y dirección de email del trabajo) cuando utilizas los Productos y Servicios de nuestros clientes y socios, como jugadores, reclutadores directores técnicos y otros actuales o potenciales, y sistemas de seguimiento de solicitantes que nos proporcionan datos sobre listas de jugadores.</p>
        <h5 className={style.subPonit}>1.3 Uso de los Productos y Servicios</h5>
        <p className={style.into}>Registramos tus visitas y tu uso de nuestros Productos y Servicios, incluidas las aplicaciones móviles.</p>
        <p className={style.into}>Registramos los datos de uso cuando visitas o utilizas de otro modo nuestros Productos y Servicios, incluidos nuestros sitios web, las aplicaciones y la tecnología de la plataforma, como cuando ves o haces clic en un contenido (por ejemplo, un vídeo de una métrica) o en anuncios (en nuestros sitios web y aplicaciones, o fuera de ellos), realizas una búsqueda, instalas o actualizas una de nuestras aplicaciones móviles, compartes artículos o solicitas oportunidad deportiva. Utilizamos registros de inicio de sesión, información de correo electrónico y direcciones de protocolo de Internet («IP») para identificarte y registrar tu uso en BigSportData.</p>
        <h5 className={style.subPonit}>1.4 Cookies y otras tecnologías similares</h5>
        <p className={style.into}>Recopilamos datos a través de cookies y tecnologías similares.</p>
        <p className={style.into}>Tal y como se describe en nuestra Política de cookies, utilizamos cookies y tecnologías similares (por ejemplo, balizas web y etiquetas de anuncios) para recopilar información (por ejemplo, identificadores de dispositivos) para reconocerte a ti y/o a tu dispositivo o dispositivos en los diferentes Productos y Servicios y dispositivos. También permitimos que otras personas usen cookies en el modo descrito en nuestra Política de cookies. Si no resides en uno de los Países designados, recopilamos (o terceros a los que recurrimos recopilan) información sobre tu dispositivo (por ejemplo, ID del anuncio, dirección IP, sistema operativo e información del navegador) para ofrecer a nuestros Miembros anuncios relevantes y entender mejor su eficacia. Más información. También puedes marcar la opción de autoexclusión para que no utilicemos la información de las cookies ni de tecnologías similares para hacer un seguimiento de tu comportamiento en otros sitios web para la publicidad de terceros.</p>
        <h5 className={style.subPonit}>1.5 Tu dispositivo y tu ubicación</h5>
        <p className={style.into}>Recopilamos datos a través de cookies y tecnologías similares.</p>
        <p className={style.into}>Cuando visitas o abandonas nuestros Productos y Servicios (incluidos algunos complementos, así como nuestras cookies o tecnologías similares en otros sitios web), recibimos la URL tanto del sitio del que has llegado como del sitio al que te diriges, además de la hora en la que has realizado tu visita. También recibimos información sobre tu red y tu dispositivo (por ejemplo, la dirección IP, el servidor proxy, el sistema operativo, el navegador web y los complementos, el identificador y las funciones de tu dispositivo, los identificadores de las cookies, el proveedor de Productos y Servicios de Internet o el operador de red móvil). Si utilizas nuestros Productos y Servicios desde un dispositivo móvil, ese dispositivo nos enviará los datos de tu ubicación en función de la configuración de tu teléfono. Te pediremos que marques la opción de autoinclusión para que podamos utilizar el GPS u otras herramientas para identificar tu ubicación exacta.</p>
        <h5 className={style.subPonit}>1.6 Mensajes</h5>
        <p className={style.into}>Si te comunicas a través de nuestros Productos y Servicios, recibiremos esa información.</p>
        <p className={style.into}>Recabamos información sobre ti cuando envías, recibes o interactúas con mensajes relacionados con nuestros Productos y Servicios. Por ejemplo, si recibes una solicitud de contacto en BigSportData, realizamos un seguimiento para ver si has realizado alguna acción al respecto y te mandaremos recordatorios. </p>
        <h5 className={style.subPonit}>1.7 Datos proporcionados por la empresa y la institución deportiva</h5>
        <p className={style.into}>Cuando tu organización (por ejemplo, tu empresa o institución deportiva, centro de formación, academia deportiva, escuela de futbol etc.) adquiere un Servicio Premium para que lo uses, nos proporcionan datos sobre ti.
        Otras instancias que adquieran nuestros Productos y Servicios para que los utilices, como tu empresa o tu institución deportiva, centro de formación, academia deportiva, escuela de futbol etc, nos proporcionan datos personales tuyos, así como tu derecho a usar los Productos y Servicios que compran para que sus empleados, estudiantes o antiguos alumnos los utilicen. Por ejemplo, obtendremos información de contacto de los administradores de las «Páginas de BigSportData» y de los usuarios con autorización para utilizar nuestros Productos y Servicios Premium, como nuestros productos de contratación, de ventas o de formación.
        </p>
        <h5 className={style.subPonit}>1.8 Sitios y Productos y Servicios de terceros</h5>
        <p className={style.into}>Obtenemos datos cuando visitas sitios que incluyen nuestros anuncios, cookies o alguno de nuestros complementos, o bien cuando inicias sesión en los Productos y Servicios de otras personas a través de tu cuenta de BigSportData.</p>
        <p className={style.into}>Recibimos información sobre tus visitas e interacción con los Productos y Servicios proporcionados por otras personas cuando inicias sesión con BigSportData o visitas Productos y Servicios de otras personas que incluyen alguno de nuestros complementos (como «Solicitar con BigSportData»), o bien nuestros anuncios, cookies o tecnologías similares.</p>
        <h5 className={style.subPonit}>1.9 Otra información</h5>
        <p className={style.into}>Estamos mejorando y actualizando nuestros Productos y Servicios, lo que significa que recibimos nuevos datos, creamos nuevos modos de manejar la información o datos y estamos en constante evolución.</p>
        <p className={style.into}>Nuestros Productos y Servicios son dinámicos y con frecuencia introducimos nuevas funcionalidades, que pueden requerir la recopilación de nueva información. Si recabamos datos personales muy diferentes o si cambiamos en gran medida el modo en que recopilamos, usamos o compartimos tus datos, te lo notificaremos y también podríamos modificar esta Política de privacidad.</p>
        <h4 className={style.point}>2. Cómo utilizamos tus datos</h4>
        <p className={style.into}>Utilizamos tus datos para proporcionar, apoyar, personalizar y desarrollar nuestros Productos y Servicios.</p>
        <p className={style.into}>El modo en que utilizamos tus datos personales dependerá de los Productos y Servicios que utilices, de la forma en que uses esos Productos y Servicios y de tu configuración. Utilizamos tus datos personales a nuestra disposición para proporcionar y personalizar nuestros Productos y Servicios (incluidos los anuncios), con la ayuda de sistemas automatizados y de las deducciones que realizamos, de manera que puedan resultar más relevantes y útiles, tanto para ti como para otros.</p>
        <h5 className={style.subPonit}>2.1 Productos y Servicios</h5>
        <p className={style.into}>Nuestros Productos y Servicios te ayudan a conectar con otras personas, encontrar empleos y oportunidades deportivas o dejar que ellos te encuentren, mantenerte informado, formarte y ser mejor atleta.</p>
        <p className={style.into}>Utilizamos tus datos para autorizar tu acceso a nuestros Productos y Servicios según tu configuración personal.</p>
        <h4 className={style.point}>Mantente conectado</h4>
        <p className={style.into}>Nuestros Productos y Servicios te permiten mantenerte al día y en contacto con tus, amigos, colegas, entrenadores, reclutadores  y otros contactos profesionales. Para hacerlo, puedes «conectar» con los profesionales que escojas y que también deseen «conectar» contigo. En función de tu configuración y la de ellos, cuando conectes con otros Miembros podrás buscar los contactos de los demás con el fin de intercambiar oportunidades profesionales.</p>
        <p className={style.into}>Utilizamos tus datos (como tu perfil, los perfiles que has visto o los datos proporcionados a través de la carga de tu agenda de direcciones o integraciones de socios) para que otras personas encuentren tu perfil, para sugerirte contactos a ti y a otras personas (por ejemplo, Miembros que tienen contactos o experiencias profesionales en común contigo) y para ofrecerte la posibilidad de invitar a otras personas a hacerse Miembros y conectar contigo. También puedes autoincluirte para permitirnos hacer uso de tu ubicación exacta o de la proximidad con otras personas para determinadas tareas (por ejemplo, para sugerirte otros Miembros cerca de ti con los que conectar, calcular el desplazamiento a un nuevo nivel o notificar a tus contactos que te encuentras logrando nuevas metas).</p>
        <p className={style.into}>Tú decides si deseas invitar a alguien a participar en nuestros Productos y Servicios, si envías una solicitud de contacto o si permites que otro Miembro se convierta en tu contacto. Cuando invites a alguien a conectar contigo, la invitación contendrá información sobre tu red, así como la información básica del perfil o ficha técnica (por ejemplo, el nombre, la foto del perfil, talla, peso, edad u origen). Enviaremos recordatorios a la persona que hayas invitado. </p>
        <p className={style.into}>Los Visitantes tienen la opción de escoger el modo en que utilizamos sus datos.</p>
        <h4 className={style.point}>Mantente informado</h4>
        <p className={style.into}>Nuestros Productos y Servicios te permiten mantenerte informado acerca de noticias, eventos e ideas sobre los temas profesionales que te interesan y de los profesionales que admiras. Nuestros Productos y Servicios también te permiten mejorar tus aptitudes profesionales o adquirir otras nuevas. Utilizamos tus datos personales a nuestra disposición (por ejemplo, los datos que brindas, los que recopilamos a través de tu interacción con nuestros Productos y Servicios y las deducciones que realizamos a partir de los datos de los que disponemos) para personalizar nuestros Productos y Servicios a tu gusto, por ejemplo, mediante recomendaciones o clasificando contenido y conversaciones relevantes en nuestros Productos y Servicios. También utilizamos estos datos para sugerirte aptitudes que podrías añadir a tu perfil y otras aptitudes que podrías necesitar para postular a nuevas oportunidades deportivas. Así que, si nos comunicas que estás interesado en una nueva aptitud (por ejemplo, al ver un vídeo de velocidad), utilizaremos dicha información para personalizar el contenido de tu feed de actividad, sugerirte que sigas a determinados miembros en nuestro sitio o para recomendarte contenido de aprendizaje relacionado a fin de ayudarte a adquirir esa nueva aptitud. Utilizamos tu contenido, tu actividad y tus datos, incluidos tu nombre y tu foto, para enviar notificaciones a tu red y a otras personas. Por ejemplo, en función de tu configuración, podemos notificar a otras personas si actualizas tu perfil, publicas contenido, realizas una acción social, utilizas alguna funcionalidad, tienes un nuevo contacto o se te menciona en las noticias.</p>
        <h4 className={style.point}>Oportunidades deportivas y Empleos</h4>
        <p className={style.into}>Nuestros Productos y Servicios te permiten encontrar oportunidades deportivas, evaluar oportunidades de formación y buscar oportunidades en el mundo deportivo y permitir que ellas te encuentren. Tanto las personas que están buscando candidatos con talento deportivo (para una beca deportiva o pertenecer a un equipo amateur u profesional) como aquellas que buscan una oportunidad de prueba, pueden encontrar tu perfil. Utilizaremos tus datos para recomendarte clubes, entrenadores, reclutadores o a personas que buscan asesoramiento, mostrarte a ti y a otras personas contactos profesionales destacados (por ejemplo, quién trabaja en un club deportivo, en un sector, en una función o ubicación, o quién tiene determinadas aptitudes y contactos). Puedes indicar que tienes interés en buscar o cambiar de equipo y compartir información con los clubes, entrenadores, reclutadores o a personas de selección de jugadores. Utilizaremos tus datos para recomendarte empleos y recomendarte a los clubes, entrenadores, reclutadores o a personas de selección de jugadores. Podemos utilizar sistemas automatizados para proporcionar contenido y recomendaciones con el fin de contribuir a que nuestros Productos y Servicios sean más relevantes para nuestros Miembros, Visitantes y Clientes. Mantener un perfil actualizado con datos precisos puede ayudarte a conectar mejor con otras personas y a lograr mejores oportunidades a través de nuestros Productos y Servicios.</p>
        <h4 className={style.point}>Productividad</h4>
        <p className={style.into}>Nuestros Productos y Servicios te permiten colaborar con, amigos, colegas, buscar clientes potenciales, clientes, socios y a otras personas con las que hacer negocios. Nuestros Productos y Servicios te permiten comunicarte con otros Miembros y programar y organizar reuniones deportivas con ellos. </p>
        <h5 className={style.subPonit}>2.2 Productos y Servicios Premium</h5>
        <p className={style.into}>Nuestros Productos y Servicios Premium ayudan a los usuarios de pago a buscar Miembros a través de nuestros Productos y Servicios (como candidatos para una prueba deportiva, posibles clientes y compañeros del deporte) y a contactar con ellos para gestionar candidatos, así como para promover contenido a través de las redes sociales.</p>
        <p className={style.into}>Vendemos Productos y Servicios Premium que proporcionan a nuestros clientes y suscriptores funcionalidades y herramientas de búsqueda personalizadas (como mensajes y alertas de actividad) como parte de nuestras soluciones de candidatos, de marketing y de ventas. Los clientes pueden exportar información limitada de tu perfil, como el nombre, el titular, edad, peso, talla, posición de juego, pierna hábil y la ubicación general (por ejemplo, México), con el fin de gestionar los posibles clientes de ventas o candidatos, salvo que marques la opción de autoexclusión. No proporcionamos información de contacto a clientes como parte de estos Productos y Servicios Premium sin tu consentimiento. Los clientes de Productos y Servicios Premium pueden almacenar la información que tengan sobre ti en nuestros Productos y Servicios Premium, listas de reclutamiento, el currículum, la información de contacto o el historial deportivo. Los datos que estos clientes almacenen sobre ti dependerán de las políticas de los dichos clientes. 2.3 </p>
        <h4 className={style.point}>Comunicaciones</h4>
        <p className={style.into}>Te contactamos y permitimos las comunicaciones entre Miembros. Contactaremos contigo por email, por teléfono móvil, a través de avisos publicados en los sitios web o aplicaciones de BigSportData, mensajes en tu buzón de BigSportData y a través de otros medios disponibles en nuestros Productos y Servicios, incluidos los mensajes de texto y las notificaciones automáticas. Te enviaremos mensajes sobre la disponibilidad de nuestros Productos y Servicios, la seguridad u otras cuestiones relacionadas con los Productos y Servicios prestados. Te mandaremos también mensajes sobre cómo usar nuestros Productos y Servicios, actualizaciones de red, recordatorios, sugerencias de oportunidad deportiva y mensajes promocionales, tanto nuestros como de nuestros socios. Puedes cambiar tus preferencias de comunicación en cualquier momento. Ten en cuenta que no puedes autoexcluirte de nuestros mensajes de servicio, incluidos avisos de seguridad y de naturaleza jurídica.</p>
        <p className={style.into}>También posibilitamos las comunicaciones entre tú y otras personas a través de nuestros Productos y Servicios, incluidos, por ejemplo, las invitaciones, los mensajes InMail,los grupos y los mensajes entre contactos.</p>
        <h5 className={style.subPonit}>2.4 Publicidad</h5>
        <p className={style.into}>Te ofrecemos anuncios personalizados dentro y fuera de los Productos y Servicios. Te ofrecemos opciones relacionadas con anuncios personalizados, pero no puedes autoexcluirte de la visualización de otros anuncios.</p>
        <p className={style.into}>Mostramos anuncios (y medimos su rendimiento) a Miembros, Visitantes y a otras personas dentro y fuera de nuestros Productos y Servicios directamente o a través de una variedad de socios, utilizando los siguientes datos, de manera independiente o combinada:</p>
        <ul>
            <li className={style.into}>Datos de tecnologías de publicidad dentro y fuera de nuestros Productos y Servicios, como píxeles, etiquetas de anuncios, cookies e identificadores de dispositivos.</li>
            <li className={style.into}>Datos proporcionados por los Miembros (por ejemplo, perfil, información de contacto, cargo y sector).</li>
            <li className={style.into}>Datos sobre tu uso de nuestros Productos y Servicios (por ejemplo, el historial de búsqueda, el feed de actividad, el contenido que lees, a quién estás siguiendo o te está siguiendo, los contactos, la participación en grupos, las visitas a páginas, los vídeos que ves, los clics en anuncios, etc.), incluido lo que se describe en la Sección 1.3.</li>
            <li className={style.into}>Información de socios publicitarios, proveedores y editores; y</li>
            <li className={style.into}>Cualquier información deducida a partir de los datos descritos anteriormente (por ejemplo, utilizando los cargos de un perfil para deducir el sector, el nivel de responsabilidad y la escala de compensación; utilizando las fechas de graduación para deducir la edad o los nombres y el uso de pronombres para deducir el género; utilizando tu feed de actividad para deducir tus intereses; o utilizando los datos de tu dispositivo para reconocerte como Miembro).</li>
        </ul>
        <p className={style.into}>Te mostraremos anuncios denominados contenido patrocinado, que se asemejan a los anuncios de contenido no patrocinado, con la diferencia de que se etiquetan como publicidad (por ejemplo, «anuncio» o «patrocinado»). Si realizas alguna acción social (como dar un aplauso, comentar o compartir) relacionada con estos anuncios, tu acción se asociará a tu nombre y será visible para otras personas, incluido el anunciante. En función de tu configuración, si realizas una acción social relacionada con los Productos y Servicios de BigSportData, dicha acción puede mencionarse con anuncios relacionados. Por ejemplo, cuando aplaudes una empresa, es posible que incluyamos tu nombre y tu foto cuando se muestra su contenido patrocinado.</p>
        <h4 className={style.point}>Opciones de publicidad</h4>
        <p className={style.into}>Cumplimos los principios de autorregulación para publicidad basada en intereses y participamos en las opciones de autoexclusión del sector para dicha publicidad. Pero no por ello dejarás de recibir publicidad: continuarás viendo otros anuncios de anunciantes no enumerados en estas herramientas de autorregulación. También puedes autoexcluirte específicamente de nuestros usos de determinadas categorías de datos para mostrarte anuncios más relevantes. </p>
        <h4 className={style.point}>Información para anunciantes</h4>
        <p className={style.into}>No compartimos tus datos personales con ningún tercero anunciante ni con redes publicitarias, excepto en los casos o para los supuestos siguientes: (i) identificadores cifrados o de dispositivos (en la medida en que se consideren datos personales en algunos países); (ii) con tu permiso explícito (por ejemplo, mediante un formulario de generación de contactos); o (iii) datos que ya sean visibles para cualquier usuario de los Productos y Servicios (por ejemplo, los datos del perfil). Sin embargo, si ves un anuncio o haces clic en este, dentro o fuera de nuestros Productos y Servicios, el anunciante recibirá una señal de que alguien ha visitado la página que muestra el anuncio y es posible que determinen que has sido tú mediante el uso de mecanismos como las cookies. Nuestros socios publicitarios pueden asociar datos personales recopilados por el anunciante directamente de ti mediante identificadores cifrados o de dispositivos que reciben de nosotros. En esos casos, exigimos contractualmente que esos socios publicitarios obtengan tu consentimiento explícito antes de hacerlo.</p>
        <h5 className={style.point}>2.5 Marketing</h5>
        <p className={style.into}>Promovemos nuestros Productos y Servicios ante ti y otras personas.</p>
        <p className={style.into}>Además de publicitar nuestros Productos y Servicios, utilizamos datos y contenido de los Miembros para invitaciones y comunicaciones que promueven el crecimiento de la red, una mayor adhesión y un mayor grado de compromiso, así como el desarrollo de nuestros Productos y Servicios, como, por ejemplo, al mostrar a tus contactos que has utilizado alguna funcionalidad de dichos Productos y Servicios.</p>
        <h5 className={style.subPonit}>2.6 Desarrollo de los Productos y Servicios e investigación</h5>
        <p className={style.into}>Desarrollamos nuestros Productos y Servicios y realizamos investigaciones</p>
        <h4 className={style.point}>Desarrollo del Servicio</h4>
        <p className={style.into}>Utilizamos datos, incluidos los comentarios públicos, para realizar investigaciones y desarrollar nuestros Productos y Servicios a fin de proporcionarte a ti y a otros Miembros una experiencia más personalizada e intuitiva, aumentar el número de Miembros y la interacción en nuestros Productos y Servicios, y ayudar a que los profesionales se conecten entre sí y tengan acceso a oportunidades económicas.</p>
        <h4 className={style.point}>Otras investigaciones</h4>
        <p className={style.into}>Buscamos crear oportunidades económicas para Miembros de todo el mundo y ayudarles a ser más productivos y a alcanzar sus metas deportivas. Utilizamos los datos personales de que disponemos para investigar tendencias sociales, económicas y corporativas, como la disponibilidad de espacios en equipos y las aptitudes necesarias para entrar a un club deportivo, así como las políticas que contribuyen a tender puentes entre los distintos sectores y zonas geográficas. En algunos casos, trabajamos con terceras partes de confianza para llevar a cabo esta investigación, con controles diseñados para proteger tu privacidad. Publicamos o permitimos que otras personas publiquen información económica, presentada como datos agregados en lugar de como datos personales.</p>
        <h4 className={style.point}>Encuestas</h4>
        <p className={style.into}>Nosotros o terceros llevamos a cabo los sondeos y encuestas que realizamos a través de nuestros Productos y Servicios. No estás obligado a responder a los sondeos o encuestas y dispones de diferentes opciones sobre la información que brindas. Puedes marcar la opción de autoexclusión para no recibir invitaciones de encuestas.</p>
        <h5 className={style.subPonit}>2.7 Atención al cliente</h5>
        <p className={style.into}>Utilizamos los datos para ayudarte y solucionar problemas.</p>
        <p className={style.into}>Utilizamos datos (como tus comunicaciones) para investigar, responder y resolver quejas, así como para problemas con el Servicio (por ejemplo, errores).</p>
        <h5 className={style.subPonit}>2.8 Datos que no te identifican</h5>
        <p className={style.into}>Usamos tu información para generar datos que no te identifiquen.</p>
        <p className={style.into}>Utilizamos tus datos para generar y compartir información que no te identifica. Por ejemplo, podemos utilizar tus datos para generar estadísticas acerca de nuestros miembros, su profesión o sector; para calcular impresiones o clics en un anuncio; o para publicar información detallada de los visitantes de un Servicio u obtener información detallada de la fuerza laboral.</p>
        <h5 className={style.subPonit}>2.9 Seguridad e investigaciones</h5>
        <p className={style.into}>Utilizamos los datos por motivos de seguridad, para la prevención de casos de fraude e investigaciones.</p>
        <p className={style.into}>Utilizamos tus datos (incluidas tus comunicaciones) con fines de seguridad o para prevenir o investigar posibles casos de fraude u otro tipo de infracciones de nuestras Condiciones de uso, o intentos de dañar a nuestros Miembros, Visitantes u otros.</p>
        <h4 className={style.point}>3. Cómo compartimos la información</h4>
        <h5 className={style.subPonit}>3.1 Nuestros Productos y Servicios</h5>
        <p className={style.into}>Todos los datos que incluyas en tu perfil y cualquier contenido que publiques o acción social (por ejemplo, recomendaciones, contenido seguido, comentarios o contenido compartido) que realices en nuestros Productos y Servicios serán vistos por otras personas, de acuerdo con tu configuración.</p>
        <h4 className={style.point}>Perfil</h4>
        <p className={style.into}>Tu perfil es visible para todos los Miembros y clientes de nuestros Productos y Servicios. En función de tu configuración, también puede ser visible para otras personas dentro o fuera de nuestros Productos y Servicios (por ejemplo, Visitantes de nuestros Productos y Servicios o usuarios de motores de búsqueda externos). </p>
        <h4 className={style.point}>Publicaciones, recomendaciones, contenido seguido, comentarios y mensajes</h4>
        <p className={style.into}>Nuestros Productos y Servicios permiten que se vea y se comparta información, incluso a través de publicaciones, recomendaciones, contenido seguido y comentarios.</p>
        <ul>
            <li className={style.into}>Cuando compartes de manera pública un artículo o una publicación (por ejemplo, una actualización, una imagen, un vídeo o un artículo), todos pueden verlo y volverlo a compartir en cualquier lugar (en función de tu configuración). Los Miembros, los Visitantes y otras personas podrán encontrar y ver el contenido que hayas compartido públicamente, incluido tu nombre (y tu foto si la has proporcionado).</li>
            <li className={style.into}>En un grupo, las publicaciones son visibles para los demás miembros del grupo. Tu pertenencia a un grupo es pública, así como parte de tu perfil, pero puedes cambiar la visibilidad en tu configuración.</li>
            <li className={style.into}>Cualquier información que compartas a través de las páginas de empresas o de otras organizaciones en nuestros Productos y Servicios podrá ser vista por otras personas que visiten esas páginas.</li>
            <li className={style.into}>Cuando sigas a una persona u empresa, tu perfil será visible para otras personas y para el «propietario de la página» como seguidor.</li>
            <li className={style.into}>Permitimos a los remitentes saber cuando actúas en relación con su mensaje, en función de tu configuración cuando resulte aplicable.</li>
            <li className={style.into}>En función de tu configuración, notificamos a un Miembro cuando ves su perfil.</li>
            <li className={style.into}>Cuando aplaudes o vuelves a compartir un comentario en otro tipo de contenido (incluidos los anuncios), otras personas podrán ver que has realizado estas «acciones sociales» y asociar ese contenido contigo (por ejemplo, tu nombre, perfil y foto, si los has proporcionado).</li>
        </ul>
        <p className={style.into}>Tu empresa puede ver cómo utilizas los Productos y Servicios que te han proporcionado para ayudarte en tu carrera deportiva (por ejemplo, como reclutador  o representante deportivo) y la información relacionada. No mostraremos tus búsquedas de empleo ni tus mensajes personales.</p>
        <h4 className={style.point}>Cuentas de empresas o clubes deportivos </h4>
        <p className={style.into}>Puede que tu empresa te ofrezca acceder a nuestros Productos y Servicios de empresa como Reclutador, Director técnico, Preparador físico, Médico etc. o nuestro Administrador de campañas publicitarias. Tu empresa puede revisar y gestionar tu uso de esos Productos y Servicios de empresa.</p>
        <p className={style.into}>En función del Servicio de empresa, y antes de que utilices dicho Servicio, te pediremos permiso para compartir datos relevantes de tu perfil con tu empleador o para hacer uso de nuestros Productos y Servicios que no son para empresas. Por ejemplo, a los usuarios de la versión de paga  se les pedirá que compartan su índice de ventas con las redes sociales, un baremo calculado parcialmente en base a la actividad de su cuenta personal. Comprendemos que determinadas actividades como los mensajes personales y aquellas relacionadas con la búsqueda de empleo son sensibles y, por tanto, no compartiremos esa información con tu empresa, salvo que escojas compartirla a través de nuestros Productos y Servicios (por ejemplo, solicitando un nuevo puesto en la misma empresa o si mencionas que estás buscando empleo en un mensaje a un compañero de trabajo a través de nuestros Productos y Servicios).</p>
        <p className={style.into}>En función de tu configuración, es posible que tu empleador disponga de ciertos datos tuyos cuando uses Productos y Servicios o herramientas del lugar de trabajo (por ejemplo, herramientas de directorios interactivos de jugadores), o bien que estos datos se conecten con información que recibamos de tu empleador con el fin de activar estos Productos y Servicios y herramientas.</p>
        <h5 className={style.subPonit}>3.2 Archivo de las comunicaciones</h5>
        <p className={style.into}>A aquellos Miembros que trabajan en un entorno regulado puede que se les exija almacenar las comunicaciones fuera de nuestro Servicio.</p>
        <p className={style.into}>Algunos Miembros (o sus empleados) deben, por razones legales o de cumplimiento profesional, archivar sus comunicaciones y actividad en las redes sociales, y utilizarán Productos y Servicios de terceros para estos Productos y Servicios de archivo. Nosotros permitimos dicha actividad de archivo de comunicaciones por y para esos Miembros fuera de nuestros Productos y Servicios. Por ejemplo, un asesor financiero debe archivar las comunicaciones con su cliente a través de nuestros Productos y Servicios para mantener su licencia de asesor financiero profesional.</p>
        <h5 className={style.subPonit}>3.3. Productos y Servicios de terceros</h5>
        <p className={style.into}>Puedes vincular tu cuenta con los Productos y Servicios de terceros para que puedan buscar los perfiles de tus contactos, publicar tu contenido compartido en esas plataformas o habilitarte para entablar conversaciones con tus contactos en dichas plataformas. Los extractos de tu perfil se mostrarán también en los Productos y Servicios de terceros.</p>
        <p className={style.into}>En función de tu configuración, otros Productos y Servicios pueden buscar tu perfil. Cuando decides vincular tu cuenta con otros Productos y Servicios, tus datos personales (por ejemplo, tu nombre, peso y ciudad de origen) estarán disponibles para los mismos. Se describirá, o se asociará, en una pantalla de consentimiento qué se entiende por compartir y utilizar esos datos personales cuando vincules las cuentas. Por ejemplo, puedes asociar tus cuentas con otras plataformas o redes sociales (ejemplo Tick tok, Facebook) para compartir contenido de nuestros Productos y Servicios en esos otros Productos y Servicios, o tu proveedor de email puede darte la opción de cargar tus contactos de BigSportData en su propio servicio. Los Productos y Servicios provistos por terceros tienen sus respectivas Políticas de privacidad y es posible que les otorgues permiso para utilizar tus datos de maneras en las que nosotros no lo haríamos. Puedes revocar la vinculación de esas cuentas.</p>
        <p className={style.into}>En función de tu configuración, aparecerán extractos de tu perfil en los Productos y Servicios de terceros (como en los resultados de motores de búsqueda, aplicaciones de email y de calendario que muestran a un usuario datos limitados del perfil de la persona con la que va a reunirse o comunicarse, agregadores de redes sociales, o administradores de candidatos o de posibles clientes). La información de perfil «antigua» permanecerá en estos Productos y Servicios hasta que estos Productos y Servicios actualicen su caché de datos con los cambios que hayas realizado en tu perfil.</p>
        <h5 className={style.subPonit}>3.4 Productos y Servicios relacionados</h5>
        <p className={style.into}>Compartimos tus datos entre nuestros Productos y Servicios y con las entidades afiliadas a BigSportData.</p>
        <p className={style.into}>Compartiremos tus datos personales con nuestras empresas afiliadas para ofrecer y desarrollar nuestros Productos y Servicios. Por ejemplo, podemos remitir tu consulta a google en algunos casos, como cuando una respuesta más actualizada pueda beneficiarte. Podremos combinar la información internamente entre los diferentes Productos y Servicios cubiertos por esta Política de privacidad para ayudar a que nuestros Productos y Servicios resulten más relevantes y útiles tanto para ti como para otros. Por ejemplo, podemos personalizar tu feed de actividad o las recomendaciones de empleo según tu historial de aprendizaje.</p>
        <h5 className={style.subPonit}>3.5 Proveedores de Productos y Servicios</h5>
        <p className={style.into}>Podemos utilizar otras empresas para ayudarnos con nuestros Productos y Servicios.</p>
        <p className={style.into}>Utilizamos terceras partes para ayudarnos a proporcionar nuestros Productos y Servicios (por ejemplo, mantenimiento, análisis, auditorías, pagos, detección de casos de fraude, atención al cliente, marketing y desarrollo). Dichas terceras partes tendrán un acceso limitado a tu información (por ejemplo, el contenido de una solicitud de atención al cliente) en la medida necesaria para ejecutar estas tareas en representación nuestra y están obligadas a no develarla ni utilizarla para otros fines.</p>
        <h5 className={style.subPonit}>3.6 Divulgaciones requeridas por ley</h5>
        <p className={style.into}>Puede que debamos compartir tus datos cuando pensemos que es necesario por ley o para proteger tus derechos y seguridad, los nuestros o los de terceros.</p>
        <p className={style.into}>Puede que debamos divulgar tu información cuando se exija por ley, en una citación u otro procedimiento judicial o si creemos, de buena fe, que la divulgación es razonablemente necesaria para (1) investigar, prevenir o actuar frente a supuestas actividades ilegales o para ayudar a las fuerzas de seguridad; (2) para hacer cumplir los acuerdos que tenemos contigo; (3) investigar y defendernos de reclamaciones o alegaciones de terceros; (4) proteger la seguridad o la integridad de nuestros Productos y Servicios (por ejemplo, compartiendo información con empresas que se enfrentan a amenazas similares); o (5) ejercer o proteger los derechos y la seguridad de BigSportData, nuestros Miembros, nuestro personal u otras personas. Tratamos de notificar a los Miembros que sus datos personales han sido solicitados jurídicamente cuando lo consideremos adecuado a nuestro juicio, salvo que se prohíba por ley, por una orden judicial o cuando la solicitud sea una emergencia. Podemos poner en tela de juicio dichos requerimientos cuando consideremos, a nuestro entender, que las solicitudes son excesivas, vagas o carecen de la autoridad necesaria, aunque no nos comprometemos a desafiar cada petición. </p>
        <h5 className={style.subPonit}>3.7 Cambio de control o venta</h5>
        <p className={style.into}>Podemos compartir tus datos cuando nuestra empresa se venda a otras empresas, pero deberán utilizarse de acuerdo con esta Política de privacidad.</p>
        <p className={style.into}>También podemos compartir tus datos personales como parte de una venta, fusión o cambio de control, o para preparar cualquiera de estas circunstancias. Cualquier otra entidad que compre toda o parte de la empresa tendrá derecho a continuar utilizando tus datos, pero únicamente en el modo descrito en esta Política de privacidad a menos que aceptes lo contrario.</p>
        <h4 className={style.point}>4. Tus opciones y obligaciones</h4>
        <h5 className={style.subPonit}>4.1 Conservación de datos</h5>
        <p className={style.into}>Conservamos la mayor parte de tus datos personales siempre que mantengas la cuenta abierta.</p>
        <p className={style.into}>Por lo general, conservamos tus datos personales durante la totalidad del tiempo que mantengas tu cuenta abierta, o bien durante el período necesario para proporcionarte nuestros Productos y Servicios. Esto incluye los datos que tú u otras personas nos habéis proporcionado o los datos generados o inferidos de tu uso de los Productos y Servicios. Incluso aunque solo utilices nuestros Productos y Servicios cuando busques una oportunidad, prueba deportiva o empleo, cada varios años, conservaremos tu información y mantendremos tu perfil abierto, a menos que cierres tu cuenta. En algunos casos, decidimos conservar determinada información (por ejemplo, información sobre el uso de los Productos y Servicios) en un formato despersonalizado o agregado.</p>
        <h5 className={style.subPonit}>4.2 Derecho de acceso y control de tus datos personales</h5>
        <p className={style.into}>Puedes acceder o borrar tus datos personales. Tienes muchas opciones a la hora de elegir cómo se recopilan, utilizan y comparten tus datos.</p>
        <p className={style.into}>Proporcionamos numerosas opciones para recopilar, utilizar y compartir tus datos, desde borrar o corregir los datos que incluyes en el perfil y controlar la visibilidad de tus publicaciones. Te ofrecemos una configuración que te permite controlar y gestionar los datos personales que tenemos sobre ti.</p>
        <p className={style.into}>En lo que respecta a tus datos personales a nuestra disposición, puedes:</p>
        <ul>
            <li className={style.into}>Eliminar datos: puedes solicitarnos que borremos o eliminemos todos o parte de tus datos personales (por ejemplo, si ya no es necesario ofrecerte Productos y Servicios).</li>
            <li className={style.into}>Cambiar o rectificar datos: puedes editar parte de tus datos personales a través de tu cuenta. También puedes solicitarnos la modificación, actualización o corrección de tus datos en ciertos casos, especialmente si no son precisos.</li>
            <li className={style.into}>Derecho de acceso y/o recepción de tus datos: puedes solicitarnos una copia de tus datos personales y una copia de los datos personales que hayas proporcionado en formato de lectura mecánica.</li>
        </ul>
        <p className={style.into}>Los Visitantes pueden obtener más información sobre cómo realizar estas solicitudes aquí. También puedes contactar con nosotros utilizando la información de contacto que aparece más abajo; gestionaremos tu solicitud conforme a la legislación aplicable.</p>
        <p className={style.into}>Es posible que los residentes en los Países designados y otras regiones gocen de derechos adicionales en virtud de la legislación aplicable.</p>
        <h5 className={style.subPonit}>4.3 Cierre de la cuenta</h5>
        <p className={style.into}>Conservamos algunos de tus datos incluso después de haber cerrado tu cuenta.</p>
        <p className={style.into}>Si decides cerrar tu cuenta de BigSportData, tus datos personales dejarán de verse en nuestros Productos y Servicios, por lo general, en un plazo de 24 horas. Normalmente, borramos la información de las cuentas cerradas en un plazo de 30 días desde el cierre de la cuenta, salvo en los casos citados a continuación.</p>
        <p className={style.into}>Conservamos tus datos personales incluso después de haber cerrado la cuenta si es razonablemente necesario para cumplir con nuestras obligaciones legales (incluidas, las peticiones de las fuerzas del orden), reunir los requisitos reglamentarios, resolver disputas, mantener la seguridad, evitar casos de fraude y abuso (por ejemplo, en caso de que hayamos restringido tu cuenta debido a un incumplimiento de nuestras Políticas para la comunidad profesional), aplicar nuestras Condiciones de uso, o cumplir tu solicitud de darte de baja de futuros mensajes que recibas de BigSportData. Una vez que tu cuenta se haya cerrado, conservaremos información despersonalizada.</p>
        <p className={style.into}>La información que hayas compartido con otras personas (por ejemplo, a través de mensaje instantáneo o chat, actualizaciones o publicaciones de grupo) seguirá viéndose después de cerrar la cuenta o eliminar la información de tu propio perfil o buzón, y no controlamos los datos que otros Miembros hayan copiado de nuestros Productos y Servicios. Tu perfil podría seguir mostrándose en los Productos y Servicios de terceros (por ejemplo, los resultados de motores de búsqueda) hasta que actualicen su memoria caché.</p>
        <h4 className={style.point}>5. Otra información importante</h4>
        <h5 className={style.subPonit}>5.1. Seguridad</h5>
        <p className={style.into}>Controlamos e intentamos prevenir los fallos en materia de seguridad. Utiliza las funcionalidades de seguridad disponibles en nuestros Productos y Servicios.</p>
        <p className={style.into}>Aplicamos medidas de seguridad diseñadas para proteger tus datos, como HTTPS. Controlamos periódicamente nuestros sistemas para detectar posibles vulnerabilidades y ataques. No obstante, no podemos garantizar la seguridad de la información que nos envíes. No hay ninguna garantía de que no se pueda acceder, divulgar, alterar o destruir los datos si se produce una filtración en alguna de nuestras medidas de seguridad físicas, técnicas o de gestión. </p>
        <h5 className={style.subPonit}>5.2. Transferencias transfronterizas de datos</h5>
        <p className={style.into}>Almacenamos y utilizamos tus datos fuera de tu país.</p>
        <p className={style.into}>Procesamos datos tanto dentro como fuera de Estados Unidos y confiamos en mecanismos jurídicos para transferir legalmente los datos entre países. Más información. Los países en los que procesamos datos pueden tener legislaciones diferentes a la de tu país, con la posibilidad de que no ofrezcan una protección tan amplia como esta última.</p>
        <h5 className={style.subPonit}>5.3 Fundamentos legales para el tratamiento de datos</h5>
        <p className={style.into}>Tenemos fundamentos legales para recopilar, utilizar y compartir tus datos. Puedes elegir cómo utilizamos tus datos. Puedes retirar tu consentimiento en cualquier momento desde tu configuración.</p>
        <p className={style.into}>Solo recopilaremos y procesaremos tus datos personales hasta donde nos permitan los fundamentos legales. Los fundamentos legales incluyen el consentimiento (si corresponde), el contrato (si es necesario el tratamiento de datos para la realización de un contrato contigo para prestarte los Productos y Servicios de BigSportData que hayas solicitado, por ejemplo) e «intereses legítimos».  </p>
        <p className={style.into}>Tu consentimiento es necesario para el tratamiento de datos personales, y puedes retirarlo o revocarlo en cualquier momento. Del mismo modo, puedes interponer objeciones a nuestros intereses legítimos. Más información. Si tienes alguna pregunta acerca de los fundamentos legales en los que se basa la recopilación y el uso de tus datos personales, ponte en contacto con nuestro Delegado de protección de datos  </p>
        <h5 className={style.subPonit}>5.4. Marketing directo y señales de «no rastreo»</h5>
        <p className={style.into}>Por el momento, no compartimos datos personales con terceros para sus fines de marketing directo a no ser que contemos con tu permiso para ello. </p>
        <h5 className={style.subPonit}>5.5. Información de contacto</h5>
        <p className={style.into}>Puedes contactarnos o utilizar otras opciones para resolver cualquier queja.</p>
        <p className={style.into}>Si tienes alguna pregunta o queja sobre esta Política, contacta con BigSportData en línea primero. También puedes contactarnos por correo electrónico. Si tras contactarnos no se resuelve tu queja, existen otras opciones disponibles, como vía telefónica disponible en neutra página web..</p>
        <p className={style.btn} onClick={handleInicio}>{"< Regresar"}</p>
    </div>
  )
}

export default Politica