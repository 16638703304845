import React from 'react';
import styles from './NavSecond.module.css';
import balonA from "../../assets/balonBtn.png"
import balonB from "../../assets/BalonBtnB.png"
import userA from "../../assets/UserBtnA.png"
import userB from "../../assets/UserBtn.png"

const NavSecond = ({ bool, boolTwo, clickBool, clickBoolTwo, user, clickBoolThree, threeBool, isVisitor }) => {

  return (
    <div className={styles.navSecond}>
      {isVisitor ?
        <div className={styles.botones}>
          <div className={styles.btn} onClick={clickBool}>
            <img className={styles.icon} src={bool ? balonB : balonA} alt="" />
            <p className={bool ? styles.txtBtn : styles.txtBtnSelect}>PERFIL</p>
          </div>
          <div className={styles.btn} onClick={clickBool}>
            <img className={styles.icon} src={!bool ? userB : userA} alt="" />
            <p className={!bool ? styles.txtBtn : styles.txtBtnSelect}>SOCIAL</p>
          </div>
        </div>
      :
        <div className={styles.botones}>
          <div className={styles.btn} onClick={clickBool}>
            <img className={styles.icon} src={!bool ? balonB : balonA} alt="" />
            <p className={!bool ? styles.txtBtn : styles.txtBtnSelect}>MÉTRICAS</p>
          </div>
          {user?.rol !== "Jugador" && <div className={styles.btn} onClick={clickBoolThree}>
            <img className={styles.icon} src={!threeBool ? userB : userA} alt="" />
            <p className={!threeBool ? styles.txtBtn : styles.txtBtnSelect}>RECLUTAMIENTO</p>
          </div>}
          <div className={styles.btn} onClick={clickBoolTwo}>
            <img className={styles.icon} src={!boolTwo ? userB : userA} alt="" />
            <p className={!boolTwo ? styles.txtBtn : styles.txtBtnSelect}>SOCIAL</p>
          </div>
        </div>}
    </div>
  );
};

export default NavSecond;
