import React, { useState, useEffect } from 'react';
import styles from "./Feed.module.css";
import Publicaciones from '../Publiaciones/Publicaciones';
import { useSelector } from "react-redux";

const FeedVisitor = ({ user, publicaciones, inVisitor, noticias }) => {
  const miUser = useSelector(state => state.dataUser)
  const [idUpdate, setIdUpdate] = useState("")
  const [seguidosBool, setSeguidosBool] = useState(true);
  const [mifeed, setMifeed] = useState(false);


  const clickBtnMenu = (apartado) => {
    switch (apartado) {
      case "miPerfil":
        setMifeed(true);
        setSeguidosBool(false);

        break;
      case "seguidos":
        setMifeed(false);
        setSeguidosBool(true);
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    if (user.id === miUser.id) {
      setIdUpdate(miUser.id);
    } else {
      setIdUpdate(user.id);
    }
  }, [user.id, miUser.id]);

  const publicacionesOrdenadas = [...publicaciones].sort((b, a) => new Date(a.updatedAt) - new Date(b.updatedAt));

  return (
    <div className={styles.conteiner}>
      <div className={styles.contBotonera}>
      <div className={seguidosBool ? styles.botonSelectedOtro : styles.botonOtro} onClick={() => clickBtnMenu("seguidos")}>
          <p className={styles.txtBoton}>Publicaciones</p>
        </div>
        <div className={mifeed ? styles.botonSelectedOtro : styles.botonOtro} onClick={() => clickBtnMenu("miPerfil")}>
          {user.rol === "Jugador" && <p className={styles.txtBoton}>Habilidades</p>}
        </div>
      </div>
      {seguidosBool && <div>
        {publicacionesOrdenadas.map((publicacion) => (
          <Publicaciones
            key={publicacion.id}
            id={publicacion.id}
            tipo={publicacion.tipo}
            userId={user.id}
            foto={user.foto}
            nombre={user.nombre}
            mensaje={publicacion.mensaje}
            img={publicacion.img}
            video={publicacion.video}
            likes={publicacion.Likes}
            comentarios={publicacion.Comentarios}
            compartidos={publicacion.compartidos}
            fecha={publicacion.updatedAt}
            origenID={publicacion.origenID}
            origenName={publicacion.origenName}
            origenUserID={publicacion.origenUserID}
            origenMensaje={publicacion.origenMensaje}
            origenUserFoto={publicacion.origenUserFoto}
            oldcreatedAt={publicacion.oldcreatedAt}
            idUpdate={idUpdate}
            inVisitor={inVisitor}
            userRol={user.rol}
            posicion={user.posicion}
            dia={user.dia}
            mes={user.mes}
            anio={user.anio}
            aplausos={publicacion.Aplausos}
          />
        ))}
      </div>}
    </div>
  );
};

export default FeedVisitor;