import styles from "./User.module.css"
import posicion from "../../assets/Campo.png"
import { Radar } from 'react-chartjs-2';
import { formatoLegible } from "../funciones.js";
import ModalMetricaComparativo from "../ModalMetrica/ModalMetricaComparativo.jsx";
import ModalPosicionComparativo from "../ModalMetrica/ModalPosicionComparativo.jsx";
import { useState } from "react";
import radarSensura from "../../assets/radarSensura.png"



const UserVisitor = ({user}) => {

    const userLocal = JSON.parse(localStorage.getItem("dataUser"))

    const [radarBool, setRadarBool] = useState(false)
    const [posicionBool, setPosicionBool] = useState(false)

    const posicionesLegibles = user.posicionesSeleccionadas.map(formatoLegible);

    const handleRadar = () => {
        setRadarBool(!radarBool)
    }

    const handlePosicion = () => {
        setPosicionBool(!posicionBool)
    }

    return (
        <div className={styles.conteiner}>
           <div className={styles.posicion}>
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
                    {userLocal.rol === "Jugador" && <p className={styles.btnTxt} onClick={handlePosicion}>COMPARAR</p>}
                </div>
                <img className={styles.campo} src={posicion} alt="" />
                <h3 className={styles.title}>POSICIÓN FAVORITA</h3>
                <div className={styles.contPosicion}>
                    <div className={styles.posi}>
                        <p className={styles.txt}>{formatoLegible(user.posicion)}</p>
                    </div>
                </div>
                <h3 className={styles.title}>OTRAS POSICIONES</h3>
                <div className={styles.contPosicion}>
                    <div className={styles.posi}>
                        <p className={styles.txt}>{posicionesLegibles[0]}</p>
                    </div>
                    <div className={styles.posi}>
                        <p className={styles.txt}>{posicionesLegibles[1]}</p>
                    </div>
                </div>
            </div>
            <div className={styles.habilidades}>
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>HABILIDADES DESTACADAS</h3>
                </div>
                <div className={styles.contHabilidades}>
                    {user.habilidadesSeleccionadas.map((habilidad, index) => (
                        <div key={index} className={styles.habi}>
                            <p className={styles.txt}>{habilidad}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.metricas}>
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
                   {userLocal.rol === "Jugador" && <p className={styles.btnTxt} onClick={handleRadar}>COMPARAR</p>}
                </div>
                <Radar
                    className={styles.radar}
                    data={{
                        labels: ["PASES CORTOS", "PASES LARGOS", "CABECEO", "REGATE", "DEFENSA", "TIROS"],
                        datasets: [
                            {
                                label: 'HABILIDADES',
                                data: [user.paseCorto, user.paseLargo, user.cabeceo, user.regate, user.defensa, user.tiros],
                                fill: true,
                                backgroundColor: 'rgba(228, 253, 63, 0.5)',
                                borderColor: 'rgb(228, 253, 63)',

                            }
                        ]

                    }}
                    options={{
                        maintainAspectRatio: true,
                        responsive: true,
                        width: 280,
                        height: 300,
                        
                        scales: {
                            r: {
                                min: 0,
                                max: 5,
                                ticks: {
                                    display: false
                                },
                                pointLabels: {
                                    color: 'rgb(255, 255, 255)',
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: 'white'
                                }
                            }
                        }
                    }}
                />
            </div>
            {radarBool && <ModalMetricaComparativo user={user} handleRadar={handleRadar}/>}
            {posicionBool && <ModalPosicionComparativo user={user} handlePosicion={handlePosicion}/>}
        </div>
    )
}

export default UserVisitor