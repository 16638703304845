import React, { useEffect, useState } from 'react'
import styles from "./Listas.module.css"
import ModalLista from '../ModalLista/ModalLista'
import { useDispatch } from 'react-redux'
import { crearLista, getListas } from '../../actions'
import { formatoLegible, calcularEdad } from '../funciones'
import ojo from "../../assets/ojo.png"

const Listas = ({ allJugadores, listas, dispatcherAmigos, inVisitor }) => {

    const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))

    const [addLista, setAddLista] = useState(false)
    const [listaName, setListaName] = useState("")
    const [listaDesc, setListaDesc] = useState("")
    const [verListIndex, setVerListIndex] = useState(null)
    const [modalListaBool, setModalListaBool] = useState(false)
    const [listaEditar, setListaEditar] = useState({})

    const dispach = useDispatch()

    const nuevaLista = async () => {
        const data = {
            nombre: listaName,
            descripcion: listaDesc,
            creadorId: dataUserLocal.id
        }
        await dispach(crearLista(data))
        await dispach(getListas(dataUserLocal.id))
        setListaName("");
        setListaDesc("");
        setAddLista(false);
    }

    useEffect(() => {
        dispach(getListas(dataUserLocal.id))
    }, [dispach, dataUserLocal.id])

    const editarLista = (index) => {
        setListaEditar(listas[index])
        setModalListaBool(true)
    }

    return (
        <div className={styles.listas}>
            <div className={styles.contTitle}>
                <h2 className={styles.title}>MIS LISTAS</h2>
                <p className={styles.btnTxt} onClick={() => setAddLista(!addLista)}>Nuvea lista</p>
            </div>
            {addLista && <div className={styles.addLista}>
                <p className={styles.txt}>Nombre de la lista</p>
                <input className={styles.inp} type="text" name="lista" id="lista" value={listaName} onChange={(e) => setListaName(e.target.value)} />
                <p className={styles.txt}>Descripcion de la lista</p>
                <input className={styles.inp} type="text" name="descripcion" id="descripcion" value={listaDesc} onChange={(e) => setListaDesc(e.target.value)} />
                <div className={styles.botonera}>
                    <button className={styles.btn} onClick={() => setAddLista(!addLista)}>Cancelar</button>
                    <button className={styles.btnA} onClick={nuevaLista}>Guardar</button>
                </div>
            </div>}
            <div className={styles.contListas}>
                {listas.map((lista, index) => (
                    <div key={index} className={styles.contLista}>
                        <div className={styles.listVista}>
                            <div className={styles.listData}>
                                <h3 className={styles.listName}>{lista.nombre.toUpperCase()}</h3>
                                <p className={styles.listDesc}>{lista.descripcion}</p>
                            </div>
                            <div className={styles.contBtnTxt}>
                                {verListIndex === index ? <p className={styles.btnTxt} onClick={() => setVerListIndex(null)}>Cerrar</p> : <p className={styles.btnTxt} onClick={() => setVerListIndex(index)}>Ver</p>}
                                <p className={styles.btnTxt} onClick={() => editarLista(index)}>Editar</p>
                            </div>
                        </div>
                        {verListIndex === index && <div className={styles.verLista}>
                            {lista.Usuarios.length > 0 ? <div className={styles.contList}>
                                {lista.Usuarios.map((jugador, index) => (
                                    <div key={index} className={styles.list}>
                                        <img className={styles.img} src={jugador.foto} alt="" />
                                        <div className={styles.contDataUser}>
                                            <p className={styles.name}>{jugador.nombre}</p>
                                            <p className={styles.posicion}>{formatoLegible(jugador.posicion)} - {calcularEdad(jugador.dia, jugador.mes, jugador.anio)} años</p>
                                        </div>
                                        <div className={styles.btnAdd} onClick={() => inVisitor(jugador.id)}>
                                            <img className={styles.iconB} src={ojo} alt="" />
                                        </div>
                                        <div className={styles.contBSD}>
                                            <p className={styles.bsdTxt}>BSD</p>
                                            <p className={styles.bsdValor}>{jugador.score}</p>
                                        </div>
                                    </div>
                                ))}
                            </div> : <div className={styles.contList}>
                                <h3 className={styles.cuestion}>AGREGA JUGADORES A TU LISTA</h3>
                                <button className={styles.btnC} onClick={() => editarLista(index)}>Agregar</button>
                            </div>}
                        </div>}
                    </div>
                ))}
            </div>
            {modalListaBool && <ModalLista setModalListaBool={setModalListaBool} modalListaBool={modalListaBool} allJugadores={allJugadores} listaEditar={listaEditar} inVisitor={inVisitor} />}
        </div>
    )
}

export default Listas