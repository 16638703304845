import React, { useEffect, useState } from 'react';
import styles from './ModalBuscar.module.css';
import 'animate.css';
import btnX from '../../assets/btnX.png';
import ojo from "../../assets/ojo.png"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import iconFiltrar from "../../assets/iconFilter.png"
import { posicionOptions, habilidadesOptions, filtrarUsuarios, buscarPorNombre, meSigue, calcularEdad, formatoLegible } from '../funciones';

const ModalBuscar = ({ allJugadores, setModalBuscarBool, modalBuscarBool, addJugador, listatReckup, deleteJugador, inVisitor }) => {

    const [posicion, setPosicion] = useState(JSON.parse(localStorage.getItem("posicion")) || []);
    const [habilidad, setHabilidad] = useState(JSON.parse(localStorage.getItem("habilidad")) || []);
    const [edadRange, setEdadRange] = useState([JSON.parse(localStorage.getItem("edadMin")) || 0, JSON.parse(localStorage.getItem("edadMax")) || 100]);
    const [alturaRange, setAlturaRange] = useState([JSON.parse(localStorage.getItem("alturaMin")) || 0, JSON.parse(localStorage.getItem("alturaMax")) || 250]);
    // const [bsdRange, setBsdRange] = useState(JSON.parse(localStorage.getItem("bsdMin")) || 0, JSON.parse(localStorage.getItem("bsdMax")) || 100)
    const [pie, setPie] = useState(JSON.parse(localStorage.getItem("pie")) || "")

    const [nombre, setNombre] = useState("")

    const [filtroBool, setFiltroBool] = useState(false)

    const [usuariosFiltrados, setUsuariosFiltrados] = useState([])
    const [usuriosPorNombre, setUsuariosPorNombre] = useState([])

    const verificarLocalStorage = () => {
        if (localStorage.getItem("posicion") === null) {
            localStorage.setItem("posicion", JSON.stringify([]));
        }
        if (localStorage.getItem("habilidad") === null) {
            localStorage.setItem("habilidad", JSON.stringify([]));
        }
        if (localStorage.getItem("edadMin") === null) {
            localStorage.setItem("edadMin", JSON.stringify(0));
        }
        if (localStorage.getItem("edadMax") === null) {
            localStorage.setItem("edadMax", JSON.stringify(100));
        }
        if (localStorage.getItem("alturaMin") === null) {
            localStorage.setItem("alturaMin", JSON.stringify(0));
        }
        if (localStorage.getItem("alturaMax") === null) {
            localStorage.setItem("alturaMax", JSON.stringify(220));
        }
        if (localStorage.getItem("pie") === null) {
            localStorage.setItem("pie", JSON.stringify(""));
        }
        // if(localStorage.getItem("bsdMin") === null) {
        //   localStorage.setItem("bsdMin", JSON.stringify(0));
        // }
        // if(localStorage.getItem("bsdMax") === null) {
        //   localStorage.setItem("bsdMax", JSON.stringify(100))
        // }
    }

    const handleEdadChange = (value) => {
        setEdadRange(value);
        localStorage.setItem("edadMin", JSON.stringify(value[0]));
        localStorage.setItem("edadMax", JSON.stringify(value[1]));
    };

    const handleAlturaChange = (value) => {
        setAlturaRange(value);
        localStorage.setItem("alturaMin", JSON.stringify(value[0]));
        localStorage.setItem("alturaMax", JSON.stringify(value[1]));
    };

    const handlePosicionChange = (e) => {
        const selectedPosicion = e.target.value;
        if (!posicion.includes(selectedPosicion)) {
            setPosicion([...posicion, selectedPosicion]);
            localStorage.setItem("posicion", JSON.stringify([...posicion, selectedPosicion]));
        }
    };

    const handleHabilidadChange = (e) => {
        const selecteHabilidad = e.target.value;
        if (!habilidad.includes(selecteHabilidad)) {
            setHabilidad([...habilidad, selecteHabilidad]);
            localStorage.setItem("habilidad", JSON.stringify([...habilidad, selecteHabilidad]));
        }
    }

    const selectPie = (pie) => {
        setPie(pie)
        localStorage.setItem("pie", JSON.stringify(pie))
    }

    const deletePosicion = (posi) => {
        setPosicion(posicion.filter((p) => p !== posi));
        localStorage.setItem("posicion", JSON.stringify(posicion.filter((p) => p !== posi)));
    }

    const deleteHabilidad = (hab) => {
        setHabilidad(habilidad.filter((h) => h !== hab));
        localStorage.setItem("habilidad", JSON.stringify(habilidad.filter((h) => h !== hab)));
    }


    const railStyle = {
        backgroundColor: 'rgba(38, 38, 38, 1)',
    };

    const trackStyle = {
        backgroundColor: 'rgba(228, 253, 63, 1)',
    };

    const handleStyle = {
        borderColor: 'rgba(228, 253, 63, 1)',
        backgroundColor: 'rgba(228, 253, 63, 1)',
    };

    const limpiarFiltros = () => {
        setPosicion([]);
        setHabilidad([]);
        setEdadRange([0, 100]);
        setAlturaRange([0, 250]);
        setPie("")
        // setBsdRange([0, 100])
        localStorage.setItem("posicion", JSON.stringify([]));
        localStorage.setItem("habilidad", JSON.stringify([]));
        localStorage.setItem("edadMin", JSON.stringify(0));
        localStorage.setItem("edadMax", JSON.stringify(100));
        localStorage.setItem("alturaMin", JSON.stringify(0));
        localStorage.setItem("alturaMax", JSON.stringify(250));
        localStorage.setItem("pie", JSON.stringify(""))
        // localStorage.setItem("bsdMin", JSON.stringify(0))
        // localStorage.setItem("bsdMax", JSON.stringify(100))
    }

    useEffect(() => {
        verificarLocalStorage()
    }, []);

    useEffect(() => {
        setUsuariosFiltrados(filtrarUsuarios(allJugadores, { posiciones: posicion, habilidades: habilidad, alturaMin: alturaRange[0], alturaMax: alturaRange[1], edadMin: edadRange[0], edadMax: edadRange[1], pie: pie }))
    }, [posicion, habilidad, edadRange, alturaRange, pie, allJugadores])

    useEffect(() => {
        setUsuariosPorNombre(buscarPorNombre(usuariosFiltrados, nombre))

    }, [nombre, usuariosFiltrados])


    return (
        <div className={styles.conteiner}>
            <div className={styles.contMenu }>
                <h1 className={styles.title}>BUSCAR JUGADORES</h1>
                <div className={ filtroBool ? styles.contFiltros : styles.constDesactive}>
                    <div className={styles.contTitle}>
                        <h3 className={styles.subTitle}>FILTROS DE BÚSQUEDA</h3>
                        <p className={styles.txtA} onClick={limpiarFiltros}>LIMPIAR</p>
                    </div>
                    <h4 className={styles.parametro}>Posición</h4>
                    <select className={styles.inputCampo} onChange={handlePosicionChange} value={posicion[0]}>
                        {posicionOptions}
                    </select>
                    <div className={styles.contPosicones}>
                        {posicion.map(posi => (
                            <div className={styles.contPossicionSelected} key={posi}>
                                <p className={styles.posicion}>{formatoLegible(posi)}</p>
                                <img className={styles.btnX} src={btnX} alt="" onClick={() => deletePosicion(posi)} />
                            </div>
                        ))}
                    </div>
                    <h4 className={styles.parametro}>Habilidades destacadas</h4>
                    <select className={styles.inputCampo} onChange={handleHabilidadChange} value={habilidad[0]}>
                        {habilidadesOptions}
                    </select>
                    <div className={styles.contPosicones}>
                        {habilidad.map(habi => (
                            <div className={styles.contPossicionSelected} key={habi}>
                                <p className={styles.posicion}>{habi}</p>
                                <img className={styles.btnX} src={btnX} alt="" onClick={() => deleteHabilidad(habi)} />
                            </div>
                        ))}
                    </div>
                    <h4 className={styles.parametro}>Edad</h4>
                    <div className={styles.slider}>
                        <Slider
                            min={0}
                            max={100}
                            range
                            defaultValue={[0, 100]}
                            value={edadRange}
                            onChange={handleEdadChange}
                            railStyle={railStyle}
                            trackStyle={trackStyle}
                            handleStyle={handleStyle}
                        />
                        <p className={styles.valor}>{edadRange[0]} a {edadRange[1]} años</p>
                    </div>
                    <h4 className={styles.parametro}>Altura</h4>
                    <div className={styles.slider}>
                        <Slider
                            min={0}
                            max={220}
                            range
                            defaultValue={[0, 250]}
                            value={alturaRange}
                            onChange={handleAlturaChange}
                            railStyle={railStyle}
                            trackStyle={trackStyle}
                            handleStyle={handleStyle}
                        />
                        <p className={styles.valor}>{alturaRange[0]} a {alturaRange[1]} cm</p>
                    </div>
                    <h4 className={styles.parametro}>BSD Score</h4>
                    <div className={styles.slider}>
                        <Slider
                            min={0}
                            max={100}
                            range
                            defaultValue={[0, 100]}
                            // value={bsdRange}
                            // onChange={handleBsdChange}
                            railStyle={railStyle}
                            trackStyle={trackStyle}
                            handleStyle={handleStyle}
                        />
                        <p className={styles.valor}>{0} a {100}</p>
                    </div>
                    <h4 className={styles.parametro}>Pie</h4>
                    <div className={styles.contBotones}>
                        <button className={pie === "Derecho" ? styles.botonSelected : styles.boton} onClick={() => selectPie("Derecho")}>Derecho</button>
                        <button className={pie === "Izquierdo" ? styles.botonSelected : styles.boton} onClick={() => selectPie("Izquierdo")}>Izquierdo</button>
                        <button className={pie === "Ambos" ? styles.botonSelected : styles.boton} onClick={() => selectPie("Ambos")}>Ambos</button>
                    </div>
                    <button className={styles.btnAgregar} onClick={() => setFiltroBool(!filtroBool)}>Filtrar</button>
                </div>
            </div>
            <div className={filtroBool ? styles.constDesactive : styles.listaJugadores}>
                <div className={styles.contBuscador}>
                    <h1 className={styles.cerrar} onClick={() => setModalBuscarBool(!modalBuscarBool)}>X</h1>
                    <input className={styles.input} type="text" placeholder='Buscar por nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
                    <div className={styles.contFiltrar} onClick={() => setFiltroBool(true)}>
                        <img className={styles.iconFiltrar} src={iconFiltrar} alt="" />
                        <p className={styles.txtFiltrar}>Filtrar</p>
                    </div>
                    <div className={styles.recomendados}>
                        {usuriosPorNombre.map(((jugador, index) => (
                            <div className={index % 2 === 1 ? styles.contJugador : styles.contJugadorTwo} key={index}>
                                <h3 className={styles.num}>{index + 1}</h3>
                                <img className={styles.foto} src={jugador.foto} alt="" />
                                <div className={styles.info}>
                                    <h3 className={styles.name}>{jugador.nombre}</h3>
                                    <p className={styles.posicion}>{formatoLegible(jugador.posicion)} - {calcularEdad(jugador.dia, jugador.mes, jugador.anio)} años</p>
                                </div>
                                <div className={styles.bsdJugador}>
                                    <p className={styles.bsdTxtJugador}>BSD</p>
                                    <p className={styles.bsdByJugador}>{jugador.score}</p>
                                </div>
                                {meSigue(listatReckup.Usuarios, jugador.id) ? <div className={styles.btnAdd} onClick={() => deleteJugador(jugador.id)}>-</div> : <div className={styles.btnAdd} onClick={() => addJugador(jugador.id)}>+</div>}
                                <div className={styles.btnAdd} onClick={() => inVisitor(jugador.id)}>
                                    <img className={styles.icon} src={ojo} alt="" />
                                </div>
                            </div>
                        )))}
                    </div>
                </div>
                <button className={styles.btnAgregar} onClick={() => setModalBuscarBool(!modalBuscarBool)}>Aceptar</button>
            </div>
        </div>
    );
};

export default ModalBuscar;

