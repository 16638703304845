import React, { useState, useRef } from 'react'
import styles from "./Noticias.module.css"
import logo from "../../../assets/Logo.svg"
import subirImagen from "../../../assets/SubirImagen.png"
import aplauso from "../../../assets/premium.png"
import mesage from "../../../assets/message.png"
import share from "../../../assets/share.png"
import medallas from "../../../assets/medallaTrue.png"
import axios from 'axios';
import { URL } from '../../../link'
import { useDispatch } from 'react-redux'
import { getNoticias } from '../../../actions'

const Noticias = ({noticias}) => {
    const user = JSON.parse(localStorage.getItem("dataUser"))
    const [name, setName] = useState("Big Sport Data")
    const [titulo, setTitulo] = useState("Patrocinado")
    const [contenido, setContenido] = useState("Aqui el contenido")
    const [logoNoticia, setLogoNoticia] = useState(user.foto)
    const [imageUpLoad, setImageUpLoad] = useState("")
    const [upLoadVideo, setUpLoadVideo] = useState("")
    const [videoPreview, setVideoPreview] = useState("");
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const fileInputImgRef = useRef(null);
    const fileInputVideoRef = useRef(null);
    const [videoLoadingInProgress, setVideoLoadingInProgress] = useState(false);

    const dispatch = useDispatch()

    const preset_key = "bqgn8ppn";
    const cloud_name = "dvwtgluwc";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;
    const cloudinaryUrlVideo = `https://api.cloudinary.com/v1_1/${cloud_name}/video/upload`;

    const uploadImage = async (file) => {
        setLoadingImage(true);
        setUpLoadVideo("")
        setVideoPreview("")
        const data = new FormData();
        data.append('file', file);
        data.append('upload_preset', preset_key);
        try {
            const response = await axios.post(cloudinaryUrl, data);
            setImageUpLoad(response.data.secure_url);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingImage(false);
        }
    };


    const uploadVideo = async (file) => {
        if (file.type.startsWith('video/')) {
            setImageUpLoad("")
            setVideoLoadingInProgress(true);
            setLoadingVideo(true);

            try {
                const video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = async function () {
                    window.URL.revokeObjectURL(video.src);
                    const duration = video.duration;

                    if (duration <= 35) {
                        const data = new FormData();
                        data.append('file', file);
                        data.append('upload_preset', preset_key);

                        try {
                            const response = await axios.post(cloudinaryUrlVideo, data);
                            setUpLoadVideo(response.data.public_id);
                            setVideoPreview(window.URL.createObjectURL(file));
                            setVideoLoadingInProgress(false);
                        } catch (error) {
                            console.log(error);
                            setVideoLoadingInProgress(false);
                            setLoadingVideo(false);
                        }
                    } else {
                        console.log("El video supera los 35 segundos");
                        setVideoLoadingInProgress(false);
                        setLoadingVideo(false);
                    }
                }

                video.src = window.URL.createObjectURL(file);
            } catch (error) {
                console.log(error);
                setVideoLoadingInProgress(false);
                setLoadingVideo(false);
            } finally {
                setLoadingVideo(false);
            }
        } else {
            console.log("El archivo no es un video");
        }
    };

    const upLoadLogo = async (e) => {
        const file = e.target.files[0];
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", preset_key);
        try {
            const response = await axios.post(cloudinaryUrl, data);
            setLogoNoticia(response.data.secure_url)
        } catch (error) {
            console.log(error);
        }
    };

    const enviarNoticia = async() => {
        const data = {
            titulo: titulo,
            mensaje: contenido,
            img: imageUpLoad,
            video: upLoadVideo,
            empresa: name,
            logo: logoNoticia,
            userID: user.id,
            tipo: "noticia"
        }
        try {
            const res = await axios.post(`${URL}/noticia/nueva`, data);
            dispatch(getNoticias())
            console.log(res.data);
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className={styles.noticias}>
            <h3 className={styles.title}>NOTICIAS</h3>
            <div className={styles.container}>
                <div className={styles.contNoticias}>
                    <h4 className={styles.subTitle}>Nueva noticia</h4>
                    <div className={styles.contFoto}>
                        <img className={styles.foto} src={subirImagen} alt="" onClick={() => document.getElementById("fileLogo").click()}/>
                        <input
                                type="file"
                                id="fileLogo"
                                className={styles.fileInput}
                                onChange={upLoadLogo}
                                accept="image/*"
                                style={{ display: "none" }}
                            />
                        <div className={styles.cont}>
                            <h4 className={styles.seccionName}>Nombre de la empresa</h4>
                            <input type="text" name='name' placeholder='Big Sport Data' value={name} className={styles.inp} onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.contData}>
                        <div className={styles.cont}>
                            <h4 className={styles.seccionName}>Titulo</h4>
                            <input type="text" name='titulo' placeholder="Patrocinado" value={titulo} className={styles.inp} onChange={(e) => setTitulo(e.target.value)} />
                        </div>
                        <div className={styles.cont}>
                            <h4 className={styles.seccionName}>Contenido</h4>
                            <input type="text" className={styles.inp} placeholder='Aqui el contenido' value={contenido} onChange={(e) => setContenido(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.contData}>
                        <div className={styles.cont}>
                            <h4 className={styles.seccionName}>Subir</h4>
                            <div className={styles.botonera}>
                                <button className={styles.btn}
                                    disabled={loadingImage}
                                    onClick={() => document.getElementById("fileInput").click()}
                                >Imagen</button>
                                <input
                                    type="file"
                                    name="foto"
                                    id="fileInput"
                                    onChange={(e) => uploadImage(e.target.files[0])}
                                    ref={fileInputImgRef}
                                    className={styles.inputext}
                                    accept="image/*"
                                    style={{ display: "none" }}
                                />
                                <button className={styles.btn}
                                 disabled={loadingVideo || videoLoadingInProgress}
                                 onClick={() => document.getElementById("fileVideo").click()}
                                 >Video</button>
                                <input
                                    type="file"
                                    name="video"
                                    id="fileVideo"
                                    onChange={(e) => uploadVideo(e.target.files[0])}
                                    ref={fileInputVideoRef}
                                    className={styles.inputext}
                                    accept="video/*"
                                    style={{ display: "none" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.cont}>
                        <button className={styles.btnA} onClick={enviarNoticia}>Enviar noticia</button>
                    </div>
                </div>
                <div className={styles.contVista}>
                    <h4 className={styles.subTitle}>Vista previa</h4>
                    <div className={styles.preview}>
                        <div className={styles.contTitle}>
                            <img className={styles.logo} src={logoNoticia || logo} alt="" />
                            <div className={styles.data}>
                                <h3 className={styles.name}>{name}</h3>
                                <p className={styles.link}>{titulo}</p>
                            </div>
                            <p className={styles.time}>Hace 2 horas</p>
                        </div>
                        <div className={styles.contenido}>
                            <div className={styles.contTxt}>
                                <p className={styles.txt}>{contenido}</p>
                            </div>
                            {imageUpLoad !== "" && <img className={styles.imgNoticia} src={imageUpLoad} alt="" />}
                            {videoPreview !== "" && <video className={styles.video} src={videoPreview} controls></video>}
                        </div>
                        <div className={styles.reacciones}>
                            <div className={styles.reaccion}>
                                <img className={styles.icon} src={aplauso} alt="" />
                                <p className={styles.value}>1</p>
                            </div>
                            <div className={styles.reaccion}>
                                <img className={styles.icon} src={mesage} alt="" />
                                <p className={styles.value}>4</p>
                            </div>
                            <div className={styles.reaccion}>
                                <img className={styles.icon} src={share} alt="" />
                                <p className={styles.value}>10</p>
                            </div>
                            <div className={styles.reaccionB}>
                                <img className={styles.iconB} src={medallas} alt="" />
                                <p className={styles.value}>5</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Noticias