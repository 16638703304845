import { useEffect, useState } from 'react'
import styles from "./Visitor.module.css"
import { getVisitorPublicaciones, getVisitorUser } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'

import StadisticsVisitor from '../Stadistics/StadisticsVisitor.jsx'
import News from '../News/News'
import { useParams } from 'react-router'
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx"
import UserVisitor from '../User/UserVisitor.jsx'
import FeedVisitor from '../Feed/FeedVisitor.jsx'
import Otro from '../Otro/Otro.jsx'
import NavSecond from '../NavSecond/NavSecond.jsx'

import ModalUser from '../ModalUser/ModalUser.jsx'
import ModalMetricaVisitor from '../ModalMetrica/ModalMetricaVisitor.jsx'

const Visitor = ({ inVisitor, notificacionesBool, userBool, logOut, inEdit, noticias }) => {
  const params = useParams()
  const id = params.id
  const dispatch = useDispatch()
  const detailUser = useSelector(state => state.dataVisitorUser)
  const publicaciones = useSelector(state => state.visitorPublicaciones)
  const userPubliaciones = []
  const [metricaBool, setMetricaBool] = useState(false)
  const [metricaValor, setMetricaValor] = useState("")

  const [isVisitorBool, setIsVisitorBool] = useState(true)

  const [bool, setBool] = useState(false)

  const metricaClick = (metricaV) => {
    setMetricaBool(true)
    setMetricaValor(metricaV)
  }

  useEffect(() => {
    dispatch(getVisitorUser(id))
    dispatch(getVisitorPublicaciones(id))
  }, [dispatch, id])

  const clickBool = () => {
    setBool(!bool)
  }


  return (
    <div className={styles.container}>
      <div className={bool ? styles.partDesactive : styles.partOne}>
        {detailUser.rol === "Jugador" ? 
          <StadisticsVisitor detailUser={detailUser} metricaClick={metricaClick}/> 
        : 
          <Otro user={detailUser} isVisitorBool={isVisitorBool}/>}
      </div>

      <div className={bool ? styles.partDesactive : styles.partTwo}>
       {detailUser.rol === "Jugador" &&
          <UserVisitor user={detailUser}/>}
      </div>

      <div className={bool ? styles.partThre : styles.partDesactive}>
        <FeedVisitor user={detailUser} publicaciones={publicaciones} userPubliaciones={userPubliaciones} inVisitor={inVisitor} noticias={noticias}/>
      </div>

      {notificacionesBool === true && <ModalNotificaciones />}

      {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit}/>}

      {metricaBool === true && <ModalMetricaVisitor setMetricaBool={setMetricaBool} metricaV={metricaValor} user={detailUser}/>}

      <div className={styles.navSecond}><NavSecond  bool={bool} clickBool={clickBool} isVisitor={true}/></div>
    </div>
  )
}

export default Visitor