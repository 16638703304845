import { useEffect, useState } from 'react'
import styles from "./ModalMetricaComparativo.module.css"
import { Radar } from 'react-chartjs-2';
import { renderText } from 'chart.js/helpers';
import tu from "../../assets/tu.png"
import { calcularEdad } from '../funciones';


const ModalMetricaComparativo = ({ user, handleRadar }) => {

    const userLocal = JSON.parse(localStorage.getItem("dataUser"))

    const [bool, setBool] = useState(true)

    return (
        <div className={styles.conteiner}>
            <div className={styles.modalContent}>
                <h1 className={styles.btnCloset} onClick={handleRadar}>X</h1> {/* Modificado */}
                <div className={styles.contTitle}>
                    <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
                </div>
                <div className={styles.botonera}>
                    <div className={bool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setBool(true)}>
                        <p className={styles.txtMenu}>{user.nombre.toUpperCase()}</p>
                    </div>
                    <div className={!bool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setBool(false)}>
                        <p className={styles.txtMenu}>TÚ</p>
                    </div>
                </div>
                <div className={styles.metricas}>
                    <div className={bool ? styles.contRadarDes : styles.contRadar}>
                        <div className={styles.etiqueta}>
                            <img className={styles.icon} src={tu} alt="" />
                            <h4 className={styles.etiquetaTxt}>TÚ</h4>
                        </div>
                        <div className={styles.userCard}>
                            <img className={styles.foto} src={userLocal.foto} alt="" />
                            <div className={styles.info}>
                                <p className={styles.name}>{userLocal.nombre.toUpperCase()}</p>
                                <p className={styles.position}>{userLocal.posicion} - {calcularEdad(userLocal.dia, userLocal.mes, userLocal.anio)} años</p>
                            </div>
                        </div>
                        <Radar
                            className={styles.radar}
                            data={{
                                labels: ["PASES CORTOS", "PASES LARGOS", "CABECEO", "REGATE", "DEFENSA", "TIROS"],
                                datasets: [
                                    {
                                        label: 'HABILIDADES',
                                        data: [userLocal.paseCorto, userLocal.paseLargo, userLocal.cabeceo, userLocal.regate, userLocal.defensa, userLocal.tiros],
                                        fill: true,
                                        backgroundColor: 'rgba(228, 253, 63, 0.2)',
                                        borderColor: 'rgb(228, 253, 63)',

                                    }
                                ]

                            }}
                            options={{
                                maintainAspectRatio: true,
                                responsive: true,
                                width: 300,
                                height: 300,
                                plugins: {
                                    legend: {
                                        display: false, // Ocultar la etiqueta o título
                                    }
                                },
                                scales: {
                                    r: {
                                        min: 0,
                                        max: 5,
                                        ticks: {
                                            display: false
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className={!bool ? styles.contRadarDes : styles.contRadar}>
                        <div className={styles.etiqueta}>
                            <h4 className={styles.etiquetaTxt}>{user.nombre.toUpperCase()}</h4>
                        </div>
                        <div className={styles.userCard}>
                            <img className={styles.foto} src={user.foto} alt="" />
                            <div className={styles.info}>
                                <p className={styles.name}>{user.nombre.toUpperCase()}</p>
                                <p className={styles.position}>{user.posicion} - {calcularEdad(user.dia, user.mes, user.anio)} años</p>
                            </div>
                        </div>
                        <Radar
                            className={styles.radar}
                            data={{
                                labels: ["PASES CORTOS", "PASES LARGOS", "CABECEO", "REGATE", "DEFENSA", "TIROS"],
                                datasets: [
                                    {
                                        // label: 'HABILIDADES',
                                        data: [user.paseCorto, user.paseLargo, user.cabeceo, user.regate, user.defensa, user.tiros],
                                        fill: true,
                                        backgroundColor: 'rgba(228, 253, 63, 0.2)',
                                        borderColor: 'rgb(228, 253, 63)',

                                    }
                                ]

                            }}
                            options={{
                                maintainAspectRatio: true,
                                responsive: true,
                                width: 300,
                                height: 300,
                                plugins: {
                                    legend: {
                                        display: false, // Ocultar la etiqueta o título
                                    }
                                },
                                scales: {
                                    r: {
                                        min: 0,
                                        max: 5,
                                        ticks: {
                                            display: false
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <button className={styles.btnAgregar} onClick={handleRadar}>Cerrar</button>
            </div>
        </div>
    )
}

export default ModalMetricaComparativo;
