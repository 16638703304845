import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import { io } from 'socket.io-client';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { notificacionesNoLeidas } from './components/funciones.js';
import { getUser, getAllUsers, getVisitorPublicaciones, getVisitorUser, masunmensaje, mensajesLeidos, getAllJugadores, getSeguidores, getSeguidos, getMensajes, getNotificaciones, getAllPublicaciones, deletePublicacion, getMensajesNoLeidos, dispachSeguir, dispacherDejarSeguir, getUserPublicaciones, getListas, getNoticias } from "./actions/index.js";
import { URL } from './link.js';

import Home from './components/Home/Home';
import NavBar from './components/NavBar/NavBar';
import Admin from './components/Admin/Admin.jsx';
import Sesion from './components/Sesion/Sesion';
import Register from './components/Register/Register';
import Validar from './components/Register/Validar.jsx';
import Landing from './components/Landing/Landing';
import Perfil from './components/Perfil/Perfil.jsx';
import Formulario from './components/Formulario/Formulario.jsx';
import Visitor from './components/Visitor/Visitor.jsx';
import Mensajes from './components/Mensajes/Mensajes.jsx';
import ConfirmarCorreo from './components/Register/ConfirmarCorreo.jsx';
import Recuperacion from './components/Recuperacion/Recuperacion.jsx';
import Verifica from './components/Recuperacion/Verifica.jsx';
import Actiualiza from './components/Recuperacion/Actiualiza.jsx';
import Valida from './components/Recuperacion/Valida.jsx';
import Succes from './components/Recuperacion/Succes.jsx';
import Seleccion from './components/Onbording/Seleccion.jsx';
import Basica from './components/Onbording/Basica.jsx';
import Posicion from './components/Onbording/Posicion.jsx';
import Extra from './components/Onbording/Extra.jsx';
import Habilidades from './components/Onbording/Habilidades.jsx';
import Metricas from './components/Onbording/Metricas.jsx';
import Listo from './components/Onbording/Listo.jsx';
import Intereses from './components/Onbording/Intereses.jsx';
import BasicaO from './components/Onbording/BasicaO.jsx';
import Amigos from './components/Amigos/Amigos.jsx';
import LandingNosotros from './components/Landing/LandingNosotros.jsx';
import LandingContacto from './components/Landing/LandingContacto.jsx';
import Politica from './components/Landing/Politica/Politica.jsx';

function App() {
  // const socket = io(URL);
  let socket = io(URL);
  // Estados globales
  const allJugadores = useSelector(state => state.allJugadores)
  const seguidores = useSelector(state => state.seguidores)
  const seguidos = useSelector(state => state.seguidos)
  const usuarioRecomendados = useSelector(state => state.usuarioRecomendados)
  const mensajes = useSelector(state => state.mensajes)
  const notificaciones = useSelector(state => state.notificaciones)
  const listas = useSelector(state => state.listas)
  const noticias = useSelector(state => state.noticias)
  const [menuBool, setMenuBool] = useState(false)
  // Local Storage del usuario
  const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))
  // const [dataUserLocal, setDataUserLocal] = useState({})
  // Estados locales
  const [notificacionesBool, setNotificacionBool] = useState(false)
  const [userBool, setUserBool] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  // Funciones importadas
  const location = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch()

  //Funciones:

  // Cerrar sesion
  function logOut(){
    socket.emit('logOut', dataUserLocal.id)
    localStorage.setItem("dataUser", JSON.stringify({}))
    navigate("/")
  }
   //Inicio de sesion y conexion al soket del server
   function logSesion(id){
    dispatch(getUser(id))
    dispatch(getMensajesNoLeidos(id))
    dispatch(getAllPublicaciones(id))
    dispatch(getListas(id))
    socket.emit('singUp', id)
    navigate("/home")
  }
  //Funcion para obtener mensajes
  const obtenerMensajes = async() => {
    await dispatch(getMensajes(dataUserLocal.id))
};
//Funcion para enviar mensaje
const enviarMensajes = (data) => {
  socket.emit('mensajes', data);
};
 // Funcion para seguir
 const seguir = async(receptorID) => {
  const data = {
    solicitanteID: dataUserLocal.id, 
	  receptorID: receptorID
  }
  const response = await dispatch(dispachSeguir(data))
  if(response === true){
    dispatcherAmigos()
  }
  }
// Funcion para dejar de seguir
const dejarSeguir = async(receptorID) => {
  const data = {
    solicitanteID: dataUserLocal.id, 
    receptorID: receptorID
  }
  const response = await dispatch(dispacherDejarSeguir(data))
  if(response === true){
    dispatcherAmigos()
  }
}
//Funcion para actualizar las listas de comunidad
const dispatcherAmigos = () => {
  dispatch(getAllJugadores(dataUserLocal.id))
  dispatch(getSeguidores(dataUserLocal.id))
  dispatch(getSeguidos(dataUserLocal.id))
}
//Funcion para actualziar las publicaciones
const dispacherPublicaciones = () => {
  dispatch(getNotificaciones(dataUserLocal.id))
  dispatch(getAllPublicaciones(dataUserLocal.id))
  dispatch(getUserPublicaciones(dataUserLocal.id))
  dispatch(getNoticias())
}
//Funcion para actualizar los datos del usuario visitado
const dispacherVisitor = async(id) => {
  await dispatch(getVisitorPublicaciones(id))
  await dispatch(getVisitorUser(id))
  dispatch(getNotificaciones(dataUserLocal.id))
  dispatch(getNoticias())
}
// Funcion para cerrar las notificaciones
const desactivarNotificacion = () => {
  setNotificacionBool(false)
  setUserBool(false)
}

  //Botones: 

  //Boton de home
  function handleClick() {
    setNotificacionBool(false)
    setUserBool(false)
    dispacherPublicaciones()
    dispatch(getUser(dataUserLocal.id))
    navigate("/home")
  }
  //Boton home para el admin
  function handlerAdminClick(){
    navigate("/admin")
  }
  //Boton para visitar perfil de otro usuario
 async function inVisitor(id){
  if(id === dataUserLocal.id) {
    navigate("/home")
    setUserBool(false)
  } else {
    await dispacherVisitor(id)
    navigate(`/visitor/${id}`)
    setUserBool(false)
  }
  }
  //Boton para entrar a los ajustes del usuario
  function inEdit(){
    navigate("/configuracion")
    setUserBool(false)
  }
  //Boton para entrar a comunidad
  function inAmigos(){
    dispatch(getNotificaciones(dataUserLocal.id))
    dispatcherAmigos()
    setNotificacionBool(false)
    setUserBool(false)
    navigate("/comunidad")
  }
  //Boton para entrar a mensajes
  function clickMessage() {
    setNotificacionBool(false)
    setUserBool(false)
    navigate("/message")
  }
// Boton para abrir las notificaciones
const clickNotificacion = () => {
  setNotificacionBool(!notificacionesBool)
  setUserBool(false)
  dispatch(getNotificaciones(dataUserLocal.id))
}
// Boton para abrir el menu del usuario
const clickUserBool = () => {
  setUserBool(!userBool)
  setNotificacionBool(false)
}

// UseEffecs para la actualziacion de estados e informacion

// Verificamos que exista la informacion del usuario en el localstorage
useEffect(() => {
  const dataUserLocalE = localStorage.getItem("dataUser");
  if (dataUserLocalE === undefined || dataUserLocalE === null) {
    localStorage.setItem("dataUser", JSON.stringify({}));
  } else {
    // setDataUserLocal(JSON.parse(dataUserLocal));
  }
}, [dataUserLocal]);
// Mantenemos el canal abierto para enviar y recibir mensajes
  useEffect(() => {
    socket.on('mensajes', async (data) => {
      const dataUserLocalM = JSON.parse(localStorage.getItem("dataUser"))
        if(data.DestinatarioId === dataUserLocalM.id || data.RemitenteId === dataUserLocalM.id){
          dispatch(masunmensaje())
          dispatch(getNotificaciones(dataUserLocalM.id))
          await dispatch(getMensajes(dataUserLocalM.id))
          // console.log(mensajes);
        }
    }, [mensajes]);

    return () => {
        socket.disconnect();
    };
}, []);
// Actualizamos los estados al iniciar sesion
useEffect(() => {
  if(dataUserLocal && Object.keys(dataUserLocal).length > 0 && dataUserLocal.id !== undefined){
    const data = dataUserLocal?.id
    socket.emit('singUp', data)
    dispatch(getUser(dataUserLocal.id))
    dispatch(getAllUsers())
    dispatcherAmigos()
    dispacherPublicaciones()
    obtenerMensajes()
  }
}, []);

// Verificacmos si el jugador desea salir de pagina:
// useEffect(() => {
//   const handleBeforeUnload = (event) => {
//     const message = "¿Realmente desea salir de la página?";
//     event.returnValue = message;
//     socket.emit('logOut', dataUserLocal.id)
//     return message;
//   }
//   window.addEventListener('beforeunload', handleBeforeUnload)
//   return () => {
//     window.removeEventListener('beforeunload', handleBeforeUnload);
//   };
// }, []);

// Funciones para la version mobile
  useEffect(() => {
  // Obtener datos del localStorage
  const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))
  if (window.AndroidInterface) {
      window.AndroidInterface.receiveLocalStorage(JSON.stringify(dataUserLocal));
  }
}, []);

  return (
    <div id="app-container">
      {location.pathname !== '/sesion' && location.pathname !== '/register' 
      && location.pathname !== '/confirmar' && location.pathname !== '/validar' 
      && location.pathname !== '/recuperacion' && location.pathname !== '/validarpass'
      && location.pathname !== '/verifica' && location.pathname !== '/actualiza'
      && location.pathname !== '/succes' && location.pathname !== '/seleccion'
      && location.pathname !== '/basica' && location.pathname !== '/posicion'
      && location.pathname !== '/extra' && location.pathname !== '/habilidades'
      && location.pathname !== '/metricas' && location.pathname !== '/listo'
      && location.pathname !== '/intereses' && location.pathname !== '/basicao'
      && location.pathname !== '/nosotros' && location.pathname !== '/contacto'
      && location.pathname !== '/politicas'
      && location.pathname !== '/admin' && location.pathname !== '/' && <NavBar 
      handleClick={handleClick} 
      clickMessage={clickMessage} 
      inAmigos={inAmigos} 
      notificacionesNum={notificacionesNoLeidas(notificaciones)}
      clickNotificacion={clickNotificacion}
      clickUserBool={clickUserBool}
      handlerAdminClick={handlerAdminClick}
      user={dataUserLocal}
      menuBool={menuBool}
      setMenuBool={setMenuBool}
      inEdit={inEdit}
      logOut={logOut}
      />}

    <Routes>

    <Route path="/" 
      element={<Landing entrar={handleClick}/>} />

    <Route path="/nosotros" 
      element={<LandingNosotros entrar={handleClick}/>} />

    <Route path="/contacto" 
      element={<LandingContacto entrar={handleClick}/>} />

      <Route path='/politicas'
      element={<Politica />} />

      <Route path='/sesion' 
      element={<Sesion logSesion={logSesion}/>} />

      <Route path="/register" 
      element={<Register />} />

      <Route path="/confirmar" 
      element={<ConfirmarCorreo />} />

      <Route path="/validar/:id" 
      element={<Validar />} />

      <Route path="/validarpass/:id"
      element={<Valida />} />

      <Route path="/actualiza" 
      element={<Actiualiza />} />

      <Route path="/basica" 
      element={<Basica />} />

      <Route path="/posicion" 
      element={<Posicion />} />

      <Route path="/extra" 
      element={<Extra />} />

      <Route path="/habilidades" 
      element={<Habilidades />} />

      <Route path="/metricas"
      element={<Metricas />} />

      <Route path="/listo"
      element={<Listo />} />

      <Route path="/intereses"
      element={<Intereses />} />

      <Route path="/basicao"
      element={<BasicaO />} />

      <Route path='/seleccion'
      element={<Seleccion />} />

      <Route path="/recuperacion" 
      element={<Recuperacion />} />

      <Route path="/succes" 
      element={<Succes />} />

      <Route path="/verifica" 
      element={<Verifica />} />

      <Route path="/home" 
      element={dataUserLocal && Object.keys(dataUserLocal).length > 0 ? <Home 
      isOpen={isOpen} 
      inVisitor={inVisitor} 
      user={dataUserLocal}
      notificacionesBool={notificacionesBool}
      userBool={userBool}
      desactivarNotificacion={desactivarNotificacion}
      deletePublicacion={deletePublicacion}
      logOut={logOut}
      inEdit={inEdit}
      inAmigos={inAmigos}
      clickMessage={clickMessage}
      dispacherPublicaciones={dispacherPublicaciones}
      allJugadores={allJugadores}
      listas={listas}
      dispatcherAmigos={dispatcherAmigos}
      noticias={noticias}
      /> : <Navigate to="/sesion" replace={true}/>}/>

      <Route path='/comunidad' 
      element={<Amigos 
      localUser={dataUserLocal}
      allJugadores={allJugadores}
      seguir={seguir} 
      seguidores={seguidores}
      seguidos={seguidos}
      usuarioRecomendados={usuarioRecomendados} 
      dispatcherAmigos={dispatcherAmigos}
      inVisitor={inVisitor}
      dejarSeguir={dejarSeguir}
      logOut={logOut}
      inEdit={inEdit}
      clickMessage={clickMessage}
      userBool={userBool}
      notificacionesBool={notificacionesBool}/>}/>

      {/* Falta optimizar hacia abajo */}

      <Route path='/perfil' 
      element={dataUserLocal && Object.keys(dataUserLocal).length > 0 ? <Perfil 
      inEdit={inEdit}  
      inVisitor={inVisitor} 
      notificacionesBool={notificacionesBool}
      user={dataUserLocal}
      noticias={noticias}
      /> : <Navigate to="/sesion" replace={true}/>} />

      <Route path='/configuracion' 
      element={dataUserLocal && Object.keys(dataUserLocal).length > 0 ? <Formulario usuario={dataUserLocal}
      logOut={logOut}
      inEdit={inEdit}
      userBool={userBool}
      /> : <Navigate to="/sesion" 
      replace={true}/>}/>

      <Route path='/message' 
      element={<Mensajes 
      mensajes={mensajes} 
      obtenerMensajes={obtenerMensajes} 
      enviarMensajes={enviarMensajes} 
      clickMessage={clickMessage}
      dispatcherAmigos={dispatcherAmigos}
      seguidos={seguidos}
      user={dataUserLocal}
      logOut={logOut}
      inEdit={inEdit}
      userBool={userBool}
      inVisitor={inVisitor}
      notificacionesBool={notificacionesBool}/>}/>

      <Route path='/visitor/:id' 
      element={dataUserLocal && Object.keys(dataUserLocal).length > 0 ? <Visitor  
      inVisitor={inVisitor}/> : <Navigate to="/sesion"
      notificacionesBool={notificacionesBool} 
      logOut={logOut}
      inEdit={inEdit}
      userBool={userBool}
      noticias={noticias}
      replace={true}/>}/>

      <Route path='/admin'
      element={<Admin
        user={dataUserLocal}
        inVisitor={inVisitor} 
        logOut={logOut}
        noticias={noticias}
      />}/>

    </Routes>
  </div>
  );
}

export default App;
