import React, { useState } from 'react'
import styles from "./Contacto.module.css"
import {URL} from "../../../link.js"
import { validarCorreo, validarStrings, validarTelefono } from '../../Register/functions.js'
import axios from 'axios'

const Contacto = () => {

    const [nombre, setNombre] = useState("")
    const [errorNombre, setErrorNombre] = useState(true)
    const [correo, setCorreo] = useState("")
    const [errorCorreo, setErrorCorreo] = useState(true)
    const [tel, setTel] = useState("")
    const [errorTel, setErrorTel] = useState(true)
    const [mensaje, setMensaje] = useState("")
    const [errorMensaje, setErrorMensaje] = useState(false)

    const enviar = async(e) => {
        e.preventDefault()
        const data = {
            nombre: nombre,
            correo: correo,
            telefono: tel,
            mensaje: mensaje
        }
        if(nombre.length === 0 || correo.length === 0 || mensaje.length === 0){
            return;
        }
        if(errorNombre === false ||  errorCorreo === false || errorTel === false || errorMensaje === false){
            return;
        } else{
            try {
                await axios.post(`${URL}/mail/contacto`, data)
                setNombre("")
                setCorreo("")
                setTel("")
                setMensaje("")
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "nombre") {
            setErrorNombre(validarStrings(value))
            setNombre(value)
            console.log(errorNombre);
        }
        if (name === "correo") {
            setErrorCorreo(validarCorreo(value))
            setCorreo(value)
        }
        if (name === "tel") {
            setErrorTel(validarTelefono(value))
            setTel(value)
        }
        if (name === "mensaje") {
            if(value.length > 0) setErrorMensaje(false)
                setErrorMensaje(true)
            setMensaje(value)
        }
    }

  return (
    <div className={styles.contacto}>
        <div className={styles.container}>
            <div className={styles.contTitle}>
                <h1 className={styles.title}>CONTÁCTANOS</h1>
                <p className={styles.txt}>Si necesitas contactarnos para alguna oportunidad de colaboración, cobertura de medios o solo para saludar, deja tus datos en el formulario y nos pondremos en contacto contigo a la brevedad.</p>
            </div>
            <div className={styles.contForm}>
                <div className={styles.form}>
                    <div className={styles.campo}>
                        <p className={errorNombre ? styles.txtInp : styles.txtInpError}>Nombre completo*</p>
                        <input className={errorNombre ? styles.inp : styles.inpError} type="text" name="nombre" value={nombre} onChange={(e) => handleChange(e)}/>
                    </div>
                    <div className={styles.campo}>
                        <p className={errorCorreo ? styles.txtInp : styles.txtInpError}>Correo electrónico*</p>
                        <input className={errorCorreo ? styles.inp : styles.inpError} type="text" name="correo" value={correo} onChange={(e) => handleChange(e)}/>
                    </div>
                    <div className={styles.campo}>
                        <p className={errorTel ? styles.txtInp : styles.txtInpError}>Teléfono</p>
                        <input className={errorTel ? styles.inp : styles.inpError} type="text" name="tel" value={tel} onChange={(e) => handleChange(e)}/>
                    </div>
                    <div className={styles.campo}>
                        <p className={styles.txtInp}>Mensaje*</p>
                        <textarea className={styles.inpTxt} type="text" placeholder='Mensaje' name="mensaje" value={mensaje} onChange={(e) => handleChange(e)}/>
                    </div>
                    <div className={styles.botones}>
                        <p className={styles.aviso}>*Campos obligatorios</p>
                        <button className={styles.btn} onClick={enviar}>ENVIAR</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contacto