import React from 'react'
import styles from "./ModalEliminar.module.css"

const ModalEliminar = ({elimnarBoolChange, handlerEliminarCuenta}) => {
    return (
        <div className={styles.modalEliminar}>
            <h1 className={styles.title}>ELIMINAR CUENTA</h1>
            <h4 className={styles.cuestion}>¿Estas seguro de eliminar tu cuenta?</h4>
            <div className={styles.botonera}>
                <button className={styles.btnC} onClick={elimnarBoolChange}>Cancelar</button>
                <button className={styles.btnD} onClick={handlerEliminarCuenta}>Eliminar</button>
            </div>
        </div>
    )
}

export default ModalEliminar