import style from "./Seleccion.module.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.svg"
import campo from "../../assets/CampoLimpio.png"
import { opcionesPosiciones } from "../funciones"

export default function Extra() {
    const [posicionesSeleccionadas, setPosicionesSeleccionadas] = useState([]);
    const [aviso, setAviso] = useState("")

    const usuario = JSON.parse(localStorage.getItem("dataUser"))

    const navigate = useNavigate();

    const onChanceInputs = (posicion) => {
        if (posicionesSeleccionadas.length < 2 || posicionesSeleccionadas.includes(posicion)) {
            if (posicionesSeleccionadas.includes(posicion)) {
                const nuevasPosiciones = posicionesSeleccionadas.filter((p) => p !== posicion);
                setPosicionesSeleccionadas(nuevasPosiciones);
            } else {
                setPosicionesSeleccionadas([...posicionesSeleccionadas, posicion]);
            }
        }
    }

    const continuar = () => {
        if (posicionesSeleccionadas.length === 0) {
            setAviso("Debes seleccionar al menos una posición para continuar")
            return
        }
        usuario.posicionesSeleccionadas = posicionesSeleccionadas
        localStorage.setItem("dataUser", JSON.stringify(usuario))
        navigate("/habilidades")
    }

    const regresar = () => {
        navigate("/posicion");
    }

    const onClickHome = () => {
        navigate("/")
    }

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.posicion}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>POSICIÓN DE JUEGO</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Compártenos en que posición del campo tienes tu mejor desempeño y que otras posiciones te gusta jugar.
                            </span>
                        </div>
                        <img src={campo} alt="posicion" className={style.campo} />
                        <div className={style.contOpciones}>
                            <h2 className={style.selectOpcTwo}>1. SELECCIONA TU POSICIÓN FAVORITA PARA JUGAR</h2>
                            <h2 className={style.selectOpc}>2. SELECCIONA OTRAS POSICIONES DONDE JUEGUES</h2>
                            <div className={style.opciones}>
                                {opcionesPosiciones.map((posicion, index) => (
                                    <div key={index} className={style.opcion}>
                                        <div className={style.contCheck}>
                                            <label htmlFor={posicion} className={style.labelCheckbox}>
                                                <input
                                                    type="checkbox"
                                                    className={style.checkbox}
                                                    id={posicion}
                                                    onChange={() => onChanceInputs(posicion)}
                                                    checked={posicionesSeleccionadas.includes(posicion)}
                                                />
                                                <span className={style.textCheck}>
                                                    {posicion}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={regresar}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    )
}
