import { useEffect } from 'react';
import style from "./Inicio.module.css"
import imgOne from "../../../assets/bsdImgOne.png"
import balon from "../../../assets/balon.png"
import imgTwo from "../../../assets/imgTwo.png"
import imgAppObe from "../../../assets/imgAppOne.png"
import imgAppTwo from "../../../assets/imgAppTwo.png"
import imgTree from "../../../assets/imgTree.png"
import imgAppTree from "../../../assets/imgAppTree.png"
import imgAppFour from "../../../assets/imgAppFour.png"
import imgFour from "../../../assets/imgFour.png"
import imgAppFive from "../../../assets/imgAppFive.png"
import logo from "../../../assets/Logo.svg"
import insta from "../../../assets/Insta.png"
import twitter from "../../../assets/twe.png"
import face from "../../../assets/face.png"

const Inicio = ({handleRegister, handleSesion, handleNosotros, handleContacto, handleInicio}) => {
    // useEffect(() => {
    //     handleInicio();
    // }, [handleInicio]);
    return (
        <div className={style.inicio} >
            <div className={style.contHero}>
                <div className={style.hero}>
                    <div className={style.contM}>
                        <h1 className={style.Mprincipal}>LA COMUNIDAD DEPORTIVA QUE IMPULSA LA MEJOR VERSION DE TI</h1>
                        <div className={style.conSegundo}>
                            <h3 className={style.MSegundo}>Únete a jugadores, entrenadores, reclutadores, clubes, universidades y más profesionales del mundo deportivo</h3>
                        </div>
                        <div className={style.contBtn}>
                            <button className={style.btnR} onClick={handleRegister}>Registrarse</button>
                            <button className={style.btnI} onClick={handleNosotros}>Conoce más</button>
                        </div>
                    </div>
                    <div className={style.contImg}>
                        <img className={style.imgOne} src={imgOne} alt="" />
                    </div>
                </div>
                <div className={style.porque}>
                    <h2 className={style.title}>¿POR QUÉ USAR BIG SPORT DATA?</h2>
                    <div className={style.contContenido}>
                        <div className={style.contenido}>
                            <h3 className={style.sub}>PARA JUGADORES</h3>
                            <p className={style.txt}>Da seguimiento a tu desempeño deportivo, sigue y realizar comparaciones de rendimiento con tus amigos, y muestra tus habilidades a la comunidad de BSD, donde podrás encontrar el siguiente paso de tu carrera futbolística.</p>
                            <div className={style.contList}>
                                <div className={style.list}>
                                    <img className={style.balon} src={balon} alt="" />
                                    <p className={style.txtB}>Conecta y compite con otros jugadores.</p>
                                </div>
                                <div className={style.list}>
                                    <img className={style.balon} src={balon} alt="" />
                                    <p className={style.txtB}>Da seguimiento a tu desempeño atlético.</p>
                                </div>
                                <div className={style.list}>
                                    <img className={style.balon} src={balon} alt="" />
                                    <p className={style.txtB}>Demuestra tus habilidades como jugador.</p>
                                </div>
                                <div className={style.list}>
                                    <img className={style.balon} src={balon} alt="" />
                                    <p className={style.txtB}>Conecta con reclutadores, entrenadores y otros profesionales de la industria.</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.contImages}>
                            <img className={style.imgTwo} src={imgTwo} alt="" />
                            <img className={style.imgAppOne} src={imgAppObe} alt="" />
                            <img className={style.imgAppTwo} src={imgAppTwo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.entrenadores}>
                <div className={style.contImagesE}>
                    <img className={style.imgTwo} src={imgTree} alt="" />
                    <img className={style.imgAppTree} src={imgAppTree} alt="" />
                    <img className={style.imgAppFour} src={imgAppFour} alt="" />
                </div>
                <div className={style.contEntre}>
                    <h3 className={style.sub}>PARA ENTRNADORES, RECLUTADORES Y FANÁTICOS</h3>
                    <p className={style.txt}>Encuentra futuros talentos, busca jugadores por sus habilidades atléticas o futbolísticas, conecta con otros profesionales de la industria y no te pierdas ninguna noticia o novedad del mundo del fútbol.</p>
                    <div className={style.contList}>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtB}>Encuentra a los mejores talentos.</p>
                        </div>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtB}>Busca jugadores por posición, edad, habilidades atléticas y más.</p>
                        </div>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtB}>Conecta con jugadores, reclutadores, entrenadores y más.</p>
                        </div>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtB}>Mantente al tanto de noticias, resultados y otras novedades del mundo del fútbol.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inicio