import React, { useEffect } from 'react'
import styles from "./Panel.module.css"
import Chart from 'chart.js/auto';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { dataRegistros } from './actions';


const Panel = ({allJugadores, allReclutadores, allUsuarios, jugadoresLine, reclutadoresLine, usuariosDesactivos, usuariosActivos}) => {
    useEffect(() => {},[allJugadores, allReclutadores, usuariosDesactivos, usuariosActivos])
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Estadisticas</h2>
            <div className={styles.totales}>
                <div className={styles.dataTotales}>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Total de Usuarios:</h4>
                        <h3 className={styles.num}>{allUsuarios.length - 1}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Usuarios:</h4>
                        <h3 className={styles.num}>{allJugadores.length}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Reclutadores:</h4>
                        <h3 className={styles.num}>{allReclutadores.length}</h3>
                    </div>
                </div>
                <div className={styles.graficasTotales}>
                    <div className={styles.grafica}>
                    <Bar
                        data={{
                            labels: ['Total', 'Usuarios', 'Reclutadores'],
                            datasets: [
                                {
                                    label: 'Todos los Usuarios',
                                    data: [allUsuarios.length - 1, allJugadores.length, allReclutadores.length],
                                }
                            ]
                        }}
                    />
                    </div>
                   <div className={styles.grafica}>
                   <Line
                        data={{
                            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Obtubre', 'Noviembre', 'Diciembre'],
                            datasets: [
                                {
                                    label: 'Registros',
                                    data: dataRegistros(allUsuarios),
                                }
                            ]
                        }}
                    />
                   </div>
                </div>
            </div>
            <div className={styles.totales}>
            <h3 className={styles.title}>Usuarios</h3>
                <div className={styles.dataTotales}>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Activos:</h4>
                        <h3 className={styles.num}>{usuariosActivos.length-1}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Bloqueados:</h4>
                        <h3 className={styles.num}>{usuariosDesactivos.length}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Conectados:</h4>
                        <h3 className={styles.num}>{jugadoresLine.length}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Desconectados:</h4>
                        <h3 className={styles.num}>{usuariosActivos.length - jugadoresLine.length}</h3>
                    </div>
                </div>
                <div className={styles.graficasTotales}>
                    <div className={styles.grafica}>
                    <Doughnut
                        data={{
                            labels: ['Usuarios Bloqueados', 'Usuarios Activos'],
                            datasets: [
                                {
                                    label: 'Dataset 1',
                                    data: [usuariosDesactivos.length, usuariosActivos.length-1],
                                }
                            ]
                        }}
                    />
                    </div>
                    <div className={styles.grafica}>
                    <Doughnut
                        data={{
                            labels: ['En liena', 'Desconectados'],
                            datasets: [
                                {
                                    label: 'Dataset 1',
                                    data: [jugadoresLine.length, usuariosActivos.length - jugadoresLine.length],
                                }
                            ]
                        }}
                    />
                    </div>
                </div>
            </div>
            <div className={styles.totales}>
            <h3 className={styles.title}>Reclutadores</h3>
                <div className={styles.dataTotales}>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Bloqueados:</h4>
                        <h3 className={styles.num}>1</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Premium:</h4>
                        <h3 className={styles.num}>3</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Free:</h4>
                        <h3 className={styles.num}>3</h3>
                    </div>
                </div>
                <div className={styles.graficasTotales}>
                   <div className={styles.grafica}>
                   <Doughnut
                        data={{
                            labels: ['Reclutadores Bloqueados', 'Reclutadores Free', 'Reclutadores Premium'],
                            datasets: [
                                {
                                    label: 'Reclutadores',
                                    data: [1, 2, 3],
                                }
                            ]
                        }}
                    />
                   </div>
                   <div className={styles.grafica}>
                   <Doughnut
                        data={{
                            labels: ['En linea', 'Desconectados'],
                            datasets: [
                                {
                                    label: 'Reclutadores',
                                    data: [reclutadoresLine.length, (allReclutadores.length - reclutadoresLine.length)],
                                }
                            ]
                        }}
                    />
                   </div>
                </div>
            </div>
        </div>
    )
}

export default Panel
