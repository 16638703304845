// Usuarios.jsx
import React, { useState, useEffect } from 'react';
import styles from './Usuarios.module.css';
import SearchBar from '../SearchBar/SearchBar';
import { useSelector } from 'react-redux';

const Usuarios = ({ activarUsuario, desactivarUsuario, allJugadores, allReclutadores, inVisitor }) => {
    const [filteredJugadores, setFilteredJugadores] = useState([]);
    const [filteredReclutadores, setFilteredReclutadores] = useState([]);
    const [order, setOrder] = useState('asc');
    const [updateCount, setUpdateCount] = useState(0);

    const activar = async(id) => {
        await activarUsuario(id);
        setUpdateCount(updateCount + 1);
    }

    const desactivar = async(id) => {
        await desactivarUsuario(id);
        setUpdateCount(updateCount + 1);
    }

    const handleJugadoresSearch = (searchTerm) => {
        const filtered = searchTerm
            ? allJugadores.filter(
                (user) =>
                    user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.correo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.id.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : allJugadores;
        setFilteredJugadores(orderList(filtered));
    };

    const handleReclutadoresSearch = (searchTerm) => {
        const filtered = searchTerm
            ? allReclutadores.filter(
                (user) =>
                    user.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.correo.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.id.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : allReclutadores;
        setFilteredReclutadores(orderList(filtered));
    };

    const orderList = (list) => {
        return list.sort((a, b) => {
            const nameA = a.nombre.toLowerCase();
            const nameB = b.nombre.toLowerCase();

            if (order === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
    };

    const handleResetJugadores = () => {
        setFilteredJugadores(orderList(allJugadores));
    };

    const handleResetReclutadores = () => {
        setFilteredReclutadores(orderList(allReclutadores));
    };

    const toggleOrder = () => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    useEffect(() => {
        handleResetJugadores();
        handleResetReclutadores();
    }, [order]);

    useEffect(() => {
        handleResetJugadores();
        handleResetReclutadores();
    }, [allJugadores, allReclutadores])

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Administrar Usuarios</h2>
            <div className={styles.listas}>
                <div className={styles.listaUsuarios}>
                    <h4 className={styles.subTitle}>Jugadores</h4>
                    <SearchBar
                        handleSearch={handleJugadoresSearch}
                        handleReset={handleResetJugadores}
                        handleOrder={toggleOrder}
                    />
                    <div className={styles.lista}>
                    {filteredJugadores.map((user) => (
                        <div className={styles.user} key={user.id}>
                            <img className={styles.foto} src={user.foto} alt="" />
                            <div className={styles.info}>
                                <div className={styles.contName}>
                                    <p className={styles.name}>{user.nombre}</p>
                                    <span className={user.line === true ? styles.inline : styles.offline}>{user.line === true ? "En linea" : "Desconectado"}</span>
                                </div>
                                <p className={styles.mail}>{user.correo}</p>
                                <p className={styles.id}>{user.id}</p>
                            </div>
                            {user.activo === false ? (
                                <button className={styles.btnActivar} onClick={() => activar(user.id)}>Activar</button>
                            ) : (
                                <button className={styles.btnDesactivar} onClick={() => desactivar(user.id)}>Desactivar</button>
                            )}
                            <button className={styles.btnVer} onClick={() => inVisitor(user.id)}>Ver perfil</button>
                        </div>
                    ))}
                    </div>
                </div>
                <div className={styles.listaUsuarios}>
                    <h4 className={styles.subTitle}>Reclutadores</h4>
                    <SearchBar
                        handleSearch={handleReclutadoresSearch}
                        handleReset={handleResetReclutadores}
                        handleOrder={toggleOrder}
                    />
                    {filteredReclutadores.map((user) => (
                        <div className={styles.user} key={user.id}>
                            <div className={styles.info}>
                                <div className={styles.contName}>
                                    <p className={styles.name}>{user.nombre}</p>
                                    <span className={user.line === true ? styles.inline : styles.offline}>{user.line === true ? "En linea" : "Desconectado"}</span>
                                </div>
                                <p className={styles.mail}>{user.correo}</p>
                                <p className={styles.id}>{user.id}</p>
                            </div>
                            {user.activo === false ? (
                                <button className={styles.btnActivar} onClick={() => activar(user.id)}>Activar</button>
                            ) : (
                                <button className={styles.btnDesactivar} onClick={() => desactivar(user.id)}>Desactivar</button>
                            )}
                            <button className={styles.btnVer} onClick={() => inVisitor(user.id)}>Ver perfil</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Usuarios;
