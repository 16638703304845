
const initialState = {
    dataUser: {},
    allPublicaciones: [],
    userPubliaciones: [],
    visitorPublicaciones: [],
    dataVisitorUser: {},
    seguidores: [],
    seguidos: [],
    mensajesNum: 0,
    allUsuarios: [],
    solicitudesRecibidas: [],
    solicitudesEnviadas: [],
    usuarioRecomendados: [],
    mensajes: [],
    notificaciones: [],
    allJugadores: [],
    allReclutadores: [],
    jugadoresLine: [],
    reclutadoresLine: [],
    usuariosDesactivos: [],
    usuariosActivos: [],
    listas: [],
    noticias: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USER':
        return {
          ...state,
          dataUser: action.payload
        }
      case 'GET_VISITOR_USER':
        return {
          ...state,
          dataVisitorUser: action.payload
        }
      case 'GET_DETAIL_USER':
        return {
          ...state,
          detailUser: action.payload
        }
      case 'GET_ALL_PUBLICACIONES':
        return {
          ...state,
          allPublicaciones: action.payload
        }
      case 'GET_USER_PUBLICACIONES':
        return {
          ...state,
          userPubliaciones: action.payload
        }
      case 'GET_VISITOR_PUBLICACIONES':
        return {
          ...state,
          visitorPublicaciones: action.payload
        }
      case 'GET_MENSAJES_NUM':
        return {
          ...state,
          mensajesNum: action.payload
        }
      case "MAS_UN_MENSAJE":
        return {
          ...state,
          mensajesNum: state.mensajesNum + action.payload
        }
      case "MENSAJES_LEIDOS":
        return {
          ...state,
          mensajesNum: action.payload
        }
      case "GET_ALL_USUARIOS":
        return {
          ...state,
          allUsuarios: action.payload
        }
      case "GET_SOLICITUDES_RECIBIDAS":
        return {
          ...state,
          solicitudesRecibidas: action.payload
        }
      case "GET_SOLICITUDES_ENVIADAS":
        return {
          ...state,
          solicitudesEnviadas: action.payload
        }
      case "GET_RECOMENDADOS":
        return {
          ...state,
          usuarioRecomendados: action.payload
        }
      case "GET_MENSAJES":
        return {
          ...state,
          mensajes: action.payload
        }
      case "GET_NOTIFICACIONES":
        return {
          ...state,
          notificaciones: action.payload
        }
      case "DELETE_NOTIFICACION":
        return {
          ...state,
          notificaciones: action.payload
        }
      case "GET_ALL_JUGADORES":
        return {
          ...state,
          allJugadores: action.payload
        }
      case "GET_ALL_RECLUTADORES":
        return {
          ...state,
          allReclutadores: action.payload
        }
      case "GET_JUGADORES_LINE":
        return {
          ...state,
          jugadoresLine: action.payload
        }
      case "GET_RECLUTADORES_LINE":
        return {
          ...state,
          reclutadoresLine: action.payload
        }
      case "DELETE_PUBLICACION":
        return {
          ...state,
          allPublicaciones: action.payload
        }
      case "GET_USUARIOS_DESACTIVOS":
        return {
          ...state,
          usuariosDesactivos: action.payload
        }
      case "GET_USUARIOS_ACTIVOS":
        return {
          ...state,
          usuariosActivos: action.payload
        }
        case "GET_SEGUIDORES":
          return {
            ...state,
            seguidores: action.payload
          }
          case "GET_SEGUIDOS":
            return {
              ...state,
              seguidos: action.payload
            }
            case "GET_LISTAS":
              return {
                ...state,
                listas: action.payload
              }
              case "CREAR_LISTA":
                return {
                  ...state,
                  listas: [...state.listas, ...action.payload]
                }
                case "GET_NOTICIAS":
                  return {
                    ...state,
                    noticias: action.payload
                  }
      default:
    return state;
    }
}

export default reducer;