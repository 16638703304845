import React, { useEffect, useState, useRef } from 'react'
import style from "./Landing.module.css"
import { useNavigate } from 'react-router'
import Loading from '../Loading/Loading'
import logo from "../../assets/Logo.svg"
import Inicio from './Inicio/Inicio'
import Nosotros from './Nosotros/Nosotros'
import Contacto from './Contacto/Contacto'
import imgFour from "../../assets/imgFour.png"
import imgAppFive from "../../assets/imgAppFive.png"
import insta from "../../assets/Insta.png"
import twitter from "../../assets/twe.png"
import face from "../../assets/face.png"
import iconMenu from "../../assets/menu.svg"

export default function Landing() {
    const navigate = useNavigate()

    const [isAndroid, setIsAndroid] = useState(false)
    const [scrolled, setScrolled] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    useEffect(() => {
        // Manejar la detección de desplazamiento
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        // Agregar y eliminar el evento de scroll
        window.addEventListener('scroll', handleScroll);

        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && menuVisible) {
                setMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuVisible]);

    useEffect(() => {
        const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))        
        if (dataUserLocal) {
            if(dataUserLocal.id?.length > 0){
                navigate("/home")
            }
        }
    },[])


    const handleNosotros = () => {
        navigate("/nosotros")
    }

    const handleContacto = () => {
        navigate("/contacto")
    }

    const handleInicio = () => {
        navigate("/")
    }

    const handleSesion = () => {
        navigate("/sesion")
    }

    const handleRegister = () => {
        navigate("/register")
    }

    const handlePolitica = () => {
        navigate("/politicas")
    }

    useEffect(() => {
        if (window.AndroidInterface) {
            setIsAndroid(true)
        }
    }, [])


    return (
        <div className={style.container}>
            {isAndroid === false ? <div className={`${style.nav} ${scrolled ? style['nav-scrolled'] : ''}`}>
                <div className={`${style['menu-lateral']} ${menuVisible ? style.show : ''}`} ref={menuRef}>
                    <a href="#" onClick={handleInicio}>Inicio</a>
                    <a href="#" onClick={handleNosotros}>Sobre Nosotros</a>
                    <a href="#" onClick={handleContacto}>Contáctanos</a>
                    <a href="#" onClick={handleSesion}>Iniciar Sesión</a>
                    <a href="#" onClick={handleRegister}>Registrarse</a>
                </div>
                <div className={style.btn} onClick={toggleMenu}>
                    <img className={style.iconMenu} src={iconMenu} alt="" />
                </div>
                <img className={style.logo} src={logo} alt="Big Sport Data" onClick={handleInicio} />
                <div className={style.menu}>
                    <div className={style.btn} onClick={handleInicio}>
                        <p>INICIO</p>
                    </div>
                    <div className={style.btn} onClick={handleNosotros}>
                        <p>SOBRE NOSOTROS</p>
                    </div>
                    <div className={style.btn} onClick={handleContacto}>
                        <p>CONTÁCTANOS</p>
                    </div>
                </div>
                <div className={style.btnRegistro} onClick={handleSesion}>
                    <p>Inicia sesión</p>
                </div>
            </div> : <div className={style.navAnd}><img className={style.logoAnd} src={logo} alt="" /></div>}
            <div className={style.cont}>
                <Inicio handleSesion={handleSesion} handleRegister={handleRegister} handleNosotros={handleNosotros} handleContacto={handleContacto} handleInicio={handleInicio} />
            </div>
            <div className={style.comunidad}>
                <div className={style.contDato}>
                    <h2 className={style.subT}>ÚNETE A LA COMUNIDAD</h2>
                    <div className={style.contNum}>
                        <div className={style.jugadores}>
                            <h1 className={style.num}>+8,000</h1>
                            <h3 className={style.jugadoresTxt}>Jugadores registrados</h3>
                        </div>
                        <div className={style.jugadores}>
                            <h1 className={style.num}>+1,000</h1>
                            <h3 className={style.jugadoresTxt}>Profesionales registrados</h3>
                        </div>
                    </div>
                    <div className={style.botones}>
                        <button className={style.btnRegis} onClick={handleRegister}>Registrate</button>
                        <button className={style.btnIniciar} onClick={handleSesion}>Inciar sesión</button>
                    </div>
                </div>
                <div className={style.contImagesC}>
                    <img className={style.imgFour} src={imgFour} alt="" />
                    <img className={style.imgAppFive} src={imgAppFive} alt="" />
                </div>
            </div>
            <div className={style.pie}>
                <div className={style.contMenu}>
                    <img className={style.logoP} src={logo} alt="" onClick={handleInicio} />
                    <div className={style.menuP}>
                        <p className={style.btnM} onClick={handleInicio}>Inicio</p>
                        <p className={style.btnM} onClick={handleNosotros}>Sobre nosotros</p>
                        <p className={style.btnM} onClick={handleContacto}>Contáctanos</p>
                    </div>
                    <div className={style.redes}>
                        <img className={style.iconR} src={insta} alt="" />
                        <img className={style.iconR} src={face} alt="" />
                        <img className={style.iconR} src={twitter} alt="" />
                    </div>
                </div>
                <div className={style.contContacto}>
                    <div className={style.dataContact}>
                        <div className={style.contContac} onClick={handleContacto}>
                            <h3 className={style.txtContac}>Contáctanos</h3>
                        </div>
                        <p className={style.contact}>Correo: bigsportdata4@gmail.com</p>
                        <p className={style.contact}>Teléfono: (+52) 77 1116 4003</p>
                        <p className={style.contact}>Dirección: Av. del Canal 122A, Col. San Calletano, Pachuca de Soto Hidalgo, C.P. 42084 México.</p>
                    </div>
                    <div className={style.mailing}>
                        <input className={style.inpMail} type="text" placeholder="Email" />
                        <div className={style.btnMail}>
                            <p className={style.txtMail}>Suscríbete a las novedades</p>
                        </div>
                    </div>
                </div>
                <div className={style.copyright}>
                    <p className={style.copyTxt}>Copyright © 2024 Big Sport Data. Todos los derechos reservados -  <a className={style.btnPolitica} onClick={handlePolitica}>Politica de privacidad</a></p>
                </div>
            </div>
        </div>
    )
}
