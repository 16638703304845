import style from "./Seleccion.module.css";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Logo.svg";

export default function Metricas() {
    const [valores, setValores] = useState({
        paseCorto: 0,
        paseLargo: 0,
        cabeceo: 0,
        regate: 0,
        defensa: 0,
        tiros: 0
    });

    const [aviso, setAviso] = useState("")

    const usuario = JSON.parse(localStorage.getItem("dataUser"));
    const navigate = useNavigate();

    const handleChange = (campo, valor) => {
        setValores(prevState => ({ ...prevState, [campo]: valor }));
    };

    const continuar = () => {
        if(valores.paseCorto === 0 || valores.paseLargo === 0 || valores.cabeceo === 0 || valores.regate === 0 || valores.defensa === 0 || valores.tiros === 0 ){
            setAviso("Por favor califica todas las habilidades para avanzar")
            return
        }
        usuario.paseCorto = valores.paseCorto;
        usuario.paseLargo = valores.paseLargo;
        usuario.cabeceo = valores.cabeceo;
        usuario.regate = valores.regate;
        usuario.defensa = valores.defensa;
        usuario.tiros = valores.tiros;
        localStorage.setItem("dataUser", JSON.stringify(usuario));
        navigate("/listo");
    };

    const regresar = () => {
        navigate("/habilidades");
    };

    const onClickHome = () => {
        navigate("/");
    };

    const renderCalificaciones = (campo) => {
        const calificaciones = [];
        for (let i = 1; i <= 5; i++) {
            calificaciones.push(
                <div
                    key={i}
                    className={valores[campo] === i ? style.caliSeleccionado : style.cali}
                    onClick={() => handleChange(campo, i)}
                >
                    <p className={style.caliText}>{i}</p>
                </div>
            );
        }
        return calificaciones;
    };

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo} onClick={onClickHome}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.metricas}>
                    <div className={style.inter}>
                        <h1 className={style.regNameM}>MÉTRICAS PERSONALES</h1>
                        <div className={style.conTextM}>
                            <span className={style.linkTextM}>
                                A continuación tendrás que contestar algunas preguntas sobre tu rendimiento en distintas áreas de juego con respecto a otros jugadores de talla mundial. Considerando que el número 5 es el mayor.
                            </span>
                        </div>
                        {Object.entries(valores).map(([campo, valor]) => (
                            <div key={campo} className={style.contCalificacion}>
                                <h2 className={style.selectOpcM}>{campo.toUpperCase()}</h2>
                                {campo === "paseCorto" && <p className={style.textInfoM}>
                                Elige qué tan efectivo eres en el pase corto, considerando que Lionel Andrés Messi es el mejor.
                                </p>}
                                {campo === "paseLargo" && <p className={style.textInfoM}>
                                Elige qué tan efectivo eres en el pase largo, considerando que Kevin de Bruyne es el más efectivo.
                                </p>}
                                {campo === "cabeceo" && <p className={style.textInfoM}>
                                Elige qué tan efectivo eres en el cabeceo, considerando que Cristiano Ronaldo el mejor.
                                </p>}
                                {campo === "regate" && <p className={style.textInfoM}>
                                Elige qué tan efectivo eres en el regate, considerando que Lionel Andrés Messi el mejor.
                                </p>}
                                {campo === "defensa" && <p className={style.textInfoM}>
                                Elige qué tan efectivo eres defendiendo, considerando que Virgil van Dijk es el más efectivo.
                                </p>}
                                {campo === "tiros" && <p className={style.textInfoM}>
                                Elige qué tan efectivo eres en el tiro a portería, considerando que Cristiano Ronaldo Messi es el mejor.
                                </p>}
                                <div className={style.contCali}>
                                    {renderCalificaciones(campo)}
                                </div>
                            </div>
                        ))}
                        <span className={style.avisoB}>{aviso}</span>
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={regresar}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
        </div>
    );
}
