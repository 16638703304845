export function validarNum(value) {
    const regex = /^[0-9]{1,2}$/;
    return regex.test(value);
}
export function validarStrings(nombre) {
    const regex = /^[A-Za-z]+$/;
    const maxLength = 16;
    return regex.test(nombre) && nombre.length <= maxLength;
}

export function validarCelular(value) {
    const regex = /^[0-9]{9,10}$/;
    return regex.test(value);
}

export function validarCP(value){
    const regex = /^[0-9]{5}$/;
    return regex.test(value);
}

export function validarAltura(value){
    const regex = /^[0-9]{2,3}$/;
    return regex.test(value);
}

export function validarPeso(value){
    const regex = /^[0-9]{2,3}$/;
    return regex.test(value);
}

export function validarTiros(value){
    const regex = /^[0-9]{1,4}$/;
    return regex.test(value);
}

export function validarGoles(value){
    const regex = /^[0-9]{1,4}$/;
    return regex.test(value);
}